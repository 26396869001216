import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import * as orderActions from '../actions/orderActions';
import '../assets/styles/components/OrderSuccess.scss';
import { ReactComponent as SVGSuccess } from '../assets/static/icons/success.svg';
import Loader from '../layouts/Loader';
import Error from '../layouts/Error';
import * as localStorage from '../localStorage';

class OrderSuccess extends Component {
  _isMounted = false;
  state = {
    loading: true,
    error: null,
    tempResponse: null,
    orderResponse: null,
    payResponse: null,
    paySuccess: false,
    newUser: false
  };
  myStorage = window.localStorage;

  isNewUser = async () => {
    const user = localStorage.getUserState();
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/customer/${user.idCustomer}`
      );
      if (!response.data.length) {
        this.setState({ newUser: true });
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  createTemp = () => {
    const user = localStorage.getUserState();
    this.props.basketReducer.basket.forEach(async element => {
      const tempObj = {
        idCustomer: user.idCustomer,
        idProduct: element.idProduct,
        service: element.service,
        numProd: element.numProd,
        commentsProd: '',
        total: element.total.toString()
      };

      this.setState({ loading: true, error: null });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/orderTemp`,
          tempObj
        );
        this.setState({ loading: false, tempResponse: response.data });
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  createOrder = async () => {
    const user = localStorage.getUserState();
    const orderObj = {
      subtotal: this.props.basketReducer.subtotal.toString(),
      discount: this.props.basketReducer.discount.toString(),
      total: this.props.basketReducer.total.toString(),
      dateOfRecovery: this.props.orderObjReducer.dateOfRecovery,
      dateOfDelivery: this.props.orderObjReducer.dateOfDelivery,
      paymentStatus: 'PENDIENTE',
      orderChannel: 'WEB',
      numberNote: '',
      payForm: 'TARJETA',
      orderTracking: 'SOLICITADO',
      idCustomer: user.idCustomer,
      orderDate: new Date().toJSON(),
      idAddressRecovery: parseInt(this.props.orderObjReducer.idAddressRecovery),
      idAddressDelivery: parseInt(this.props.orderObjReducer.idAddressDelivery),
      dateOfPayment: new Date().toJSON()
    };

    if (this.props.basketReducer.promotion.id) {
      orderObj.discount = '0';
      orderObj.total = this.props.basketReducer.subtotal.toString();
      orderObj.promotions = [this.props.basketReducer.promotion.id];
    }

    if(this.state.newUser) {
      const total = this.props.basketReducer.subtotal - 100;
      orderObj.discount = '100';
      orderObj.total = total.toString();
    }

    this.setState({ loading: true, error: null });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order`,
        orderObj
      );
      this.emptyBasket();
      this.setState({ loading: false, orderResponse: response.data });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  emptyBasket = () => {
    this.myStorage.setItem(
      't2gBasket',
      JSON.stringify({
        content: [],
        promotion: {},
        subtotal: 0,
        discount: 0,
        total: 0
      })
    );
    this.props.setBasket([]);
    this.props.setPromotion({});
    this.props.setSubtotal(0);
    this.props.setDiscount(0);
    this.props.setTotal(0);
  };

  payOrder = async () => {
    const user = localStorage.getUserState();
    const payObj = {
      idOrder: this.state.orderResponse.idOrder,
      idPayCard: this.props.paymentReducer.idPayCard
    };
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment/${user.idCustomer}`,
        payObj
      );
      this.setState({
        loading: false,
        payResponse: response.data,
        paySuccess: true
      });
      this.props.setPaymentPayCard(null);
      this.props.setPaymentLastFour(null);
    } catch (error) {
      this.props.setPaymentPayCard(null);
      this.props.setPaymentLastFour(null);
      this.setState({ loading: false, error: error, paySuccess: false });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.isNewUser();
    await this.createOrder();
    await this.payOrder();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="success">
        <div className="success__container">
          {this.state.loading ? (
            <Loader />
          ) : this.state.error ? (
            <Error />
          ) : !this.state.paySuccess ? (
            <Error />
          ) : (
            <>
              <SVGSuccess />
              <h2 className="success__title">Pedido creado con éxito</h2>
              <p>
                Número de pedido:
                <strong>{` ${this.state.orderResponse.idOrder}`}</strong>
              </p>
              <p>
                Nuestro personal estará llegando a tu domicilio en la siguiente
                dirección:
                <br />
                <strong>
                  {this.props.addressReducer.addressRecovery.street}{' '}
                  {this.props.addressReducer.addressRecovery.numExt}{' '}
                  {this.props.addressReducer.addressRecovery.numInt} {', '}
                  {`Col. ${this.props.addressReducer.addressRecovery.colony}`}
                  {', '}
                  {`C.P. ${this.props.addressReducer.addressRecovery.CP}`}
                </strong>
              </p>
              <p>
                Fecha de recolección:
                <br />
                <strong>
                  {this.props.orderObjReducer.dateOfRecovery.substring(0, 10)}
                </strong>
              </p>
              <br />
              <span>
                <h2>¡TIN2GO!</h2>
                Los expertos en el cuidado de tu ropa agradecen tu confianza.
              </span>
              <br />
              <Link to="/">
                <button>Aceptar</button>
              </Link>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    addressReducer: reducers.addressReducer,
    basketReducer: reducers.basketReducer,
    orderObjReducer: reducers.orderObjReducer,
    paymentReducer: reducers.paymentReducer,
    successReducer: reducers.successReducer
  };
};

export default connect(mapStateToProps, orderActions)(OrderSuccess);
