import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../../layouts/Loader';
import Error from '../../layouts/Error';
import { getUserState } from '../../localStorage';
import '../../assets/styles/components/profile/myProfile.scss';
import Modal from '../../layouts/Modal';
import ProfileEdit from '../ProfileEdit';

class MyProfile extends Component {
  state = {
    loginData: {},
    loading: true,
    error: null,
    user: {},
    userData: {},
    modalIsOpen: false,
    modalContent: null
  };

  constructor(props) {
    super(props);
    this.isSubmitting = props.isSubmitting;
    this.isValid = props.isValid;
  }

  componentDidMount() {
    this.getUser();
  }

  editProfile = () => {
    this.openModal(
      <ProfileEdit
        handleCloseModal={this.handleCloseModal}
        data={this.state.userData}
      />
    );
  };

  getUser = async () => {
    const user = getUserState();

    this.setState({ loading: true, error: null });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/customer/${user.idCustomer}`
      );
      this.setState({ loading: false, userData: response.data.clients[0] });
      const userGenerated = {
        name: { name: 'Nombre', value: this.state.userData.name },
        lastname: { name: 'Apellido', value: this.state.userData.lastname },
        email: { name: 'Correo', value: this.state.userData.email },
        telephone: { name: 'Télefono', value: this.state.userData.telephone }
      };
      this.setState({ user: userGenerated });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
    this.getUser();
  };

  openModal = component => {
    this.setState({ modalIsOpen: true, modalContent: component });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error />;
    }
    return (
      <>
        <section className='my-profile'>
          <div className='myProfile__head'>
            <h2>Mi cuenta</h2>
            <button onClick={this.editProfile}>Editar</button>
          </div>
          <div className='my-information'>
            {Object.entries(this.state.user).map(item => {
              return (
                <div key={item} className='my-profile-item'>
                  <span className='title'>{item[1].name}</span>
                  <br />
                  <span className='description'>{item[1].value}</span>
                </div>
              );
            })}
          </div>
        </section>
        <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}>
          {this.state.modalContent}
        </Modal>
      </>
    );
  }
}

export default MyProfile;
