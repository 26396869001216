import React from 'react';
import { ReactComponent as SVGNotFound } from '../assets/static/icons/not-found.svg';
import '../assets/styles/layouts/Error.scss';

const Error = props => (
  <div className={`error ${props.vertical ? 'vertical' : ''}`}>
    <SVGNotFound className='error-icon' />
    <h4 className='error-text'>
      ¡Ups!
      <br />
      Algo ha salido mal.
    </h4>
  </div>
);

export default Error;
