export const getStorageState = () => {
    const state = localStorage.getItem('state');

    return state === null ? undefined : JSON.parse(state);
};

export const setStorageState = (state) => {
    const stringified = JSON.stringify(state);
    localStorage.setItem('state', stringified);
};

export const getUserState = () => {
    const state = getStorageState();

    if (!state)
        return undefined;

    return state.authReducer.user;
};

export const clearStorageState = () => {
    localStorage.clear();
};