import React, { Component } from 'react';
import maxLogo from '../assets/static/images/max-logo.png';
import '../assets/styles/components/Footer.scss';
import { Link } from 'react-router-dom';
// import NewsletterForm from './NewsletterForm';

class Footer extends Component {
  width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );

  render() {
    if (this.width <= 425) {
      return '';
    }

    return (
      <footer>
        <div className="footer">
          <div className="footer__container">
            <div className="footer__contact footer__item ">
              <p className="footer__item-text link">
                <a
                  href="https://api.whatsapp.com/send?phone=5215526539102"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (55) 2653 9102
                </a>
              </p>
              <p>@Tin2Go</p>
              <p className="footer__item-text">
                <a href="mailto:servicioalcliente@tin2go.com">
                  servicioalcliente@tin2go.com
                </a>
              </p>
              <div className="footer__item-text">
                <p>Con la garantía de Tintorerías MAX</p>
                <a
                  href="https://www.tintoreriasmax.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={maxLogo} alt="MAX logo" />
                </a>
              </div>
            </div>
            <div className="footer__terms footer__item">
              <p className="footer__item-text link">
                <Link to="/condiciones">Términos y condiciones</Link>
              </p>
              <p className="footer__item-text link">
                <Link to="/privacidad">Aviso de privacidad</Link>
              </p>
              <p className="footer__item-text link">
                <Link to="/adhesion">Contrato de adhesión</Link>
              </p>
            </div>
            <div className="width300" />
            {/* <div className='footer__newsletter'>
              <h3 className='footer__newsletter-title'>
                Suscríbete a nuestro newsletter
              </h3>
              <p>Conoce antes que nadie nuestras promociones</p>
              <NewsletterForm />
            </div> */}
          </div>
          <div className="footer__rights">
            © {new Date().getFullYear()} TIN2GO Todos los derechos reservados.
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
