import React, { Component } from 'react';
import '../../assets/styles/components/address/Addresses.scss';
import Error from '../../layouts/Error';
import Loader from '../../layouts/Loader';
import addressService from '../../services/AddressService';
import { getUserState } from '../../localStorage';
import Address from './Address';
import NewAddress from './NewAddress';
import Modal from '../../layouts/Modal';
import { ReactComponent as SVGNOADDRESS } from '../../assets/static/icons/address.svg';

class Addresses extends Component {
  _isMounted = false;

  state = {
    loading: false,
    error: null,
    addresses: [],
    modalIsOpen: false,
    modalContent: null,
    activeId: 0
  };

  componentWillMount() {
    this._isMounted = true;

    this.getAddresses(this.props.addressId);
  }

  componentWillReceiveProps(nextProps) {
    this.getAddresses(nextProps.addressId);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAddresses = async idSection => {
    this.setState({ loading: true, error: null });

    try {
      const user = getUserState();
      const response = await addressService.getAddresses(user.idCustomer);
      if (this._isMounted) {
        this.setState({
          loading: false,
          error: null,
          addresses: response.data
        });
      }
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
    this.getAddresses();
  };

  openModal = component => {
    this.setState({ modalIsOpen: true, modalContent: component });
  };

  toggleClass = (activeId, address) => {
    this.setState({
      activeId
    });

    address.address.numInt = '';
    address.address.mz = '';
    this.openModal(
      <NewAddress handleCloseModal={this.handleCloseModal} address={address} />
    );
  };

  addAddress = () => {
    this.openModal(<NewAddress handleCloseModal={this.handleCloseModal} />);
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error vertical={true} />;
    }

    if (!this.state.addresses.length) {
      return (
        <>
          <section className='noaddresses'>
            <div className='message'>
              <SVGNOADDRESS className='image' />
              <p className='description'>
                No cuentas con ninguna dirección registrada.
              </p>
            </div>
            <button onClick={this.addAddress}>Agregar</button>
          </section>
          <Modal
            isOpen={this.state.modalIsOpen}
            onClose={this.handleCloseModal}
          >
            <div className='backPayment'>{this.state.modalContent}</div>
          </Modal>
        </>
      );
    }

    return (
      <section className='addresses'>
        <div className='addresses-head'>
          <h2>Mis direcciones</h2>
          <button onClick={this.addAddress}>Agregar</button>
        </div>
        <div className='addresses-container'>
          {this.state.addresses.map(item => {
            return (
              <div
                key={item.idAddress}
                className={`${
                  this.state.activeId === item.idAddress ? 'active' : ''
                } address-container`}
                onClick={() => {
                  this.toggleClass(item.idAddress, item);
                }}
              >
                <Address address={item.address} />
              </div>
            );
          })}
        </div>
        <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}>
          <div className='backPayment'>{this.state.modalContent}</div>
        </Modal>
      </section>
    );
  }
}

export default Addresses;
