import React, { Component } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as basketActions from '../actions/basketActions';
import store from '../store';
import { LoginAction } from '../actions/LoginActions';
import '../assets/styles/components/SignUp.scss';
import { ReactComponent as SVGSuccess } from '../assets/static/icons/success.svg';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';

class SignUp extends Component {
  state = {
    loading: false,
    error: null,
    response: false,
    password: ''
  };

  myStorage = window.localStorage;

  componentDidMount() {
    if (this.props.data && this.props.data._profile) {
      console.log(this.props);
    } else {
      console.log('No hay props xD');
      console.log(this.props);
    }
  }

  addBasket = product => {
    const content = this.props.basketReducer.content;
    content.push(product);
    this.props.setBasket(content);
  };

  getOrderTemp = async userId => {
    this.setState({ loading: true, error: null });
    let tempData = [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orderTemp/customer/${userId}`
      );
      this.setState({ loading: false, tempData: response.data });
      tempData = response.data;
    } catch (error) {
      this.setState({ loading: false, error: error });
    }

    if (tempData.length) {
      this.props.setBasket([]);
      tempData.forEach(element => {
        let productDry = null;
        let productWet = null;
        let product = null;

        if (element.product.priceWet) {
          if (element.service === 'Dry') {
            productDry = {
              idOrderTemp: element.idOrderTemp,
              displayType: 'Dry',
              idProduct: element.idProduct,
              keyProduct: `${element.idProduct}Dry`,
              name: element.product.nameProduct,
              numProd: parseInt(element.numProd),
              price: parseFloat(element.product.priceDry),
              section: element.section.nameSection,
              service: 'Dry',
              total: parseFloat(element.total)
            };
          }
          if (element.service === 'Wet') {
            productWet = {
              idOrderTemp: element.idOrderTemp,
              displayType: 'Wet',
              idProduct: element.idProduct,
              keyProduct: `${element.idProduct}Wet`,
              name: element.product.nameProduct,
              numProd: parseInt(element.numProd),
              price: parseFloat(element.product.priceWet),
              section: element.section.nameSection,
              service: 'Wet',
              total: parseFloat(element.total)
            };
          }
        } else {
          product = {
            idOrderTemp: element.idOrderTemp,
            displayType: '',
            idProduct: element.idProduct,
            keyProduct: `${element.idProduct}Dry`,
            name: element.product.nameProduct,
            numProd: parseInt(element.numProd),
            price: parseFloat(element.product.priceDry),
            section: element.section.nameSection,
            service: 'Dry',
            total: parseFloat(element.total)
          };
        }

        if (productDry) {
          this.addBasket(productDry);
        }

        if (productWet) {
          this.addBasket(productWet);
        }

        if (product) {
          this.addBasket(product);
        }
      });

      this.setTotal();

      this.setLocalWithReducer();
    }
  };

  setLocalWithReducer = () => {
    this.myStorage.setItem(
      't2gBasket',
      JSON.stringify(this.props.basketReducer)
    );
  };

  setTotal = () => {
    const content = this.props.basketReducer.content;
    let subtotal = 0;

    content.forEach(element => {
      subtotal += element.total;
    });
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);

    if (basket.promotion.type === 'ORDER') {
      switch (basket.promotion.discountType) {
        case 'PERCENT':
          const percent = parseInt(basket.promotion.discount) / 100;
          const discount = parseFloat((subtotal * percent).toFixed(2));
          basket.discount = discount;
          break;
        default:
          break;
      }
    }

    const total = subtotal - basket.discount;
    this.props.setSubtotal(subtotal);
    this.props.setDiscount(basket.discount);
    this.props.setTotal(total);
  };

  updateOrderTemp = userId => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);
    basket.content.forEach(async element => {
      const tempObj = {
        idCustomer: userId,
        idProduct: element.idProduct,
        service: element.service,
        numProd: element.numProd,
        commentsProd: '',
        total: element.total.toString()
      };

      this.setState({ loading: true, error: null });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/orderTemp`,
          tempObj
        );
        this.setState({ loading: false, tempResponse: response.data });
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  createUser = async values => {
    let userData;
    if (this.props.data && this.props.data._profile) {
      userData = {
        name: this.props.data._profile.firstName,
        lastname: this.props.data._profile.lastName,
        email: this.props.data._profile.email,
        idSocial: this.props.data._profile.id,
        password: '',
        telephone: values.telephone
      };
    } else {
      userData = {
        name: values.name,
        lastname: values.lastName,
        email: values.email,
        password: values.password,
        telephone: values.telephone
      };
    }
    console.log(userData);
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/client`,
        userData
      );
      this.setState({
        loading: false,
        response: response.data,
        password: userData.password
      });
    } catch (error) {
      this.setState({ loading: false, error: error.response.data.error });
    }
  };

  loginUser = async () => {
    let userData;

    if (this.state.password) {
      userData = {
        email: this.state.response.email,
        password: this.state.password
      };
    } else {
      userData = {
        idSocial: this.props.data._profile.id
      };
    }

    this.setState({ loading: true, error: null });
    try {
      let response;
      if (this.state.password) {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/customer/login`,
          userData
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/customer/socialLogin`,
          userData
        );
      }
      this.setState({
        loading: false,
        response: response.data,
        password: ''
      });
      // let basket = this.myStorage.getItem('t2gBasket');
      // basket = JSON.parse(basket);
      // basket.discount = 100;
      // this.myStorage.setItem('t2gBasket', JSON.stringify(basket));
      // this.props.setDiscount(100);
      const data = response.data;
      const user = {
        ...data.customer,
        token: data.token
      };
      this.updateOrderTemp(data.customer.idCustomer);
      // this.getOrderTemp(data.customer.idCustomer);
      store.dispatch(LoginAction(user));
      //const location = store.history.location;
      const location = this.props.location.state;
      if (location && location.redirectTo) {
        this.props.history.replace(location.redirectTo);
      } else {
        this.props.history.replace('/');
      }
    } catch (error) {
      this.setState({ loading: false, error: error });
      console.log(error);
    }
  };

  resetState = () => {
    this.setState({ loading: false, error: null, response: false });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="signUp">
          <Loader />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className="signUp">
          <Error />
          <br />
          <button onClick={this.resetState}>Regresar</button>
        </div>
      );
    }

    if (this.state.response) {
      return (
        <div className="signUp">
          <SVGSuccess />
          <br />
          <br />
          <br />
          <h1 className="signUp__success__title">¡Bienvenido a Tin2Go!</h1>
          <p className="signUp__success__text">
            Tu cuenta ha sido creada con éxito.
            <br />
            <br />
            Al ser un nuevo usuario tienes un descuento $100.00, el cuál se
            aplicará al confirmar tu primer servicio.
          </p>
          <br />
          <br />
          <button onClick={this.loginUser} className="loginButton">
            Aceptar
          </button>
        </div>
      );
    }

    return (
      <div className="signUp">
        <h1 className="signUp__title">Registro</h1>
        <Formik
          initialValues={{
            name: '',
            lastName: '',
            telephone: '',
            email: '',
            password: '',
            confirm: '',
            terms: false
          }}
          validate={values => {
            const errors = {};

            if (!values.name && !this.props.data) {
              errors.name = 'Ingresa un nombre.';
            }

            if (!values.lastName && !this.props.data) {
              errors.lastName = 'Ingresa un apellído.';
            }

            if (!values.telephone) {
              errors.telephone = 'Ingresa un teléfono.';
            } else if (
              !/(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/.test(
                values.telephone
              )
            ) {
              errors.telephone = 'Teléfono inválido.';
            }

            if (!values.email && !this.props.data) {
              errors.email = 'Ingresa un correo electrónico.';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email) &&
              !this.props.data
            ) {
              errors.email = 'Correo electrónico inválido';
            }

            if (!values.password && !this.props.data) {
              errors.password = 'Ingresa una contraseña.';
            } else if (
              !/^[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{4,20}$/.test(
                values.password
              ) &&
              !this.props.data
            ) {
              errors.password =
                'La contraseña debe tener una longitud de 4 a 20 carácteres.';
            }
            if (values.password && !values.confirm && !this.props.data) {
              errors.confirm = 'Confirma tu contraseña.';
            } else if (values.password !== values.confirm && !this.props.data) {
              errors.confirm = 'Las contraseñas no coinciden.';
            }

            if (!values.terms) {
              errors.terms =
                'Para registrarte debes aceptar los términos y condiciones.';
            }

            console.log(errors);
            return errors;
          }}
          onSubmit={values => {
            this.createUser(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid
          }) => (
            <form onSubmit={handleSubmit}>
              <label>Nombre</label>
              {this.props.data && this.props.data._profile ? (
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={this.props.data._profile.firstName}
                  disabled
                />
              ) : (
                <>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <div className="inputError">
                    {errors.name && touched.name && errors.name}
                  </div>
                </>
              )}
              <br />
              <label>Apellido</label>
              {this.props.data && this.props.data._profile ? (
                <input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={this.props.data._profile.lastName}
                  disabled
                />
              ) : (
                <>
                  <input
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                  <div className="inputError">
                    {errors.lastName && touched.lastName && errors.lastName}
                  </div>
                </>
              )}
              <br />
              <label>Correo electrónico</label>
              {this.props.data && this.props.data._profile ? (
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="nombre@ejemplo.com"
                  value={this.props.data._profile.email}
                  disabled
                />
              ) : (
                <>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="nombre@ejemplo.com"
                    value={values.email}
                  />
                  <div className="inputError">
                    {errors.email && touched.email && errors.email}
                  </div>
                </>
              )}
              <br />
              <label>Número de teléfono</label>
              <input
                type="tel"
                name="telephone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.telephone}
              />
              <div className="inputError">
                {errors.telephone && touched.telephone && errors.telephone}
              </div>
              <br />
              {this.props.data && this.props.data._profile ? (
                ''
              ) : (
                <>
                  <label>Contraseña</label>
                  <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Usa entre 4 y 20 caracteres"
                    value={values.password}
                  />
                  <div className="inputError">
                    {errors.password && touched.password && errors.password}
                  </div>
                  <br />
                  <label>Confirma tu contraseña</label>
                  <input
                    type="password"
                    name="confirm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirm}
                  />
                  <div className="inputError">
                    {errors.confirm && touched.confirm && errors.confirm}
                  </div>
                  <br />
                </>
              )}
              <div className="termsCheck">
                <input
                  className="checkbox"
                  type="checkbox"
                  name="terms"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.terms}
                />
                <span>
                  Acepto los <strong>Términos y Condiciones</strong> y la{' '}
                  <strong>Política de Privacidad.</strong>
                </span>
              </div>
              <div className="inputError">
                <br />
                {errors.terms && touched.terms && errors.terms}
              </div>
              <br />
              <button
                className="loginButton"
                type="submit"
                disabled={(isSubmitting, !isValid)}
              >
                Realizar registro
              </button>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer
  };
};

export default connect(mapStateToProps, basketActions)(SignUp);
