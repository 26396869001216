import React, { Component } from 'react';
import { ReactComponent as SVGPROFILE } from '../../assets/static/icons/user.svg';
import { ReactComponent as SVGPAYMENTS } from '../../assets/static/icons/payCard.svg';
import { ReactComponent as SVGPEDIDOS } from '../../assets/static/icons/pedidos.svg';
import { ReactComponent as SVGDIRECCION } from '../../assets/static/icons/address.svg';
import NavbarItem from './navbarItem';

class Navbar extends Component {
  state = {
    activedCategory: 'orders'
  };

  activateCategory(name = 'profile') {
    this.setState({
      activedCategory: name
    });
  }

  render() {
    return (
      <>
        <div className='navbar-profile'>
          <NavbarItem
            component={SVGPEDIDOS}
            name='Mis pedidos'
            active={this.state.activedCategory === 'orders' ? 'active' : ''}
            onClick={() => {
              this.activateCategory('orders');
              this.props.changeView('orders');
            }}
          />

          <NavbarItem
            component={SVGPROFILE}
            name='Mi cuenta'
            active={this.state.activedCategory === 'profile' ? 'active' : ''}
            onClick={() => {
              this.activateCategory('profile');
              this.props.changeView('profile');
            }}
          />

          <NavbarItem
            component={SVGDIRECCION}
            name='Mis direcciones'
            active={this.state.activedCategory === 'address' ? 'active' : ''}
            onClick={() => {
              this.activateCategory('address');
              this.props.changeView('address');
            }}
          />

          <NavbarItem
            component={SVGPAYMENTS}
            name='Métodos de pago'
            active={this.state.activedCategory === 'payment' ? 'active' : ''}
            onClick={() => {
              this.activateCategory('payment');
              this.props.changeView('payment');
            }}
          />
        </div>
      </>
    );
  }
}

export default Navbar;
