import React, { Component } from 'react';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import '../assets/styles/components/OrderDetail.scss';
import BillForm from './BillForm';
import PaymentSelect from './PaymentSelect';
import PaymentNew from './PaymentNew';

class OrderDetail extends Component {
  state = {
    dateRecovery: null,
    recoveryHour: null,
    dateDelivery: null,
    deliveryHour: null
  };

  billForm = () => {
    this.props.changeModal(
      <BillForm data={this.props.data} close={this.props.handleCloseModal} />
    );
  };

  payNew = () => {
    this.props.changeModal(
      <PaymentNew
        data={this.props.data}
        close={this.props.handleCloseModal}
        change={this.payForm}
      />
    );
  };

  payForm = () => {
    this.props.changeModal(
      <PaymentSelect
        data={this.props.data}
        close={this.props.handleCloseModal}
        change={this.payNew}
      />
    );
  };

  formatDate = () => {
    let dateJSON = this.props.data.dateOfRecovery;
    let date = new Date(dateJSON);
    let schedule = format(date, 'h:mm aa', { locale: es });
    this.setState({
      dateRecovery: format(date, 'EEEE d MMMM yyyy', { locale: es })
    });
    switch (schedule) {
      case '9:00 AM':
        this.setState({ recoveryHour: '9:00 AM - 11:00 AM' });
        break;

      case '11:00 AM':
        this.setState({ recoveryHour: '11:00 AM - 1:00 PM' });
        break;

      case '1:00 PM':
        this.setState({ recoveryHour: '1:00 PM - 3:00 PM' });
        break;

      case '3:00 PM':
        this.setState({ recoveryHour: '3:00 PM - 5:00 PM' });
        break;

      case '5:00 PM':
        this.setState({ recoveryHour: '5:00 PM - 7:00 PM' });
        break;

      case '7:00 PM':
        this.setState({ recoveryHour: '7:00 PM - 9:00 PM' });
        break;

      default:
        this.setState({ recoveryHour: 'Sin horario' });
        break;
    }

    dateJSON = this.props.data.dateOfDelivery;
    date = new Date(dateJSON);
    schedule = format(date, 'h:mm aa', { locale: es });
    this.setState({
      dateDelivery: format(date, 'EEEE d MMMM yyyy', { locale: es })
    });
    switch (schedule) {
      case '9:00 AM':
        this.setState({ deliveryHour: '9:00 AM - 11:00 AM' });
        break;

      case '11:00 AM':
        this.setState({ deliveryHour: '11:00 AM - 1:00 PM' });
        break;

      case '1:00 PM':
        this.setState({ deliveryHour: '1:00 PM - 3:00 PM' });
        break;

      case '3:00 PM':
        this.setState({ deliveryHour: '3:00 PM - 5:00 PM' });
        break;

      case '5:00 PM':
        this.setState({ deliveryHour: '5:00 PM - 7:00 PM' });
        break;

      case '7:00 PM':
        this.setState({ deliveryHour: '7:00 PM - 9:00 PM' });
        break;

      default:
        this.setState({ deliveryHour: 'Sin horario' });
        break;
    }
  };

  componentDidMount() {
    this.formatDate();
  }

  render() {
    return (
      <div className='orderDetail__root'>
        <div className='orderDetail'>
          <h3 className='orderDetail__title'>
            Pedido: {this.props.data.idOrder}
          </h3>
          {this.props.data.paymentStatus === 'PENDIENTE' ? (
            <div className='orderDetail__card'>
              <span className='orderDetail__card__title'>
                Estatus del pedido:{' '}
              </span>
              <strong className='orderDetail__noPayText'>
                Pendiente de pago
              </strong>
              <p>
                Hemos tenido un problema al procesar tu pago, pero no te
                preocupes haz click en el botón de abajo y selecciona un método
                de pago para volver a intentarlo.
              </p>
              <button className='width100' onClick={this.payForm}>
                Realizar pago
              </button>
            </div>
          ) : (
            <div className='orderDetail__card'>
              <span className='orderDetail__card__title'>
                Estatus del pedido
              </span>
              <div className='orderDetail__card__container'>
                <div className='orderDetail__statusList'>
                  <div className='orderDetail__statusList__bullet'>
                    <figure
                      className={`orderDetail__statusList__bullet__circle ${
                        this.props.data.orderTracking === 'SOLICITADO' ||
                        this.props.data.orderTracking === 'ASIGNADO' ||
                        this.props.data.orderTracking === 'RECOLECTADO' ||
                        this.props.data.orderTracking === 'EN PLANTA' ||
                        this.props.data.orderTracking === 'LISTA' ||
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                  </div>
                  <span className='orderDetail__statusList__item'>
                    Nuevo Pedido
                  </span>
                </div>
                <div className='orderDetail__statusList'>
                  <div className='orderDetail__statusList__bullet'>
                    <figure
                      className={`orderDetail__statusList__bullet__line ${
                        this.props.data.orderTracking === 'ASIGNADO' ||
                        this.props.data.orderTracking === 'RECOLECTADO' ||
                        this.props.data.orderTracking === 'EN PLANTA' ||
                        this.props.data.orderTracking === 'LISTA' ||
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                    <figure
                      className={`orderDetail__statusList__bullet__circle ${
                        this.props.data.orderTracking === 'ASIGNADO' ||
                        this.props.data.orderTracking === 'RECOLECTADO' ||
                        this.props.data.orderTracking === 'EN PLANTA' ||
                        this.props.data.orderTracking === 'LISTA' ||
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                  </div>
                  <span className='orderDetail__statusList__item'>
                    Por recolectar
                  </span>
                </div>
                <div className='orderDetail__statusList'>
                  <div className='orderDetail__statusList__bullet'>
                    <figure
                      className={`orderDetail__statusList__bullet__line ${
                        this.props.data.orderTracking === 'RECOLECTADO' ||
                        this.props.data.orderTracking === 'EN PLANTA' ||
                        this.props.data.orderTracking === 'LISTA' ||
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                    <figure
                      className={`orderDetail__statusList__bullet__circle ${
                        this.props.data.orderTracking === 'RECOLECTADO' ||
                        this.props.data.orderTracking === 'EN PLANTA' ||
                        this.props.data.orderTracking === 'LISTA' ||
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                  </div>
                  <span className='orderDetail__statusList__item'>
                    Recolectado
                  </span>
                </div>
                <div className='orderDetail__statusList'>
                  <div className='orderDetail__statusList__bullet'>
                    <figure
                      className={`orderDetail__statusList__bullet__line ${
                        this.props.data.orderTracking === 'EN PLANTA' ||
                        this.props.data.orderTracking === 'LISTA' ||
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                    <figure
                      className={`orderDetail__statusList__bullet__circle ${
                        this.props.data.orderTracking === 'EN PLANTA' ||
                        this.props.data.orderTracking === 'LISTA' ||
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                  </div>
                  <span className='orderDetail__statusList__item'>
                    En planta
                  </span>
                </div>
                <div className='orderDetail__statusList'>
                  <div className='orderDetail__statusList__bullet'>
                    <figure
                      className={`orderDetail__statusList__bullet__line ${
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                    <figure
                      className={`orderDetail__statusList__bullet__circle ${
                        this.props.data.orderTracking === 'EN RUTA' ||
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                  </div>
                  <span className='orderDetail__statusList__item'>
                    Listo para entrega
                  </span>
                </div>
                <div className='orderDetail__statusList'>
                  <div className='orderDetail__statusList__bullet'>
                    <figure
                      className={`orderDetail__statusList__bullet__line ${
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                    <figure
                      className={`orderDetail__statusList__bullet__circle ${
                        this.props.data.orderTracking === 'FINALIZADO'
                          ? 'active'
                          : ''
                      }`}
                    />
                  </div>
                  <span className='orderDetail__statusList__item'>
                    Entregado
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className='orderDetail__card'>
            <span className='orderDetail__card__title'>Recolección</span>
            <p>
              C.P. {this.props.data.addressRecovery.CP}.{' '}
              {this.props.data.addressRecovery.street}{' '}
              {this.props.data.addressRecovery.numExt}{' '}
              {this.props.data.addressRecovery.numInt}
              {', '}
              {this.props.data.addressRecovery.colony}
              {', '}
              {this.props.data.addressRecovery.locality}
              <br />
              <br />
              {this.state.dateRecovery} {''} {this.state.recoveryHour}
            </p>
            <span className='orderDetail__card__title'>Entrega</span>
            <p>
              C.P. {this.props.data.addressRecovery.CP}.{' '}
              {this.props.data.addressDelivery.street}{' '}
              {this.props.data.addressDelivery.numExt}{' '}
              {this.props.data.addressDelivery.numInt}
              {', '}
              {this.props.data.addressDelivery.colony}
              {', '}
              {this.props.data.addressDelivery.locality}
              <br />
              <br />
              {this.state.dateDelivery} {''} {this.state.deliveryHour}
            </p>
          </div>
          <div className='orderDetail__card'>
            <span className='orderDetail__card__title'>
              Estatus de facturación:
              <strong>
                {!this.props.data.isBilled ? ' Sin facturar' : ' Facturado'}
              </strong>
            </span>
            {!this.props.data.isBilled &&
            this.props.data.orderTracking === 'FINALIZADO' ? (
              <>
                <br />
                <br />
                <button className='width100' onClick={this.billForm}>
                  Facturar pedido
                </button>
              </>
            ) : (
              <>
                <br />
                <br />
                <small>
                  La opción de facturar aparecerá aquí cuando tu pedido esté
                  finalizado.
                </small>
              </>
            )}
          </div>
          <div className='orderDetail__card'>
            <span className='orderDetail__card__title'>Detalle de pedido</span>
            {this.props.data.products.map(item => {
              return (
                <div
                  className='orderDetail__product'
                  key={`${item.idProduct}${item.service}`}
                >
                  <div className='orderDetail__product__wrapper'>
                    <span className='orderDetail__product__quantity'>
                      {item.numProd}
                    </span>
                    <div className='orderDetail__product__detail'>
                      <span className='orderDetail__product__detail__name'>
                        {item.product.nameProduct}
                      </span>
                      <span
                        className={`orderDetail__product__detail__section ${
                          item.service === 'Wet' ? 'wet' : ''
                        }`}
                      >
                        {`${item.section.nameSection}${
                          item.product.priceWet ? ` ${item.service}: ` : ':'
                        } $${
                          item.service === 'Dry'
                            ? item.product.priceDry.toFixed(2)
                            : item.product.priceWet.toFixed(2)
                        }`}
                      </span>
                    </div>
                  </div>
                  <span className='orderDetail__product__total'>
                    {`$${parseFloat(item.total).toFixed(2)}`}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className='orderDetail__resume'>
          <div className='orderDetail__resume__item'>
            <span className='orderDetail__resume__item__text'>Subtotal</span>
            <span className='orderDetail__resume__item__number'>
              {`$${parseFloat(this.props.data.subtotal).toFixed(2)}`}
            </span>
          </div>
          <br />
          <div className='orderDetail__resume__item'>
            <span className='orderDetail__resume__item__text'>Descuento</span>
            <span className='orderDetail__resume__item__number'>
              {`$${parseFloat(this.props.data.discount).toFixed(2)}`}
            </span>
          </div>
          <br />
          <br />
          <div className='orderDetail__resume__total'>
            <span className='orderDetail__resume__total__text'>TOTAL</span>
            <span className='orderDetail__resume__total__number'>
              {`$${parseFloat(this.props.data.total).toFixed(2)}`}
            </span>
          </div>
          <br />
        </div>
      </div>
    );
  }
}

export default OrderDetail;
