const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PAYMENT_PAY_CARD':
      return {
        ...state,
        idPayCard: action.payload
      };
    case 'SET_PAYMENT_LAST_FOUR':
      return {
        ...state,
        lastFour: action.payload
      };
    case 'RESET_PAYMENT':
      return {};
    default:
      return state;
  }
};
