import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as SVGClose } from '../assets/static/icons/close.svg';
import '../assets/styles/layouts/Modal.scss';

function ModalHeader(props) {
  if (!props.isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className='modal'>
      <figure onClick={props.onClose} className='modal__close'>
        <SVGClose className='modal__close-icon' />
      </figure>
      <div className='modal__container'>{props.children}</div>
    </div>,
    document.getElementById('modal')
  );
}

export default ModalHeader;
