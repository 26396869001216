import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as orderActions from '../../actions/orderActions';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import '../../assets/styles/components/address/NewAddress.scss';
import { getUserState } from '../../localStorage';
import addressService from '../../services/AddressService';
import Loader from '../../layouts/Loader';

class NewAddress extends Component {
  state = {
    lat: 0,
    lng: 0,
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    autocomplete: null,
    map: null,
    marker: null,
    address: {},
    CPValid: true,
    postalCodeValid: true
  };

  constructor(props) {
    super(props);
    this.isSubmitting = props.isSubmitting;
    this.isValid = props.isValid;
    this.getGeolocalization();
  }

  getGeolocalization = () => {
    navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        lng: position.coords.longitude,
        lat: position.coords.latitude
      });
    });
  };

  getField = (address, field) => {
    const search = address.find(g => g.types.find(t => t === field));
    if (search) return search.long_name;

    return null;
  };

  placechange = () => {
    const place = this.state.autocomplete.getPlace();

    this.setState({
      lng: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),
      address: {
        numExt: this.getField(place.address_components, 'street_number'),
        street: this.getField(place.address_components, 'route'),
        colony: this.getField(place.address_components, 'sublocality'),
        locality: this.getField(place.address_components, 'locality'),
        state: this.getField(
          place.address_components,
          'administrative_area_level_1'
        ),
        CP: this.getField(place.address_components, 'postal_code'),
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
    });

    this.setMarker();
  };

  setMarker = () => {
    const location = new this.props.google.maps.LatLng(
      this.state.lat,
      this.state.lng
    );
    if (!this.state.marker) {
      const marker = new this.props.google.maps.Marker({
        position: location,
        animation: this.props.google.maps.Animation.BOUNCE,
        map: this.state.map
      });
      this.setState({
        marker
      });
    }

    this.state.map.setCenter(location);
    this.state.marker.setPosition(location);
  };

  fetchPlaces = (mapProps, map) => {
    const { google } = mapProps;
    const input = document.getElementById('autocomplete');

    const options = {
      componentRestrictions: { country: 'MX' },
      types: ['geocode']
    };

    this.setState({
      autocomplete: new google.maps.places.Autocomplete(input, options),
      map,
      address: this.props.address
        ? this.props.address.address
        : this.state.address
    });
    this.setMarker();

    this.state.autocomplete.setFields([
      'place_id',
      'address_component',
      'geometry',
      'name',
      'formatted_address',
      'formatted_phone_number',
      'opening_hours',
      'website',
      'photos'
    ]);

    this.state.autocomplete.addListener('place_changed', this.placechange);
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  handleChange = event => {};

  toAddress = () => {
    window.scrollTo(0, 0);
    this.props.setStep('address');
  };

  validate = values => {
    let errors = {};
    if (!values.numExt) errors.numExt = 'Campo requerido!';

    if (!values.street) errors.street = 'Campo requerido!';

    if (!values.colony) errors.colony = 'Campo requerido!';
    if (!values.locality) errors.locality = 'Campo requerido!';
    if (!values.CP) errors.CP = 'Campo requerido!';
    if (!values.reference) errors.reference = 'Campo requerido!';
    if (!values.alias) errors.alias = 'Campo requerido!';

    return errors;
  };

  render() {
    const style = {
      width: '100%',
      height: '100%',
      position: 'relative'
    };

    return (
      <div className='newAddress-container'>
        <div className='new-address'>
          <h3 className='paymentNew-title'>Dirección</h3>
          <div>
            <span className='label'>
              Ingresa la dirección completa y en caso de ser necesario edita los
              campos individuales.
            </span>
          </div>
          <br />
          <div className='map-container'>
            {this.state.lat !== 0 ? (
              <Map
                google={this.props.google}
                style={style}
                zoom={17}
                onReady={this.fetchPlaces}
                onClick={this.onMapClicked}
              ></Map>
            ) : (
              LoadingContainer
            )}
          </div>

          <div>
            <br />
            <span className='label'>Dirección completa</span>
            <input type='text' id='autocomplete' className='width100' />
          </div>

          <Formik
            enableReinitialize
            initialValues={this.state.address}
            validate={this.validate}
            onSubmit={async (values, actions) => {
              try {
                const user = getUserState();
                const response = await addressService.verifyPostalCode(
                  values.CP
                );

                this.setState({
                  postalCodeValid: response.data != null ? true : false
                });

                if (!this.state.postalCodeValid) return;

                const { idPostalCode } = response.data;

                values.idPostalCode = idPostalCode;
                delete values.state;

                const newAddress = this.props.address
                  ? await addressService.updateAddress(
                      values,
                      this.state.address.idAddress
                    )
                  : await addressService.saveAddress(values, user.idCustomer);

                if (newAddress.data && this.props.handleCloseModal)
                  this.props.handleCloseModal();
                else if (newAddress.data) this.toAddress();
              } catch (error) {
                console.log('error', error);
              }
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              values,
              setValues,
              isValid
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <br />
                  <span className='label'>Calle</span>
                  <div className='form-group'>
                    <Field
                      className='width100'
                      type='street'
                      name='street'
                      value={values.street}
                    />
                    <ErrorMessage name='street'>
                      {message => <div className='inputError'>{message}</div>}
                    </ErrorMessage>
                  </div>
                  <div className='form-group'>
                    <br />
                    <span className='label'>Número</span>
                    <Field
                      className='width100'
                      type='numExt'
                      name='numExt'
                      value={values.numExt}
                    />
                    <ErrorMessage name='numExt'>
                      {message => <div className='inputError'>{message}</div>}
                    </ErrorMessage>
                  </div>
                  <div className='form-group'>
                    <br />
                    <span className='label'>Colonia</span>
                    <Field
                      className='width100'
                      type='colony'
                      name='colony'
                      value={values.colony}
                    />
                    <ErrorMessage name='colony'>
                      {message => <div className='inputError'>{message}</div>}
                    </ErrorMessage>
                  </div>
                  <div className='form-group'>
                    <br />
                    <span className='label'>Alcaldía o municipio</span>
                    <Field
                      className='width100'
                      type='locality'
                      name='locality'
                      value={values.locality}
                    />
                    <ErrorMessage name='locality'>
                      {message => <div className='inputError'>{message}</div>}
                    </ErrorMessage>
                  </div>

                  <div className='form-group'>
                    <br />
                    <span className='label'>Código postal</span>
                    <Field
                      className='width100'
                      type='CP'
                      name='CP'
                      value={values.CP}
                    />
                    <ErrorMessage name='CP'>
                      {message => <div className='inputError'>{message}</div>}
                    </ErrorMessage>
                    {!this.state.postalCodeValid ? (
                      <p className='inputError'>
                        No tenemos cobertura en esta Zona.
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='form-group'>
                    <br />
                    <br />
                    <span className='label'>Referencia</span>
                    <Field
                      className='width100'
                      type='reference'
                      name='reference'
                      value={values.reference}
                    />
                    <ErrorMessage name='reference'>
                      {message => <div className='inputError'>{message}</div>}
                    </ErrorMessage>
                  </div>
                  <div className='form-group'>
                    <br />
                    <span className='label'>Alias</span>
                    <Field
                      className='width100'
                      type='alias'
                      name='alias'
                      value={values.alias}
                    />
                    <ErrorMessage name='alias'>
                      {message => <div className='inputError'>{message}</div>}
                    </ErrorMessage>
                  </div>
                  <br />
                  <br />
                  <button
                    type='submit'
                    disabled={!isValid}
                    className={`btn-dark width100 submit`}
                  >
                    Guardar dirección
                  </button>
                </Form>
              );
            }}
          </Formik>
          <br />
          <span className='newLink' onClick={this.toAddress}>
            Regresar...
          </span>
        </div>
      </div>
    );
  }
}

const LoadingContainer = props => <Loader />;

const mapStateToProps = reducers => {
  return reducers.orderReducer;
};

const _NewAddress = connect(mapStateToProps, orderActions)(NewAddress);

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  LoadingContainer: LoadingContainer
})(_NewAddress);
