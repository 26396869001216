import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as basketActions from '../actions/basketActions';
import Categories from '../components/Categories';
import Hero from '../components/Hero';
import Sections from '../components/Sections';
import Products from '../components/Products';
import Mobile from '../components/Mobile';

class Home extends Component {
  _isMounted = false;
  width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );

  componentDidMount() {
    this.props.setStep('address');
    this.props.profileMenu(false);
    this.props.setOpenModal(false);
    this._isMounted = true;
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.width <= 425) {
      return <Mobile />;
    }

    return (
      <>
        <Hero />
        <Sections />
        <div className="flexStaSta">
          <Categories />
          <Products />
        </div>
      </>
    );
  }
}

export default connect(null, basketActions)(Home);
