import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';
import Product from './Product';
import { ReactComponent as SVGSad } from '../assets/static/icons/sad.svg';
import '../assets/styles/components/ProductList.scss';

class ProductList extends Component {
  state = {
    loading: true,
    error: null,
    productsData: []
  };

  componentDidMount() {
    this.getProducts(this.props.activeCategory);
  }

  componentWillReceiveProps(nextProps) {
    this.getProducts(nextProps.activeCategory);
  }

  getProducts = async idCategory => {
    this.setState({ loading: true, error: null });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/category/${idCategory}/products`
      );
      this.setState({ loading: false, productsData: response.data.products });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error />;
    }

    if (!this.state.productsData.length) {
      return (
        <div className='productList__empty'>
          <SVGSad className='productList__empty-icon' />
          <h4 className='productList__empty-text'>
            La categoría seleccionada está vacía.
          </h4>
        </div>
      );
    }

    return (
      <div className='productList'>
        {this.state.productsData.map(item => {
          return <Product key={item.idProduct} data={item} />;
        })}
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.categoryReducer;
};

export default connect(mapStateToProps)(ProductList);
