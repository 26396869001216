import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/components/descCalzado.scss';
import Modal from '../layouts/Modal';
import PromotionDetail from './Acercade_modal';
import PromotionDetail2 from './Sabermas_modal';
class ProductList extends Component {
  state = {
    loading: true,
    error: null,
    productsData: []
  };

  componentDidMount() {
    this.GetButtons(this.props.activeCategory);
  }

  componentWillReceiveProps(nextProps) {
    this.GetButtons(nextProps.activeCategory);
  }

  GetButtons(idCategory){
    this.setState({ loading: false, id: idCategory });
  }
  state = {
    modalIsOpen: false
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
  };


  state2 = {
    modalIsOpen2: false
  };

  openModal2 = () => {
    this.setState({ modalIsOpen2: true });
  };

  handleCloseModal2 = () => {
    this.setState({ modalIsOpen2: false });
  };


  render() {

  const data1 = 
    { id: this.state.id }
  ;
  const data = 
    { id: this.state.id }
  ;
  

   


    if(this.state.id>=81 && this.state.id<=90 ){
      return (
        <>
          <div className="DescRenovacion" onClick={this.openModal}>
            <h2 className="DescRenovacion__name">Acerca de </h2>
          </div>


          <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}>
          <PromotionDetail data={data1} close={this.handleCloseModal} />
          </Modal>
      

      <div className="DescRenovacion " onClick={this.openModal2}>
          <h2 className="DescRenovacion__name leftsaber">Saber más</h2>
          </div>
          <Modal isOpen={this.state.modalIsOpen2} onClose={this.handleCloseModal2}>
          <PromotionDetail2 data={data} close={this.handleCloseModal2} />
          </Modal>

          
          </>
      );
    }else{
      return (
        <div className='productList'>
          <h4 className='productList__empty-text'>
            </h4>
        </div>
      );

      }
  }
}

const mapStateToProps = reducers => {
  return reducers.categoryReducer;
};

export default connect(mapStateToProps)(ProductList);
