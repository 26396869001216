/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/styles/components/auth/Login.scss';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import Error from '../../layouts/Error';
import Loader from '../../layouts/Loader';
import { LoginAction } from '../../actions/LoginActions';
import store from '../../store';
import authService from '../../services/AuthService';
import { ReactComponent as SVGSUCCESS } from '../../assets/static/icons/success.svg';
import { createBrowserHistory } from 'history';

import Modal from '../../layouts/Modal';
import swal from 'sweetalert';


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.isSubmitting = props.isSubmitting;
    this.isValid = props.isValid;
    this.state = { loginData: {}, loading: false, error: null };
    this.message = null;
    const query = window.location.search.substring(1);
    this.token = query ? query.replace('token=', '') : null;


    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }


  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };


  validate = values => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Por favor, introduzca una contraseña.';
    }

    if (values.password !== values.confirmation) {
      errors.confirmation = 'Las contraseñas no coinciden.';
    }

    return errors;
  };

  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error />;
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{
          password: '',
          confirmation: ''
        }}
        validate={this.validate}
        onSubmit={async (values, actions) => {
          try {
            const res = await authService.resetPassword(
              this.token,
              values.password
            );
            const data = res.data;
            /*const browserHistory = createBrowserHistory({
              forceRefresh: true
            });*/

            //browserHistory.push('/login');

            swal(
              '¡Éxito!',
              'Su contraseña ha sido actualizada',
              'success'
            ).then(function() {
                window.location = "https://tin2go.com/";
            });

          } catch (error) {
            //console.log('error', error);
            //this.showModal();dsa

            swal(
              'Ups!',
              'Volver a solicitar el cambio',
              'error'
            ).then(function() {
                window.location = "recuperar_contrasena";
            });
           
            

}
          actions.setSubmitting(false);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          values,
          setValues,
          isValid
        }) => {
          return (
            <div className='auth-container'>
              <h1>Cambio de contraseña</h1>
              <p>Ingresa tu nueva contraseña.</p>
              <Form>
                <div className='form-group'>
                  <Field
                    className='width100'
                    type='password'
                    name='password'
                    placeholder='Contraseña'
                    value={values.password}
                  />
                  <ErrorMessage name='password'>
                    {message => <div className='inputError'>{message}</div>}
                  </ErrorMessage>
                </div><br></br>
                

                <div className='form-group'>
                  <Field
                    className='width100'
                    type='password'
                    name='confirmation'
                    placeholder='Confirmación de contraseña'
                    value={values.confirmation}
                  />
                  <ErrorMessage name='confirmation'>
                    {message => <div className='inputError'>{message}</div>}
                  </ErrorMessage>
                </div><br></br>

                <input
                  type='submit'
                  disabled={!isValid}
                  className={`btn-dark width100 submit`}
                  value='Cambiar contraseña'
                />
              </Form><br></br>
              

              <Link to='/login'>Regresar</Link>
            </div>
            
          );
        }}
      </Formik>
    );
  }
}

export default ResetPassword;
