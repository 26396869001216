import axios from 'axios';


class PaymentService {

    getPayment(clientId) {
        return new Promise(async(resolve, reject) => {

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/paycards/${clientId}`
                );
                resolve(response);
            } catch (error) {
                reject(error);
                console.log(error);
            }

        });

    }

    deletePayment() {


    }
}

const paymentService = new PaymentService();

export default paymentService;