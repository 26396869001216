import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Home from './containers/Home';
import Login from './components/auth/Login';
import Addresses from './components/address/Addresses';
import Layout from './layouts/Layout';
import './assets/styles/App.scss';
import PrivateRoute from './auth/PrivateRoute';
import Order from './containers/Order';
import Profile from './containers/Profile';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import SignUp from './components/SignUp';
import Terms from './components/Terms';
import Adherence from './components/Adherence';
import Privacy from './components/Privacity';
import Promotions from './containers/Promotions';

function App(props) {
  return (
    <Layout>
      <Switch>
        <PrivateRoute path="/orden" component={Order} />
        <Route exact path="/" component={Home} />
        <Route exact path="/promociones" component={Promotions} />
        <Route exact path="/registro" component={SignUp} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/direcciones" component={Addresses} />
        <PrivateRoute path="/perfil" component={Profile} />
        <Route path="/recuperar_contrasena" component={ForgotPassword} />
        <Route path="/cambiar_contasena" component={ResetPassword} />
        <Route path="/condiciones" component={Terms} />
        <Route path="/adhesion" component={Adherence} />
        <Route path="/privacidad" component={Privacy} />
      </Switch>
    </Layout>
  );
}

const mapStateToProps = reducers => {
  return reducers.loginReducer;
};

export default connect(mapStateToProps)(App);
