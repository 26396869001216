import React, { Component } from 'react';
import '../assets/styles/components/ProductAdd.scss';

class ProductAdd extends Component {
  render() {
    return (
      <figure className='productAdd'>
        <span className='productAdd__title'>Producto agregado</span>
        <span className='productAdd__text'>
          El producto elegido ha sido agregado en la cesta.
        </span>
        <button onClick={this.props.viewBasket} className='productAdd__button'>
          Ver cesta
        </button>
        <button onClick={this.props.onClose} className='productAdd__button'>
          Aceptar
        </button>
      </figure>
    );
  }
}

export default ProductAdd;
