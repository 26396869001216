import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as basketActions from '../../actions/basketActions';
import '../../assets/styles/components/auth/Login.scss';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import Error from '../../layouts/Error';
import Loader from '../../layouts/Loader';
import { LoginAction } from '../../actions/LoginActions';
import store from '../../store';
import authService from '../../services/AuthService';
import SocialButton from './SocialButton';
import { createBrowserHistory } from 'history';
import SignUp from '../SignUp';

class Login extends Component {
  state = {
    loading: false,
    error: null,
    tempData: [],
    socialData: false,
    socialSignUp: false
  };
  myStorage = window.localStorage;

  constructor(props) {
    super(props);
    this.isSubmitting = props.isSubmitting;
    this.isValid = props.isValid;
    this.state = { loginData: {}, loading: false, error: null };
    this.referer =
      (props.location.state ? props.location.state.referer : undefined) || '/';
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.profileMenu(false);
    this.props.setOpenModal(false);
  }

  validate = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Por favor, introduzca un correo electrónico.';
    }

    if (!this.validateEmail(values.email)) {
      errors.email = 'Por favor, introduzca un correo electrónico valido.';
    }

    if (!values.password) {
      errors.password = 'Por favor, introduzca su contraseña';
    }
    console.log('errors', errors);

    return errors;
  };

  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  getSocialUser = async socialId => {
    const requestBody = { idSocial: socialId };
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/socialLogin`,
        requestBody
      );
      this.setState({ loading: false, userData: response.data });
    } catch (error) {
      this.setState({ loading: false, socialData: false });
    }
  };

  socialLogin = async socialData => {
    console.log(socialData);
    const userData = {
      idSocial: socialData.customer.idSocial
    };
    this.setState({ loading: true, error: null });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/socialLogin`,
        userData
      );
      this.setState({ loading: false, error: null });
      const data = res.data;
      this.updateOrderTemp(data.customer.idCustomer);
      this.getOrderTemp(data.customer.idCustomer);
      const user = {
        ...data.customer,
        token: data.token
      };
      store.dispatch(LoginAction(user));
      //const location = store.history.location;
      const location = this.props.location.state;
      const browserHistory = createBrowserHistory({
        forceRefresh: true
      });
      if (location && location.redirectTo) {
        browserHistory.push(location.redirectTo);
        // this.props.history.replace(location.redirectTo);
      } else {
        // this.props.history.replace('/');
        browserHistory.push('/');
      }
    } catch (error) {
      this.setState({ loading: true, error: error });
    }
  };

  handleSocialLogin = async user => {
    await this.getSocialUser(user._profile.id);
    if (!this.state.userData) {
      this.setState({ socialSignUp: true, socialData: user });
    } else {
      this.socialLogin(this.state.userData);
    }
  };

  handleSocialLoginFailure = err => {
    console.error(err);
  };

  addBasket = product => {
    const content = this.props.basketReducer.content;
    content.push(product);
    this.props.setBasket(content);
  };

  getOrderTemp = async userId => {
    this.setState({ loading: true, error: null });
    let tempData = [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orderTemp/customer/${userId}`
      );
      this.setState({ loading: false, tempData: response.data });
      tempData = response.data;
    } catch (error) {
      this.setState({ loading: false, error: error });
    }

    if (tempData.length) {
      this.props.setBasket([]);
      tempData.forEach(element => {
        let productDry = null;
        let productWet = null;
        let product = null;

        if (element.product.priceWet) {
          if (element.service === 'Dry') {
            productDry = {
              idOrderTemp: element.idOrderTemp,
              displayType: 'Dry',
              idProduct: element.idProduct,
              keyProduct: `${element.idProduct}Dry`,
              name: element.product.nameProduct,
              numProd: parseInt(element.numProd),
              price: parseFloat(element.product.priceDry),
              section: element.section.nameSection,
              service: 'Dry',
              total: parseFloat(element.total)
            };
          }
          if (element.service === 'Wet') {
            productWet = {
              idOrderTemp: element.idOrderTemp,
              displayType: 'Wet',
              idProduct: element.idProduct,
              keyProduct: `${element.idProduct}Wet`,
              name: element.product.nameProduct,
              numProd: parseInt(element.numProd),
              price: parseFloat(element.product.priceWet),
              section: element.section.nameSection,
              service: 'Wet',
              total: parseFloat(element.total)
            };
          }
        } else {
          product = {
            idOrderTemp: element.idOrderTemp,
            displayType: '',
            idProduct: element.idProduct,
            keyProduct: `${element.idProduct}Dry`,
            name: element.product.nameProduct,
            numProd: parseInt(element.numProd),
            price: parseFloat(element.product.priceDry),
            section: element.section.nameSection,
            service: 'Dry',
            total: parseFloat(element.total)
          };
        }

        if (productDry) {
          this.addBasket(productDry);
        }

        if (productWet) {
          this.addBasket(productWet);
        }

        if (product) {
          this.addBasket(product);
        }
      });

      this.setTotal();

      this.setLocalWithReducer();
    }
  };

  setLocalWithReducer = () => {
    this.myStorage.setItem(
      't2gBasket',
      JSON.stringify(this.props.basketReducer)
    );
  };

  setTotal = () => {
    const content = this.props.basketReducer.content;
    let subtotal = 0;

    content.forEach(element => {
      subtotal += element.total;
    });
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);

    if (basket.promotion.type === 'ORDER') {
      switch (basket.promotion.discountType) {
        case 'PERCENT':
          const percent = parseInt(basket.promotion.discount) / 100;
          const discount = parseFloat((subtotal * percent).toFixed(2));
          basket.discount = discount;
          break;
        default:
          break;
      }
    }

    const total = subtotal - basket.discount;
    this.props.setSubtotal(subtotal);
    this.props.setDiscount(basket.discount);
    this.props.setTotal(total);
  };

  updateOrderTemp = userId => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);
    basket.content.forEach(async element => {
      const tempObj = {
        idCustomer: userId,
        idProduct: element.idProduct,
        service: element.service,
        numProd: element.numProd,
        commentsProd: '',
        total: element.total.toString()
      };

      this.setState({ loading: true, error: null });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/orderTemp`,
          tempObj
        );
        this.setState({ loading: false, tempResponse: response.data });
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error />;
    }

    if (this.state.socialSignUp) {
      return (
        <SignUp
          data={this.state.socialData}
          history={this.props.history}
          location={this.props.location}
        />
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: ''
        }}
        validate={this.validate}
        onSubmit={async (values, actions) => {
          this.setState({ loading: true, error: null });
          try {
            const res = await authService.login(values);
            this.setState({ loading: false, error: null });
            const data = res.data;
            this.updateOrderTemp(data.customer.idCustomer);
            this.getOrderTemp(data.customer.idCustomer);
            const user = {
              ...data.customer,
              token: data.token
            };
            store.dispatch(LoginAction(user));
            //const location = store.history.location;
            const location = this.props.location.state;
            const browserHistory = createBrowserHistory({
              forceRefresh: true
            });
            if (location && location.redirectTo) {
              browserHistory.push(location.redirectTo);
              // this.props.history.replace(location.redirectTo);
            } else {
              // this.props.history.replace('/');
              browserHistory.push('/');
            }
          } catch (error) {
            this.setState({ loading: false });
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          values,
          setValues,
          isValid
        }) => {
          return (
            <div className="auth-container">
              <h1>Inicio de sesión</h1>
              <p>Inicia sesión con tu cuenta de Tin2Go</p>
              <Form>
                <div className="form-group">
                  <span className="login__label">Correo electrónico</span>
                  <Field
                    className="width100"
                    type="email"
                    name="email"
                    value={values.email}
                  />
                  <ErrorMessage name="email">
                    {message => <div className="inputError">{message}</div>}
                  </ErrorMessage>
                </div>
                <br />
                <div className="form-group">
                  <span className="login__label">Contraseña</span>
                  <Field
                    className="width100"
                    type="password"
                    name="password"
                    value={values.password}
                  />
                  <ErrorMessage name="password">
                    {message => <div className="inputError">{message}</div>}
                  </ErrorMessage>
                </div>
                <br />
                <button
                  type="submit"
                  disabled={!isValid}
                  className={`loginButton submit`}
                >
                  Iniciar sesión
                </button>
              </Form>

              <div className="">
                <p>
                  ¿Se te olvidó tu contraseña?{' '}
                  <Link to="/recuperar_contrasena" className="passRecovery">
                    Recupérala
                  </Link>
                </p>
              </div>
              <div className="separator-login"></div>
              <div className="network">
                <SocialButton
                  provider="facebook"
                  appId="445354806176838"
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={this.handleSocialLoginFailure}
                >
                  Inicia Sesión con Facebook
                </SocialButton>
                <br />
                <SocialButton
                  provider="google"
                  appId="499210086403-2gqm9cn48eod87nlun4nf81brlfd2cna.apps.googleusercontent.com"
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={this.handleSocialLoginFailure}
                >
                  Inicia Sesión con Google
                </SocialButton>
              </div>
              <div className="signup">
                <p>¿No tienes cuenta en TIN2GO?</p>
                <Link to="/registro" className="passRecovery">
                  Regístrate
                </Link>
              </div>
              <br />
              <Link to="/">Ingresar como invitado</Link>
            </div>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer
  };
};

export default connect(mapStateToProps, basketActions)(Login);
