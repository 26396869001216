import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as localStorage from '../localStorage';
import * as basketActions from '../actions/basketActions';
import '../assets/styles/components/BasketProduct.scss';
import { ReactComponent as SVGAdd } from '../assets/static/icons/add.svg';
import { ReactComponent as SVGClose } from '../assets/static/icons/close.svg';
import { ReactComponent as SVGRemove } from '../assets/static/icons/remove.svg';

class BasketProduct extends Component {
  myStorage = window.localStorage;

  removeItem = () => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);

    const user = localStorage.getUserState();
    if (user && user.token) {
      basket.content.forEach(element => {
        if (element.keyProduct === this.props.keyProduct) {
          this.deleteOrderTemp(element.idOrderTemp);
        }
      });
    }

    basket.content = basket.content.filter(
      item => item.keyProduct !== this.props.keyProduct
    );
    basket.subtotal = 0;
    basket.content.forEach(item => {
      basket.subtotal += item.total;
    });
    if (basket.promotion.type === 'ORDER') {
      switch (basket.promotion.discountType) {
        case 'PERCENT':
          const percent = parseInt(basket.promotion.discount) / 100;
          const discount = parseFloat((basket.subtotal * percent).toFixed(2));
          basket.discount = discount;
          break;
        default:
          break;
      }
    }
    if (basket.subtotal - basket.discount < 0) {
      basket.total = 0;
    } else {
      basket.total = basket.subtotal - basket.discount;
    }
    this.myStorage.setItem('t2gBasket', JSON.stringify(basket));
    this.props.setBasket(JSON.parse(this.myStorage.t2gBasket).content);
    this.props.setSubtotal(JSON.parse(this.myStorage.t2gBasket).subtotal);
    this.props.setDiscount(JSON.parse(this.myStorage.t2gBasket).discount);
    this.props.setTotal(JSON.parse(this.myStorage.t2gBasket).total);

    if (user && user.token) {
      this.updateOrderTemp(user.idCustomer);
    }
  };

  deleteOrderTemp = async idOrderTemp => {
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/orderTemp/${idOrderTemp}`
      );
      this.setState({ loading: false, tempResponse: response.data });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  removeCont = () => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);
    basket.content.forEach(item => {
      if (item.keyProduct === this.props.keyProduct && item.numProd > 1) {
        const nextNumProd = item.numProd - 1;
        const nextTotal = item.price * nextNumProd;
        item.numProd = nextNumProd;
        item.total = nextTotal;
        return;
      }
    });
    basket.subtotal = 0;
    basket.content.forEach(item => {
      basket.subtotal += item.total;
    });
    if (basket.promotion.type === 'ORDER') {
      switch (basket.promotion.discountType) {
        case 'PERCENT':
          const percent = parseInt(basket.promotion.discount) / 100;
          const discount = parseFloat((basket.subtotal * percent).toFixed(2));
          basket.discount = discount;
          break;
        default:
          break;
      }
    }
    if (basket.subtotal - basket.discount < 0) {
      basket.total = 0;
    } else {
      basket.total = basket.subtotal - basket.discount;
    }
    this.myStorage.setItem('t2gBasket', JSON.stringify(basket));
    this.props.setBasket(JSON.parse(this.myStorage.t2gBasket).content);
    this.props.setSubtotal(JSON.parse(this.myStorage.t2gBasket).subtotal);
    this.props.setDiscount(JSON.parse(this.myStorage.t2gBasket).discount);
    this.props.setTotal(JSON.parse(this.myStorage.t2gBasket).total);

    const user = localStorage.getUserState();
    if (user && user.token) {
      this.updateOrderTemp(user.idCustomer);
    }
  };

  addCont = () => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);
    basket.content.forEach(item => {
      if (item.keyProduct === this.props.keyProduct) {
        const nextNumProd = item.numProd + 1;
        const nextTotal = item.price * nextNumProd;
        item.numProd = nextNumProd;
        item.total = nextTotal;
        return;
      }
    });
    basket.subtotal = 0;
    basket.content.forEach(item => {
      basket.subtotal += item.total;
    });

    if (basket.promotion.type === 'ORDER') {
      switch (basket.promotion.discountType) {
        case 'PERCENT':
          const percent = parseInt(basket.promotion.discount) / 100;
          const discount = parseFloat((basket.subtotal * percent).toFixed(2));
          basket.discount = discount;
          break;
        default:
          break;
      }
    }

    if (basket.subtotal - basket.discount < 0) {
      basket.total = 0;
    } else {
      basket.total = basket.subtotal - basket.discount;
    }
    this.myStorage.setItem('t2gBasket', JSON.stringify(basket));
    this.props.setBasket(JSON.parse(this.myStorage.t2gBasket).content);
    this.props.setSubtotal(JSON.parse(this.myStorage.t2gBasket).subtotal);
    this.props.setDiscount(JSON.parse(this.myStorage.t2gBasket).discount);
    this.props.setTotal(JSON.parse(this.myStorage.t2gBasket).total);

    const user = localStorage.getUserState();
    if (user && user.token) {
      this.updateOrderTemp(user.idCustomer);
    }
  };

  updateOrderTemp = userId => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);
    basket.content.forEach(async element => {
      const tempObj = {
        idCustomer: userId,
        idProduct: element.idProduct,
        service: element.service,
        numProd: element.numProd,
        commentsProd: '',
        total: element.total.toString()
      };

      this.setState({ loading: true, error: null });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/orderTemp`,
          tempObj
        );
        this.setState({ loading: false, tempResponse: response.data });
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  addBasketReducer = product => {
    let content = this.props.basketReducer.content;
    content.push(product);
    this.props.setBasket(content);
  };

  setTotal = () => {
    const content = this.props.basketReducer.content;
    let total = 0;

    content.forEach(element => {
      total += element.total;
    });

    this.props.setSubtotal(total);
    total -= this.props.basketReducer.discount;
    this.props.setTotal(total);
  };

  setLocalWithReducer = () => {
    this.myStorage.setItem(
      't2gBasket',
      JSON.stringify(this.props.basketReducer)
    );
  };

  render() {
    return (
      <div className="basketProduct">
        <div className="basketProduct__top">
          <div className="basketProduct__top__left">
            <h3 className="basketProduct__top__left-name">{this.props.name}</h3>
            <span className="basketProduct__top__left-price">
              {`${this.props.section} ${
                this.props.displayType
              }: $${this.props.price.toFixed(2)}`}
            </span>
          </div>
          <SVGClose
            className="basketProduct__top__close"
            onClick={this.removeItem}
          />
        </div>
        <div className="basketProduct__quantity">
          <div className="basketProduct__quantity__count">
            <SVGRemove
              className="basketProduct__quantity__count-icon"
              onClick={this.removeCont}
            />
            <span className="basketProduct__quantity__count-number">
              {this.props.numProd}
            </span>
            <SVGAdd
              className="basketProduct__quantity__count-icon"
              onClick={this.addCont}
            />
          </div>
          <span className="basketProduct__quantity-total">
            ${this.props.total.toFixed(2)}
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer
  };
};

export default connect(mapStateToProps, basketActions)(BasketProduct);
