import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as basketActions from '../actions/basketActions';
import BasketProductList from './BasketProductList';
import '../assets/styles/components/Basket.scss';
import { ReactComponent as SVGClose } from '../assets/static/icons/close.svg';

class Basket extends Component {
  myStorage = window.localStorage;

  removePromotion = () => {
    const localBasket = JSON.parse(this.myStorage.getItem('t2gBasket'));
    localBasket.promotion = {};
    localBasket.discount = 0;
    localBasket.total = localBasket.subtotal;
    this.myStorage.setItem('t2gBasket', JSON.stringify(localBasket));
    this.props.setPromotion({});
    this.props.setDiscount(0);
    this.props.setTotal(this.props.basketReducer.subtotal);
  };

  render() {
    return (
      <div className={`basket ${this.props.runway ? '' : 'noRunway'}`}>
        <h2 className="basket__title">Mi cesta</h2>
        <BasketProductList />
        {this.props.basketReducer.subtotal ? (
          <>
            <div className="basket__payDetail">
              {this.props.basketReducer.promotion.id ? (
                <div className="basket__payDetail__promotion">
                  <strong>Promoción aplicada</strong>
                  <br />
                  <div className="basket__payDetail__promotion__item">
                    <small>{this.props.basketReducer.promotion.name}</small>
                    <SVGClose
                      className="basket__payDetail__promotion__item__close"
                      onClick={this.removePromotion}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="basket__payDetail__item">
                <span>Subtotal</span>
                <span>{`$${this.props.basketReducer.subtotal.toFixed(
                  2
                )}`}</span>
              </div>
              <div className="basket__payDetail__item">
                <span>Descuento</span>
                <span>{`$${this.props.basketReducer.discount.toFixed(
                  2
                )}`}</span>
              </div>
              <div className="basket__payDetail__total">
                <span>TOTAL</span>
                <span>{`$${this.props.basketReducer.total.toFixed(2)}`}</span>
              </div>
            </div>
            {this.props.runway ? (
              ''
            ) : (
              <>
                <br />
                <div className="basket__pay">
                  {this.props.basketReducer.subtotal < 200 ? (
                    <p>El monto mínimo para un pedido es de $200.00</p>
                  ) : (
                    <Link to="/orden">
                      <button className="basket__pay-button">{`Pagar: $${this.props.basketReducer.total.toFixed(
                        2
                      )}`}</button>
                    </Link>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer
  };
};

export default connect(mapStateToProps, basketActions)(Basket);
