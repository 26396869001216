import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as basketActions from '../actions/basketActions';
import '../assets/styles/components/PromotionDetail.scss';

class PromotionDetail extends Component {
  


  
  
  render() {
    const {
      data
    } = this.props;

    
    

    

    if(data.id ==81){
      var names = 'Belleza para tu calzado y/o recuperación de calzado maltratado ';
     // var icon = <img src={SVGWarning}  class="imgde" ></img>;
    }else if(data.id ==82){
      var names = 'El desgaste de las tapas de los zapatos o sandalias es muy común, y es por eso que este producto es uno de los más solicitados, pero al mismo tiempo más eficientes para el cuidado de tus zapatos. El cambio de tapas hará que tu calzado luzca como nuevo.';
    }else if(data.id ==83){
      var names = '¿Te ha pasado el que un tacón se te zafe o se te rompa por el uso o desgaste?  si tienes un calzado con este problema, aquí tienes la solución ya que este servicio se da tanto para calzado de hombre como de mujer, cambiando el tacón, forrándolo y pintándolo en el caso de calzado femenino y sustituyéndolo por uno de cuero o goma en el caso de los calzados masculinos.';
    }else if(data.id ==84){
      var names = 'Este producto nos permite mantener las suelas de los zapatos en un muy buen estado por mucho más tiempo, protegiéndolo de la humedad, ya que aplicamos una media suela que protege la original, consiguiendo protección agarre y duración constante. Esta media suela puede ponerse recién comprado el calzado o después de un desgaste del mismo.'
    }else if(data.id ==85){
      var names = 'El aplicar cambio de tapas y proteger las suelas, hace que tu calzado esté en perfecto estado para que lo uses.     En este servicio combinamos dos en uno y conseguimos que la suela de tu calzado quede como nuevo remplazando las tapas y la cubierta de media suela para una mayor protección. Este servicio dejará la parte de abajo de tus zapatos como recién salidos de la caja.'
    }else if(data.id ==86){
      var names = 'El calzado habla mucho de quien lo porta por eso su cuidado, mantenimiento y limpieza son tan importantes no solo para el zapato sino para quien lo calza. Este servicio dejará tus zapatos como nuevos ya que tendrán un mantenimiento completo con; cambio de suela o Doble Vida, Tapas, Plantillas, Costuras, pintura y hormado, para que vuelvas a estrenar zapatos.'
    }else if(data.id ==87){
      var names = '¿Alguna vez se te ha descosido o despegado una suela? ¿Qué has hecho con esos zapatos? ¿tirarlos? No lo hagas y pide este servicio donde coseremos o pegaremos la suela según lo requiera, para que puedas seguir disfrutando de esos zapatos que tanto te gustan.'
    }else if(data.id ==88){
      var names = 'Tienes un par de zapatos, que te quedan justos, apretados y además lastiman tus pies. Pues ya no has de sufrir, porque este producto es el que estabas buscando, donde ensanchamos lo necesario tu calzado para que te quede como un guante y vuelvas a lucir ese calzado que tanto te gusta.'
    }else if(data.id ==89){
      var names = 'Al paso del tiempo el color de los zapatos se va desgastando y pierde su brillo, su porte y color, este producto es para regresar ese brillo y calidad al calzado, ya que un calzado limpio y reluciente habla muy bien de quien lo porta.'
    }else if(data.id ==90){
      var names = 'Lavamos y restauramos el calzado de gamuza, devolviendo en lo posible en mejor estado en cuanto a limpieza y color';
    }

    


      return (
        <div className="PromotionDetail">
          {/*icon*/}
          <p className="TextoDesc">
            
            <br /> 
            {names}
            
            

          </p>
          <button
            onClick={() => {
              this.props.close();
            }}
            className="PromotionDetail__button"
          >
            Aceptar
          </button>
        </div>
      );

  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer
  };
};

export default connect(mapStateToProps, basketActions)(PromotionDetail);
