import React, { Component } from 'react';
import '../assets/styles/components/Promotion.scss';
import { ReactComponent as SVGMoney } from '../assets/static/icons/money.svg';
import { ReactComponent as SVGPercent } from '../assets/static/icons/percent.svg';
import Modal from '../layouts/Modal';
import PromotionDetail from './PromotionDetail';

class Promotion extends Component {
  state = {
    modalIsOpen: false
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { data } = this.props;

    const PromotionIcon = () => {
      switch (data.discountType) {
        case 'MONEY':
          return <SVGMoney className="Promotion__icon" />;
        case 'PERCENT':
          return <SVGPercent className="Promotion__icon" />;
        default:
          return '';
      }
    };

    const PromotionDiscount = () => {
      switch (data.discountType) {
        case 'PERCENT':
          return (
            <small className="Promotion__description">
              {`${data.discount}% de descuento sobre el total de tu orden.`}
            </small>
          );
        default:
          return '';
      }
    };

    if(data.isVisible==1){

      return (
        <>
          <div className="Promotion" onClick={this.openModal}>
            <PromotionIcon />
            <h2 className="Promotion__name">{data.name}</h2>
            <PromotionDiscount />
          </div>
          <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}>
            <PromotionDetail data={data} close={this.handleCloseModal} />
          </Modal>
        </>
      );
    }else{
      return (
        
          <div className="Promoti asdon" >
          </div>
          
      );
    }
  }
}

export default Promotion;
