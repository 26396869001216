import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as categoryActions from '../actions/categoryActions';
import '../assets/styles/components/Category.scss';

class Category extends Component {
  state = {
    active: false
  };

  activateCategory = () => {
    this.props.activateCategory(this.props.data.idCategory);
  };

  render() {
    if (
      this.props.data.nameCategory.toLowerCase().includes('convenio') ||
      this.props.data.nameCategory.toLowerCase().includes('tikets pendientes')
    ) {
      return '';
    }

    return (
      <figure
        className={`category ${
          this.props.data.idCategory === this.props.activeCategory
            ? 'active'
            : ''
        }`}
        onClick={this.activateCategory}
      >
        <span className='category__name'>{this.props.data.nameCategory}</span>
      </figure>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.categoryReducer;
};

export default connect(mapStateToProps, categoryActions)(Category);
