import React, { Component } from 'react';
import axios from 'axios';
import '../assets/styles/components/PaymentDelete.scss';
import Loader from '../layouts/Loader';
import Error from '../layouts/Error';

class PaymentDelete extends Component {
  state = {
    error: null,
    loading: false,
    response: false
  };

  deletePayCard = async () => {
    this.setState({ loading: true, error: null });

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/paycards/${this.props.data.idPaycards}`
      );
      this.setState({ loading: false, response: response });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  closeModal = () => {
    this.props.handleCloseModal();
  };

  render() {
    if (this.state.loading) {
      return (
        <div className='paymentDelete'>
          <Loader />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className='paymentDelete'>
          <Error />
        </div>
      );
    }

    if (!this.state.response) {
      return (
        <div className='paymentDelete'>
          <h1 className='paymentDelete__title'>Eliminación de tarjeta</h1>
          <p>
            ¿Estás seguro de eliminar la tarjeta con terminación:
            <strong>{` ${this.props.data.lastfour}`}</strong>?
          </p>
          <button onClick={this.deletePayCard}>Eliminar</button>
        </div>
      );
    }

    if (this.state.response) {
      return (
        <div className='paymentDelete'>
          <h1 className='paymentDelete__title'>Eliminación de tarjeta</h1>
          <p>
            La tarjeta con terminación
            <strong>{` ${this.props.data.lastfour} `}</strong>
            ha sido eliminada correctamente.
          </p>
          <button onClick={this.closeModal}>Aceptar</button>
        </div>
      );
    }
  }
}

export default PaymentDelete;
