const INITIAL_STATE = {
  execute: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_EXECUTE':
      return {
        ...state,
        execute: action.payload
      };
    default:
      return state;
  }
};
