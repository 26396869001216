import React, { Component } from 'react';
import ProductList from './ProductList';
import ProductListDesc from './ProductList_desc';

import { ReactComponent as SVGInformation } from '../assets/static/icons/information.svg';
import '../assets/styles/components/Products.scss';

class Products extends Component {
  render() {
    return (
      <section className='products'>
        <div className='products__header'>
          <p className='products__header-text'>
            Tu ropa siempre lista con nuestro servicio experto de tintorería.
          </p>
          <SVGInformation className='products__header-icon' />
        </div>
        <ProductListDesc /><br></br>
        <ProductList />
      </section>
    );
  }
}

export default Products;
