import React from 'react';
import logo from '../assets/static/images/logo.png';
import app from '../assets/static/images/app.png';
import appleBadge from '../assets/static/images/app-store-badge.png';
import googleBadge from '../assets/static/images/google-play-badge.png';
import maxLogo from '../assets/static/images/max-logo.png';
import '../assets/styles/components/Mobile.scss';

const Mobile = () => (
  <div className="Mobile">
    <div className="Mobile__header">
      <a href="https://tin2go.com/" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="Tin2Go logo" />
      </a>
    </div>
    <div className="Mobile__content">
      <h1 className="Mobile__content__title">
        Tin2Go, ¡Tintorería en 1, 2 por click!
      </h1>
      <p className="Mobile__content__text">
        Descarga la app de Tin2Go en tu smartphone y pide cuando y desde dónde
        quieras.
      </p>
      <div className="Mobile__content__badges">
        <a
          href={process.env.REACT_APP_APPLE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <figure className="Mobile__content__badges__item">
            <img src={appleBadge} alt="Apple Badge" />
          </figure>
        </a>
        <a
          href={process.env.REACT_APP_ANDROID_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <figure className="Mobile__content__badges__item">
            <img src={googleBadge} alt="Apple Badge" />
          </figure>
        </a>
      </div>
      <img className="Mobile__content__img" src={app} alt="App" />
    </div>
    <div className="Mobile__footer">
      <div className="Mobile__footer__contact footer__item ">
        <p className="Mobile__footer__item-text link">
          <a
            href="https://api.whatsapp.com/send?phone=5215526539102"
            target="_blank"
            rel="noopener noreferrer"
          >
            (55) 2653 9102
          </a>
        </p>
        <p>@Tin2Go</p>
        <p className="footer__item-text">
          <a href="mailto:servicioalcliente@tin2go.com">
            servicioalcliente@tin2go.com
          </a>
        </p>
        <p>Con la garantía de Tintorerías MAX</p>
        <a
          href="https://www.tintoreriasmax.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={maxLogo} alt="MAX logo" />
        </a>
      </div>
    </div>
  </div>
);

export default Mobile;
