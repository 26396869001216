import React from 'react';
import wagonImg from '../assets/static/images/wagon.png';
import '../assets/styles/components/Hero.scss';

const Hero = () => (
  <section className='hero'>
    <div className='hero__text'>
      <h2>Servicios de tintorería, lavandería y planchado a domicilio</h2>
      <p>Sin complicaciones tu ropa siempre lista en 1, 2 por click.</p>
    </div>
    <div>
      <img src={wagonImg} alt='Wagon' />
    </div>
  </section>
);

export default Hero;
