import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as sectionActions from '../actions/sectionActions';
import { ReactComponent as SVGDry } from '../assets/static/icons/dry.svg';
import { ReactComponent as SVGIroning } from '../assets/static/icons/ironing.svg';
import { ReactComponent as SVGLaundry } from '../assets/static/icons/laundry.svg';
import { ReactComponent as SVGFootwear } from '../assets/static/icons/footwear.svg';
import { ReactComponent as SVGTailor } from '../assets/static/icons/tailor.svg';
import { ReactComponent as SVGMore } from '../assets/static/icons/more.svg';
import { ReactComponent as SVGPiel } from '../assets/static/icons/piel.svg';
import { ReactComponent as SVGTapete } from '../assets/static/icons/tapete.svg';
import { ReactComponent as SVGBed } from '../assets/static/icons/bed.svg';
import { ReactComponent as SVGHome} from '../assets/static/icons/casa.svg';
import { ReactComponent as SVGCar} from '../assets/static/icons/car.svg';
import { ReactComponent as SVGPets} from '../assets/static/icons/mascotas.svg';
import { ReactComponent as SVGClean} from '../assets/static/icons/cleaning.svg';
import { ReactComponent as SVGCar2} from '../assets/static/icons/car2.svg';
import { ReactComponent as SVGRug} from '../assets/static/icons/rug.svg';
import { ReactComponent as SVGShoes} from '../assets/static/icons/shoes.svg';
import { ReactComponent as SVGPackage} from '../assets/static/icons/package.svg';
import '../assets/styles/components/Section.scss';

class Section extends Component {
  state = {
    active: false
  };

  activateSection = () => {
    this.props.activateSection({
      id: this.props.data.idSection,
      name: this.props.data.nameSection
    });
  };

  selectIcon() {
    switch (this.props.data.nameSection) {
      case 'Tintorería':
        return <SVGDry className='section__icon-svg' />;
      case 'Lavandería':
        return <SVGLaundry className='section__icon-svg' />;
      case 'Planchado':
        return <SVGIroning className='section__icon-svg' />;
      case 'Calzado':
        return <SVGFootwear className='section__icon-svg' />;
      case 'Sastrería':
        return <SVGTailor className='section__icon-svg' />;
      case 'Piel y Gamuza':
        return <SVGPiel className='section__icon-svg' />;
      case 'Tapetes, Salas , Colchones y otros muebles':
        return <SVGTapete className='section__icon-svg' />;
      case 'Teñido':
        return <SVGBed className='section__icon-svg' />;
      case 'Mascotas':
        return <SVGPets className='section__icon-svg' />;
      case 'Desinfección de Espacios ':
        return <SVGClean className='section__icon-svg' />;

      case 'Aseo Automotriz ':
        return <SVGCar2 className='section__icon-svg' />;

      case 'Tapetes, Salas y Colchones':
        return <SVGRug className='section__icon-svg' />;

      case 'Renovación de Calzado':
        return <SVGShoes className='section__icon-svg' />;
              
      case 'Package':
        return <SVGPackage className='section__icon-svg' />;
        
      case 'Desinfección Residencial y Comercial':
        return <SVGHome className='section__icon-svg' />;

      case 'Vestiduras de Autos':
        return <SVGCar className='section__icon-svg' />;
      default:
        return <SVGMore className='section__icon-svg' />;
    }
  }

  render() {
    return (
      <div className='section'>
        <figure
          className={`section__icon ${
            this.props.data.idSection === this.props.activeSection.id
              ? 'active'
              : ''
          }`}
          onClick={this.activateSection}
        >
          {this.selectIcon()}
        </figure>
        <p className='section__title'>{this.props.data.nameSection}</p>
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.sectionReducer;
};

export default connect(mapStateToProps, sectionActions)(Section);
