import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as orderActions from '../actions/orderActions';
import * as localStorage from '../localStorage';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { withFormik, Field, ErrorMessage, Form } from 'formik';
import '../assets/styles/components/AddressSelect.scss';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';
import { addDays, parseJSON } from 'date-fns';
import { differenceInHours } from 'date-fns/esm';

class AddressSelect extends Component {
  state = {
    dateRecovery: setHours(setMinutes(addDays(new Date(), 1), 0), 9),
    dateDelivery: setHours(setMinutes(addDays(new Date(), 2), 0), 19),
    loading: false,
    error: null,
    addressData: [],
    diffRecovery: false,
    diffDelivery: false
  };
  includeDates = [
    new Date(),
    addDays(new Date(), 1),
    addDays(new Date(), 2),
    addDays(new Date(), 3),
    addDays(new Date(), 4),
    addDays(new Date(), 5),
    addDays(new Date(), 6),
    addDays(new Date(), 7),
    addDays(new Date(), 8),
    addDays(new Date(), 9),
    addDays(new Date(), 10),
    addDays(new Date(), 11),
    addDays(new Date(), 12),
    addDays(new Date(), 13),
    addDays(new Date(), 14)
  ];
  includeRecoveryDates = [
    addDays(new Date(), 2),
    addDays(new Date(), 3),
    addDays(new Date(), 4),
    addDays(new Date(), 5),
    addDays(new Date(), 6),
    addDays(new Date(), 7),
    addDays(new Date(), 8),
    addDays(new Date(), 9),
    addDays(new Date(), 10),
    addDays(new Date(), 11),
    addDays(new Date(), 12),
    addDays(new Date(), 13),
    addDays(new Date(), 14)
  ];
  includeTimes = [
    setHours(setMinutes(new Date(), 0), 9),
    setHours(setMinutes(new Date(), 0), 11),
    setHours(setMinutes(new Date(), 0), 13),
    setHours(setMinutes(new Date(), 0), 15),
    setHours(setMinutes(new Date(), 0), 17),
    setHours(setMinutes(new Date(), 0), 19)
  ];

  getAddresses = async idCustomer => {
    this.setState({ loading: true, error: null });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/addressByCustomer/${idCustomer}`
      );
      this.setState({
        loading: false,
        addressData: response.data
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  recoveryChange = date => {
    if (differenceInHours(date, new Date()) < 2) {
      this.setState({ diffRecovery: true });
    } else {
      this.setState({ diffRecovery: false });
    }
    if (differenceInHours(this.state.dateDelivery, date) < 48) {
      this.setState({ diffDelivery: true });
    } else {
      this.setState({ diffDelivery: false });
    }
    this.setState({
      dateRecovery: date
    });
  };

  deliveryChange = date => {
    if (differenceInHours(date, this.state.dateRecovery) < 48) {
      this.setState({ diffDelivery: true });
    } else {
      this.setState({ diffDelivery: false });
    }
    this.setState({
      dateDelivery: date
    });
  };

  componentDidMount() {
    const user = localStorage.getUserState();
    this.getAddresses(user.idCustomer);
    if (
      differenceInHours(this.state.dateDelivery, this.state.dateRecovery) < 48
    ) {
      this.setState({ diffDelivery: true });
    } else {
      this.setState({ diffDelivery: false });
    }
  }

  toNewAddress = () => {
    window.scrollTo(0, 0);
    this.props.setStep('newAddress');
  };

  toCardPay = () => {
    this.props.setOrderDateRecovery(this.props.values.dateRecovery);
    this.props.setOrderDateDelivery(this.props.values.dateDelivery);
    this.props.setOrderAddressRecovery(this.props.values.addressRecovery);
    this.props.setOrderAddressDelivery(this.props.values.addressDelivery);
    this.state.addressData.forEach(element => {
      if (element.idAddress === parseInt(this.props.values.addressRecovery)) {
        this.props.setAddressRecovery(element.address);
      }
      if (element.idAddress === parseInt(this.props.values.addressDelivery)) {
        this.props.setAddressDelivery(element.address);
      }
    });
    window.scrollTo(0, 0);
    this.props.setStep('cardPay');
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      if (this.state.dateRecovery) {
        this.props.setFieldValue(
          'dateRecovery',
          this.state.dateRecovery.toJSON()
        );
      }
      this.props.setFieldValue(
        'dateDelivery',
        this.state.dateDelivery.toJSON()
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error vertical={true} />;
    }

    return (
      <div className='addressSelect'>
        <Form>
          <h3 className='addressSelect-title'>Dirección de recolección</h3>
          <Field as='select' name='addressRecovery'>
            <option defaultValue='' disabled hidden />
            {this.state.addressData.map(element => {
              return (
                <option
                  key={element.idAddress}
                  value={element.idAddress}
                  label={`${element.address.street} ${element.address.numInt} ${element.address.numExt}`}
                />
              );
            })}
          </Field>
          <ErrorMessage name='addressRecovery'>
            {message => <div className='inputError'>{message}</div>}
          </ErrorMessage>
          <h3 className='addressSelectDelivery-title'>
            Fecha y hora de recolección
          </h3>
          <DatePicker
            className='picker'
            selected={this.state.dateRecovery}
            name='dateRecovery'
            onChange={this.recoveryChange}
            dateFormat='d MMMM , yyyy h:mm aa'
            locale={es}
            showTimeSelect
            includeTimes={this.includeTimes}
            includeDates={this.includeDates}
          />
          {this.state.diffRecovery ? (
            <div className='inputError'>
              La fecha de recolección debe ser agendada con un mínimo de 2 horas
              de anticipación.
            </div>
          ) : (
            ''
          )}
          <h3 className='addressSelectDelivery-title'>Dirección de entrega</h3>
          <Field as='select' name='addressDelivery'>
            <option defaultValue='' disabled hidden />
            {this.state.addressData.map(element => {
              return (
                <option
                  key={element.idAddress}
                  value={element.idAddress}
                  label={`${element.address.street} ${element.address.numInt} ${element.address.numExt}`}
                />
              );
            })}
          </Field>
          <ErrorMessage name='addressDelivery'>
            {message => <div className='inputError'>{message}</div>}
          </ErrorMessage>
          <h3 className='addressSelectDelivery-title'>
            Fecha y hora de entrega
          </h3>
          <DatePicker
            className='picker'
            selected={this.state.dateDelivery}
            name='dateDelivery'
            onChange={this.deliveryChange}
            dateFormat='d MMMM , yyyy h:mm aa'
            locale={es}
            showTimeSelect
            includeTimes={this.includeTimes}
            includeDates={this.includeRecoveryDates}
          />
          {this.state.diffDelivery ? (
            <div className='inputError'>
              La fecha de entrega debe ser agendada con un mínimo de 48 horas
              posteriores a la fecha de recolección.
            </div>
          ) : (
            ''
          )}
          <button
            type='button'
            onClick={this.toCardPay}
            className={`btn-dark margin50 width100 submit ${
              this.props.isSubmitting || !this.props.isValid ? 'disabled' : ''
            }`}
            disabled={this.props.isSubmitting || !this.props.isValid}
          >
            Continuar
          </button>
        </Form>
        <span className='newLink' onClick={this.toNewAddress}>
          Registrar una nueva dirección...
        </span>
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.orderReducer;
};

const _AddressSelect = connect(mapStateToProps, orderActions)(AddressSelect);

export default connect(
  mapStateToProps,
  orderActions
)(
  withFormik({
    mapPropsToValues(props) {
      return {
        addressRecovery: '',
        addressDelivery: '',
        dateRecovery: setHours(setMinutes(new Date(), 0), 19),
        dateDelivery: setHours(setMinutes(addDays(new Date(), 2), 0), 19)
      };
    },

    validate(values) {
      const errors = {};

      if (!values.addressRecovery) {
        errors.addressRecovery =
          'Por favor selecciona una dirección de recolección.';
      }

      if (differenceInHours(parseJSON(values.dateRecovery), new Date()) < 2) {
        errors.dateRecovery =
          'La fecha de recolección debe agendarse con por lo menos 2 horas de anticipación.';
      }

      if (!values.addressDelivery) {
        errors.addressDelivery =
          'Por favor selecciona una dirección de entrega.';
      }

      if (
        differenceInHours(
          parseJSON(values.dateDelivery),
          parseJSON(values.dateRecovery)
        ) < 48
      ) {
        errors.dateDelivery =
          'La fecha de recolección debe agendarse con por lo menos 2 horas de anticipación.';
      }

      return errors;
    }
  })(_AddressSelect)
);
