import React, { Component } from 'react';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import '../assets/styles/components/OrderItem.scss';
import Modal from '../layouts/Modal';
import OrderDetail from './OrderDetail';

class OrderItem extends Component {
  state = {
    orderDate: null,
    modalIsOpen: false,
    modalContent: null
  };

  componentDidMount() {
    this.formatDate();
  }

  formatDate = () => {
    const dateJSON = this.props.data.orderDate;
    const date = new Date(dateJSON);
    this.setState({
      orderDate: format(date, 'd MMMM yyyy h:mm aa', { locale: es })
    });
  };

  openModal = component => {
    this.setState({ modalIsOpen: true, modalContent: component });
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
    this.props.reload();
  };

  render() {
    return (
      <>
        <div
          className='orderItem'
          onClick={() =>
            this.openModal(
              <OrderDetail
                data={this.props.data}
                handleCloseModal={this.handleCloseModal}
                changeModal={this.openModal}
              />
            )
          }
        >
          <div className='orderItem__details'>
            <h1 className='orderItem__details__title'>
              Pedido: {this.props.data.idOrder}
            </h1>
            <span className='orderItem__details__date'>
              Fecha: {this.state.orderDate}
            </span>
          </div>
          <div className='orderItem__status'>
            {this.props.data.paymentStatus !== 'PAGADO' ? (
              <h5 className='orderItem__status__text noPay'>
                Pendiente de pago
              </h5>
            ) : (
              <h5 className='orderItem__status__text'>
                {this.props.data.orderTracking}
              </h5>
            )}
          </div>
        </div>
        <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}>
          {this.state.modalContent}
        </Modal>
      </>
    );
  }
}

export default OrderItem;
