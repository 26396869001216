import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as localStorage from '../localStorage';
import * as orderActions from '../actions/orderActions';
import { withFormik, Field, ErrorMessage, Form } from 'formik';
import { ReactComponent as SVGSuccess } from '../assets/static/icons/success.svg';
import '../assets/styles/components/PaymentSelect.scss';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';

class PaymentSelect extends Component {
  state = {
    loading: false,
    error: null,
    cardsData: [],
    paymentResponse: false
  };

  componentDidMount() {
    const user = localStorage.getUserState();
    this.getCards(user.idCustomer);
  }

  getCards = async idCustomer => {
    this.setState({ loading: true, error: null });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/paycards/${idCustomer}`
      );
      this.setState({
        loading: false,
        cardsData: response.data
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  toNewPayment = () => {
    if (window.location.pathname === '/orden') {
      window.scrollTo(0, 0);
      this.props.setStep('newCardPay');
    } else {
      this.props.change();
    }
  };

  paySubmit = async () => {
    if (window.location.pathname === '/orden') {
      this.state.cardsData.forEach(element => {
        if (element.idPaycards === parseInt(this.props.values.payCard)) {
          this.props.setPaymentPayCard(element.idPaycards);
          this.props.setPaymentLastFour(element.lastfour);
        }
      });
      this.props.setStep('confirm');
    } else {
      console.log(this.props.values, this.props.data);
      const payObj = {
        idOrder: this.props.data.idOrder,
        idPayCard: parseInt(this.props.values.payCard)
      };
      this.setState({ loading: true, error: null });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/payment/${this.props.data.idCustomer}`,
          payObj
        );
        this.setState({ loading: false, paymentResponse: response.data });
        console.log(this.state.paymentResponse);
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error vertical={true} />;
    }

    if (this.state.paymentResponse) {
      return (
        <div className='paymentSelect selectSuccessPay'>
          <div className='paymentSelect__successPay'>
            <SVGSuccess />
            <p className='paymentSelect__successPay__text'>
              El pago de tu pedido se ha realizado correctamente.
            </p>
            <button onClick={this.props.close}>Aceptar</button>
          </div>
        </div>
      );
    }

    return (
      <div className='paymentSelect'>
        <h3 className='paymentSelect-title'>Tarjeta de crédito o débito</h3>
        <Form>
          <Field as='select' name='payCard'>
            <option defaultValue='' disabled hidden />
            {this.state.cardsData.map(element => {
              return (
                <option
                  key={element.idPaycards}
                  value={element.idPaycards}
                  label={`•••• •••• •••• ${element.lastfour}`}
                />
              );
            })}
          </Field>
          <ErrorMessage name='payCard'>
            {message => <div className='inputError'>{message}</div>}
          </ErrorMessage>
          <button
            type='button'
            onClick={this.paySubmit}
            className={`btn-dark margin50 width100 submit ${
              this.props.isSubmitting || !this.props.isValid ? 'disabled' : ''
            }`}
            disabled={
              this.props.isSubmitting ||
              !this.props.isValid ||
              !this.props.values.payCard
            }
          >
            Continuar
          </button>
        </Form>
        <span className='newLink' onClick={this.toNewPayment}>
          Registrar una nueva tarjeta...
        </span>
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.orderReducer;
};

const _PaymentSelect = connect(mapStateToProps, orderActions)(PaymentSelect);

export default withFormik({
  mapPropsToValues(props) {
    return {
      payCard: ''
    };
  },

  validate(values) {
    const errors = {};

    if (!values.payCard) {
      errors.payCard = 'Por favor selecciona una tarjeta.';
    }

    return errors;
  }
})(_PaymentSelect);
