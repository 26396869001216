import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import es from 'date-fns/locale/es';
import { format } from 'date-fns';
import * as orderActions from '../actions/orderActions';
import * as localStorage from '../localStorage';
import '../assets/styles/components/Confirm.scss';
import Loader from '../layouts/Loader';
import Error from '../layouts/Error';

class Confirm extends Component {
  state = {
    loading: false,
    error: null,
    tempData: [],
    recoveryDate: null,
    recoveryHour: null,
    deliveryDate: null,
    deliveryHour: null,
    newUser: false
  };
  myStorage = window.localStorage;

  confirmSubmit = () => {
    window.scrollTo(0, 0);
    this.props.setStep('success');
  };

  componentDidMount() {
    this.isNewUser();
    this.getTempOrder();
    this.formatDates();
    this.setLocalWithReducer();
  }

  isNewUser = async () => {
    const user = localStorage.getUserState();
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/customer/${user.idCustomer}`
      );
      if (!response.data.length) {
        this.setState({ newUser: true });
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  getTempOrder = async () => {
    const user = localStorage.getUserState();
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orderTemp/customer/${user.idCustomer}`
      );
      this.setState({ loading: false, tempData: response.data });
      let subTotal = 0;
      this.state.tempData.forEach(element => {
        subTotal += parseInt(element.total);
      });
      this.props.setSubtotal(subTotal);
      if (this.props.basketReducer.promotion.type === 'ORDER') {
        switch (this.props.basketReducer.promotion.discountType) {
          case 'PERCENT':
            const percent =
              parseInt(this.props.basketReducer.promotion.discount) / 100;
            const discount = parseFloat((subTotal * percent).toFixed(2));
            this.props.setDiscount(discount);
            break;
          default:
            break;
        }
      }
      const total =
        this.props.basketReducer.subtotal - this.props.basketReducer.discount;
      this.props.setTotal(total);
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  setLocalWithReducer = () => {
    this.myStorage.setItem(
      't2gBasket',
      JSON.stringify(this.props.basketReducer)
    );
  };

  formatDates = () => {
    const dateRecoveryJSON = this.props.orderObjReducer.dateOfRecovery;
    const dateRecovery = new Date(dateRecoveryJSON);
    const recoveryHour = format(dateRecovery, 'h:mm aa', { locale: es });
    this.setState({
      recoveryDate: format(dateRecovery, 'd MMMM yyyy', { locale: es })
    });
    switch (recoveryHour) {
      case '9:00 AM':
        this.setState({ recoveryHour: '9:00 AM - 11:00 AM' });
        break;

      case '11:00 AM':
        this.setState({ recoveryHour: '11:00 AM - 1:00 PM' });
        break;

      case '1:00 PM':
        this.setState({ recoveryHour: '1:00 PM - 3:00 PM' });
        break;

      case '3:00 PM':
        this.setState({ recoveryHour: '3:00 PM - 5:00 PM' });
        break;

      case '5:00 PM':
        this.setState({ recoveryHour: '5:00 PM - 7:00 PM' });
        break;

      case '7:00 PM':
        this.setState({ recoveryHour: '7:00 PM - 9:00 PM' });
        break;

      default:
        this.setState({ recoveryHour: 'Sin horario' });
        break;
    }

    const dateDeliveryJSON = this.props.orderObjReducer.dateOfDelivery;
    const dateDelivery = new Date(dateDeliveryJSON);
    const deliveryHour = format(dateDelivery, 'h:mm aa', { locale: es });
    this.setState({
      deliveryDate: format(dateDelivery, 'd MMMM yyyy', { locale: es })
    });
    switch (deliveryHour) {
      case '9:00 AM':
        this.setState({ deliveryHour: '9:00 AM - 11:00 AM' });
        break;

      case '11:00 AM':
        this.setState({ deliveryHour: '11:00 AM - 1:00 PM' });
        break;

      case '1:00 PM':
        this.setState({ deliveryHour: '1:00 PM - 3:00 PM' });
        break;

      case '3:00 PM':
        this.setState({ deliveryHour: '3:00 PM - 5:00 PM' });
        break;

      case '5:00 PM':
        this.setState({ deliveryHour: '5:00 PM - 7:00 PM' });
        break;

      case '7:00 PM':
        this.setState({ deliveryHour: '7:00 PM - 9:00 PM' });
        break;

      default:
        this.setState({ deliveryHour: 'Sin horario' });
        break;
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <section className="confirm">
          <Loader />
        </section>
      );
    }

    if (this.state.error) {
      return (
        <section className="confirm">
          <Error />
        </section>
      );
    }

    return (
      <section className="confirm">
        <div className="confirm__details">
          <h1 className="confirm__title">Detalles del pedido</h1>
          <p>Por favor verifica los detalles del pedido y confirma.</p>
          <div className="confirm__addresses">
            <div className="confirm__addresses__item">
              <h4 className="confirm__title">Dirección de recolección</h4>
              <span>
                {this.props.addressReducer.addressRecovery.street}{' '}
                {this.props.addressReducer.addressRecovery.numExt}{' '}
                {this.props.addressReducer.addressRecovery.numInt}
                <br />
                {this.props.addressReducer.addressRecovery.colony}
                <br />
                {this.props.addressReducer.addressRecovery.locality}
                <br />
                {`C.P. ${this.props.addressReducer.addressRecovery.CP}`}
              </span>
            </div>
            <div className="confirm__addresses__item">
              <h4 className="confirm__title">Dirección de entrega</h4>
              <span>
                {this.props.addressReducer.addressDelivery.street}{' '}
                {this.props.addressReducer.addressDelivery.numExt}{' '}
                {this.props.addressReducer.addressDelivery.numInt}
                <br />
                {this.props.addressReducer.addressDelivery.colony}
                <br />
                {this.props.addressReducer.addressDelivery.locality}
                <br />
                {`C.P. ${this.props.addressReducer.addressDelivery.CP}`}
              </span>
            </div>
            <div className="confirm__addresses__item">
              <h4 className="confirm__title">Método de pago</h4>
              <span>
                {`•••• •••• •••• ${this.props.paymentReducer.lastFour}`}
              </span>
            </div>
          </div>
          <div className="confirm__addresses">
            <div className="confirm__addresses__item">
              <h4 className="confirm__title">Fecha de recolección</h4>
              <span>{this.state.recoveryDate}</span>
            </div>
            <div className="confirm__addresses__item">
              <h4 className="confirm__title">Fecha de entrega</h4>
              <span>{this.state.deliveryDate}</span>
            </div>
            <div className="confirm__addresses__item" />
          </div>
          <div className="confirm__addresses">
            <div className="confirm__addresses__item">
              <h4 className="confirm__title">Horario de recolección</h4>
              <span>{this.state.recoveryHour}</span>
            </div>
            <div className="confirm__addresses__item">
              <h4 className="confirm__title">Horario de entrega</h4>
              <span>{this.state.deliveryHour}</span>
            </div>
            <div className="confirm__addresses__item" />
          </div>
          <div className="confirm__products">
            <h2 className="confirm__title">Servicios</h2>
            {this.state.tempData.map(item => {
              return (
                <div key={item.idOrderTemp} className="confirm__products__item">
                  <h4 className="confirm__title">{item.product.nameProduct}</h4>
                  <span className="confirm__title">Sección:</span>{' '}
                  {item.section.nameSection}
                  <br />
                  {item.product.priceWet ? (
                    <>
                      <span className="confirm__title">Tipo de servicio:</span>{' '}
                      {item.service}
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  <span className="confirm__title">Cantidad:</span>{' '}
                  {item.numProd}
                  <br />
                  <span className="confirm__title">Precio:</span>{' '}
                  {item.service === 'Dry'
                    ? `$${item.product.priceDry.toFixed(2)}`
                    : `$${item.product.priceWet.toFixed(2)}`}
                  <br />
                  <span className="confirm__title">Total:</span>{' '}
                  {`$${parseInt(item.total).toFixed(2)}`}
                  <br />
                </div>
              );
            })}
          </div>
        </div>
        <div className="confirm__resume">
          <h3 className="confirm__title">Resumen del pedido</h3>
          <div className="confirm__subtotal">
            <strong>Servicios</strong>
            {` $${this.props.basketReducer.subtotal.toFixed(2)}`}
          </div>{' '}
          <br />
          <div className="confirm__subtotal">
            <strong>Descuentos</strong>
            {this.state.newUser
              ? ` $${(this.props.basketReducer.discount + 100).toFixed(2)}`
              : ` $${this.props.basketReducer.discount.toFixed(2)}`}
          </div>
          <br />
          <br />
          <div className="confirm__total">
            <strong>Total:</strong>
            {this.state.newUser
              ? ` $${(this.props.basketReducer.total - 100).toFixed(2)}`
              : ` $${this.props.basketReducer.total.toFixed(2)}`}{' '}
            <br />
          </div>
          {this.props.basketReducer.subtotal < 200 ? (
            <>
              <p>El monto mínimo para un pedido es de $200.00</p>
            </>
          ) : (
            ''
          )}
          {this.props.basketReducer.content.length &&
          this.props.basketReducer.subtotal >= 200 ? (
            <button className="confirm__button" onClick={this.confirmSubmit}>
              Confirmar pedido
            </button>
          ) : (
            <Link to="/">
              <button className="confirm__button">Regresar</button>
            </Link>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer,
    orderObjReducer: reducers.orderObjReducer,
    paymentReducer: reducers.paymentReducer,
    addressReducer: reducers.addressReducer
  };
};

export default connect(mapStateToProps, orderActions)(Confirm);
