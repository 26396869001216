import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../assets/styles/components/BasketProductList.scss';
import BasketProduct from './BasketProduct';

class BasketProductList extends Component {
  render() {
    return (
      <div className='basketProductList'>
        {this.props.content.length ? (
          this.props.content.map(product => {
            return (
              <BasketProduct
                key={product.keyProduct}
                displayType={product.displayType}
                idProduct={product.idProduct}
                keyProduct={product.keyProduct}
                name={product.name}
                numProd={product.numProd}
                price={product.price}
                section={product.section}
                service={product.service}
                total={product.total}
              />
            );
          })
        ) : (
          <span>Tu cesta está vacía</span>
        )}
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.basketReducer;
};

export default connect(mapStateToProps, null)(BasketProductList);
