import React from 'react';
import '../../assets/styles/components/profile/navbarItem.scss';

const NavbarItem = ({ component: Component, name, active, onClick }) => {
  return (
    <>
      <div className={`navbar-items ${active}`} onClick={onClick}>
        <div className={`menu-item`}>
          <Component />
          <span className='menu-item-name'>{name}</span>
        </div>
      </div>
    </>
  );
};

export default NavbarItem;
