import React from 'react';
import '../assets/styles/components/Terms.scss';

const Terms = () => {
	window.scrollTo(0, 0);
	return (
		<section className="conditions">
			<h2 className="conditions__title">Términos y condiciones</h2>
			<article className="conditions__text">
				<p>
					Estos son los términos y condiciones de uso de la tecnología
					desarrollada por SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
					y protegida por la Ley Federal del Derecho de Autor. Cuando Usted
					descarga y usa la aplicación tecnológica de TIN2GO o usa la página web
					en lo subsiguiente la funcionalidad de “TIN2GO” y/o cualquier servicio
					o componente tecnológico relacionado a los servicios y funcionalidad
					de TIN2GO, usted está aceptando todos los términos de uso y
					condiciones que se definen en el presente documento.
				</p>
				<p>
					Los presentes términos y condiciones de uso constituyen un acuerdo
					legal entre Usted y SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE
					C.V.
				</p>
				<p>
					Usted como usuario final de los servicios que se prestan a través de
					la tecnología desarrollada por SERVICIOS DE TINTORERIA Y LOGISTICA
					S.A.P.I. DE C.V. usted se compromete a respetar y acatar los mismos.
					Si Usted NO está de acuerdo con TODOS los términos y condiciones de
					servicio que se describen a continuación, no acceda a la aplicación
					TIN2GO ni use ningún servicio o tecnología proveído por TIN2GO.
				</p>
				<strong>I. USO CONTINUADO:</strong>
				<p>
					Usted debe leer cuidadosamente este acuerdo, ya que mediante la
					indicación de aceptación de este acuerdo o mediante el uso de TIN2GO,
					usted está entrando en un acuerdo legalmente vinculante con SERVICIOS
					DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. como un usuario final. Si
					usted no está de acuerdo con estos términos y condiciones, no podrá
					utilizar la funcionalidad o los productos y servicios de TIN2GO,
					tecnología protegida por La Ley Federal del Derecho de Autor.
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. tiene la
					exclusividad para la explotación de los derechos patrimoniales de la
					página web y la aplicación TIN2GO.
				</p>
				<strong>II. DEFINICIONES:</strong>
				<p>
					TIN2GO: página web (www.TIN2GO.com) y/o aplicación mediante la cual se
					lleva a cabo la prestación de servicios consistentes en tintorería,
					lavandería, y/o sastrería, planchado, Lavado de Alfombras, lavado de
					salas, Lavado de vestiduras, alto vacío, servicios que se describen en
					el presente documento y la página web de TIN2GO. TERMINOS Y
					CONDICIONES: TIN2GO despliega sus términos y condiciones en la
					siguiente liga electrónica www.TIN2GO.com/terminos.html
				</p>
				<p>
					APLICACIÓN TECNOLOGICA TIN2GO: Aplicación tecnológica móvil
					desarrollada por SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
					y protegida por derechos de autor, que se comercializará en Appstore
					(Apple) y Play Store (Google).
				</p>
				<p>
					INSTRUCCIONES DE LAVADO: TIN2GO seguirá las instrucciones de lavado
					y/o secado que se encuentren adheridas a la prenda o de no ser así las
					que nos indique usted como usuario final. No asumimos responsabilidad
					por ropa desgastada, artículos que se desvanecen o se encogen debido
					al lavado y secado normal, o por artículos que nos han indicado que
					requieren un cuidado especial. Proporcionaremos un tratamiento
					especial para la ropa si es indicada por los usuarios finales y
					asumiremos la responsabilidad por los daños causados por no seguir sus
					instrucciones. Las instrucciones de cuidado especial deben
					especificarse en nuestra página web o aplicación tecnológica al hacer
					la orden o escrito en una nota adherida a su ropa. En caso de que se
					presente deterioro, desgaste o daño irreversible en las prendas con
					motivo de los servicios que se prestan, y el usted no haya dado a
					conocer instrucciones especiales para su tratamiento, Tin2go no se
					hará responsable por dichos daños o deterioros, por lo que la
					responsabilidad será completamente a cargo de Usted.
				</p>
				<p>
					CUIDADO ESPECIAL: Se define, para efectos del presente documento como
					limpieza en seco, secado tendido, y lavadora en ciclo suave.
				</p>
				<p>
					ORDEN DE SERVICIO: Documento que contiene la siguiente información: la
					fecha de entrega; cualquier deterioro que tenga la prenda, incluyendo
					adornos y aplicaciones; cualquier riesgo que corra la prenda por el
					proceso de lavado, secado o planchado; las manchas o decoloraciones
					visibles.
				</p>
				<p>
					PRENDAS ESPECIALES: Se definen como prendas importadas o de alta
					costura, su valor estimado, características que guarda, servicio
					solicitado, precio del servicio, límite de garantía, fecha de entrega,
					especificaciones de almacenamiento y riesgo que corren por maniobras.
					Estos datos deben ser incluidos en la orden de servicio de TIN2GO.
					TIN2GO no se hará responsable por daños o deterioro en prendas que
					tengan un valor superior a los $10,000.00 pesos (DIEZ MIL PESOS 00/100
					M.N.), por lo que cualquier daño o deterioro que se presente en una
					prenda de valor superior al referido será responsabilidad de usted.
				</p>
				<p>
					CORTINAS Y PRENDAS CON EXPOSICIÓN SOLAR: Todo tipo de cortinas y
					prendas que por su naturaleza sean expuestas al sol o a la intemperie
					no serán objeto de garantía.
				</p>
				<p>TENIS: Todo tipo de tenis no será objeto de garantía.</p>
				<p>
					DAÑOS A LAS PRENDAS: Cualquier prenda de vestir que haya sufrido un
					daño o perdida debe de notificarse dentro de los próximos 2 (dos) días
					hábiles de la fecha de entrega definida en la orden de servicio, para
					considerar y definir una compensación. Dado el volumen de la ropa que
					manejamos, los tiempos de notificación tardía hacen más difícil
					verificar la reclamación. O envía un email a:
					servicioalcliente@tin2go.com
				</p>
				<p>
					LUGAR DE RECOLECCIÓN Y ENTREGA: Usted es el único responsable de
					proveer un lugar seguro en donde TIN2GO va a recoger y dejar las
					prendas a las que se les prestarán los servicios de tintorería,
					lavado, secado, doblado y planchado Las zonas en donde se podrán
					prestar los servicios de TIN2GO serán definidas por SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. y dadas a conocer al público
					mediante la página web www.tin2go.com
				</p>
				<strong>III. CONTENIDO DE USUARIO:</strong>
				<p>
					En relación con el uso de la página web de TIN2GO o mediante la
					instalación, actualización y uso de la aplicación tecnológica de
					TIN2GO, es posible cargar, publicar, imágenes, texto, audio y video en
					formatos electrónicos que se exponen en la red mediante TIN2GO, en lo
					sucesivo "Contenido del Usuario”. El Contenido del usuario es de
					propiedad del usuario final, TIN2GO no reclama la propiedad de los
					derechos de autor u otros derechos de propiedad sobre dicha
					información y contenido de usuario. No obstante lo anterior, el
					usuario final ACEPTA SIN LIMITANTES TODOS LOS LINEAMIENTOS QUE SE
					DEFINEN A CONTINUACION:
				</p>
				<p>
					1. Usted puede utilizar los Servicios TIN2GO únicamente de conformidad
					con LOS TERMINOS Y CONDICIONES DE TIN2GO. Usted no tiene facultad para
					utilizar los Servicios y funcionalidad de TIN2GO en cualquier forma
					que pueda sobrecargar, alterar, dañar, desactivar o perjudicar la
					página web, los servicios o a los usuarios finales de TIN2GO, o
					utilizar TIN2GO en cualquier forma que pueda conducir a la
					transferencia, la distribución o la carga de software o material que
					contenga código malicioso, como virus, bombas de tiempo, gusanos,
					troyanos, software de espionaje u otro software potencialmente dañino,
					material o información.
				</p>
				<p>
					2. Usted se compromete a no utilizar los Servicios TIN2GO con el
					propósito de cargar, publicar, enviar correo electrónico o cualquier
					otra forma de procesar o reenviar contenido que infrinja leyes
					aplicables (incluyendo pero no limitando a información que pueda
					considerarse como constituir incitación al odio racial, pornografía
					infantil, calumnia, insulto, instigación de la rebelión, descripción
					de violencia, y / o violación de los derechos de autor o cualquier
					derecho de propiedad intelectual o violaciones de datos personales) o
					que puede en cualquier forma ser concebido como amenazante,
					insultante, racista, ofensivo, vulgar y / o indecente o que conlleve a
					infringir el círculo personal de un particular o a infringir marca
					registrada de terceros o los derechos de autor u otros derechos de
					propiedad intelectual.{' '}
				</p>
				<p>
					3. Usted consiente a SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE
					C.V. al procesamiento y almacenamiento de los datos personales y el
					Contenido del Usuario, y para revelar Datos Personales o Contenido si
					TIN2GO es obligado a hacerlo por ley o si SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V. a su discreción considera necesario (a)
					cumplir con una obligación legal; (b) ratificar el Convenio con el
					Usuario; (c) responder a declaraciones que establezcan que el
					Contenido y / o Datos Personales constituyen una infracción del
					derecho de un tercero o está en contra de la ley de cualquier otra
					manera; o (d) proteger los intereses, derechos, propiedad o seguridad
					personal de SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. sus
					usuarios o el público en general.
				</p>
				<strong>
					IV. LIMITACIÓN DE RESPONSABILIDAD DEL CONTENIDO DE USUARIO:
				</strong>
				<p>
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. almacena y
					distribuye contenido de usuario y no lo controla por ningún medio.
					Todas las opiniones, declaraciones, servicios, ofertas u otros
					contenidos incluidos en Contenido de Usuario son de los respectivos
					usuarios, autores o distribuidores y SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V. no es responsable de la exactitud o
					confiabilidad del Contenido de Usuario. SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V. no tiene ninguna obligación de modificar o
					controlar el contenido de usuario que usted y otros usuarios publican
					o distribuyen, y no será responsable en modo alguno del Contenido de
					Usuario. Usted como usuario final accede y utiliza el contenido de
					usuario bajo su propio riesgo, SERVICIOS DE TINTORERIA Y LOGISTICA
					S.A.P.I. DE C.V. no será responsable bajo ninguna circunstancia por
					cualquier pérdida o daño que cualquier persona pueda sufrir como
					resultado de usar la página web o la tecnología de SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
				</p>
				<strong>V. LIMITACIÓN DE RESPONSABILIDAD DE LOS SERVICIOS:</strong>
				<p>
					TIN2GO Y/O SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. no es
					responsable de cualquier reacción adversa como resultado de los
					detergentes que se utilizan para prestar LOS SERVICIOS que se definen
					en el presente documento. TIN2GO Y/O SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V. no está obligado a eliminar las manchas que
					por su naturaleza sean insolubles. Así mismo no será responsable por
					las manchas no perceptibles a la vista al momento de la recepción de
					la (s) prenda (s) y que surgieran durante su proceso de lavado y/o
					planchado, tales como los residuos de perfumes, ácidos, desodorantes o
					exudación. TIN2GO no es responsable por los daños o pérdida causados a
					objetos en los bolsillos de sus prendas (ejemplos: plumas, accesorios,
					aparatos electrónicos, labiales, llaves). Mientras hacemos nuestro
					mejor esfuerzo para revisar sus bolsillos, no podemos garantizar que
					vamos a encontrar todos los objetos. Mientras nos esforzamos por
					entregar todos los pedidos a tiempo, por circunstancias ajenas a
					nuestra voluntad pueden resultar en una orden que se entregue tarde.
					Por lo tanto, no podemos garantizar entregas puntuales. Nos pondremos
					en contacto contigo lo más rápido que podamos cuando se de este
					situación y existe cualquier retraso en tu pedido.
				</p>
				<strong>VI. REVISIONES DE CONTENIDO:</strong>
				<p>
					TIN2GO no hace revisiones al Contenido subido por los usuarios y no es
					responsable del Contenido. Cualquier revisión de Contenido realizado
					por TIN2GO no limita la responsabilidad del Usuario por el Contenido.
				</p>
				<strong>VII. RESTRICCIONES DE CONTENIDO:</strong>
				<p>
					Usted deberá cumplir con TODAS las reglas que se definen y enumeran a
					continuación como condicionante para usar TIN2GO. Usted, como usuario
					final no podrá:
				</p>
				<p>
					(a) Utilizar, cargar, publicar, distribuir o transmitir ningún
					Contenido de Usuario en violación de cualquier ley local, estatal,
					federal o normas y tratados internacionales.
				</p>
				<p>
					(b) Con excepción de lo permitido por este acuerdo, no se podrá
					recolectar contenido de terceras personas, que incluye, direcciones,
					correo electrónico, sin consentimiento de las mismas.
				</p>
				<p>
					(c) Participar en cualquier otra conducta que interfiere con los
					servicios de TIN2GO, o que a juicio exclusivo de SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. se exponga a cualquiera de sus
					funcionarios, directores, empleados o agentes a cualquier
					responsabilidad o perjuicio de cualquier tipo por el contenido de
					usuario.
				</p>
				<p>
					Usted no podrá utilizar TIN2GO para enviar, publicar, cargar,
					distribuir Contenido de Usuario que:
				</p>
				<p>
					(a) Sea difamatorio, vulgar, amenazador, violento, abusivo, obsceno,
					pornográfico o ilegal o que aliente algún delito de cualquier
					ordenamiento, civil, penal, mercantil, laboral.
				</p>
				<p>
					(b) Contenga material de obras protegidas sin el consentimiento por
					escrito del propietario de dicho material protegido por derechos de
					autor, o de la persona que tenga los derechos patrimoniales de la obra
					protegida por derechos de autor, en caso de que esto ocurriera usted
					como el usuario final mantendrá a salvo a SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V. y sus socios, funcionarios, empleados y/o
					representantes de cualquier pago por concepto de daños, perjuicios,
					regalías, multas o impuestos que pudieran ser aplicables.
				</p>
				<p>
					(c) Infrinja cualquier derecho de privacidad o publicidad o cualquier
					otro derecho de terceros;
				</p>
				<p>
					(d) Contenga cualquier declaración, dirección, instrucción que
					implique un riesgo razonablemente previsible de lesión o daño a los
					lectores del material o de terceros;
				</p>
				<p>
					(e) Promover cualquier producto o servicio de cualquier competidor de
					TIN2GO Y/O SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. y/ o
					alentar a cualquier otro usuario de la aplicación TIN2GO para
					convertirse en usuario o usuarios de dichos competidores.
				</p>
				<p>
					(f) Contenga virus de software, malware o cualquier otro código,
					archivo o programa diseñado para interrumpir, destruir o limitar la
					funcionalidad de cualquier software, hardware o equipo de
					telecomunicaciones de TIN2GO Y/O SERVICIOS DE TINTORERIA Y LOGISTICA
					S.A.P.I. DE C.V.
				</p>
				<strong>VIII. REGISTRO (SOLO PARA MAYORES DE 18 AÑOS):</strong>
				<p>
					Tras el registro de una cuenta de TIN2GO, el Usuario debe ingresar los
					datos personales solicitados en el formulario de inscripción que
					incluye el nombre completo, nombre del perfil TIN2GO, dirección postal
					y dirección de correo electrónico ("Datos de Registro") y mantener a
					TIN2GO informado de cualquier cambio en dichos Datos de Registro
					durante la vigencia del Convenio con Usted. Si es una persona moral,
					el Usuario deberá presentar también la información acerca de la
					persona (s) de contacto que tendrán acceso a TIN2GO en nombre de la
					persona moral. Si el Usuario envía inexactos, incompletos o no
					actualizados los Datos de Registro, TIN2GO tiene derecho a rescindir
					el Convenio con el Usuario, con efecto inmediato y al mismo tiempo
					cancelar o eliminar el acceso que el Usuario tiene a TIN2GO, además de
					que no tendrá obligación de prestar los servicios ofrecidos en la
					aplicación o página web. En relación con el registro, cada Usuario
					debe ingresar información de pago (datos de la tarjeta de crédito
					("Datos de Pago”). Las formas de pago utilizadas y validadas por
					TIN2GO están listadas en la plataforma TIN2GO. Usted debe asegurarse
					de que la cuenta y contraseña que registre para el uso de TIN2GO estén
					almacenadas de una manera satisfactoria y protegidas de ser utilizadas
					por terceros. Usted es responsable de cualquier uso no autorizado de
					la cuenta y la contraseña de Usuario. TIN2GO NO recopila y procesa los
					Datos de Pago, esto lo hace el proveedor de estos servicios de pago de
					TIN2GO.
				</p>
				<p>
					Si Usted se registra en TIN2GO en calidad de usuario final mayor de 18
					años debe crear una cuenta y registrarse con nosotros. Si Usted se
					registra como usuario final, usted garantiza que:
				</p>
				<p>
					(a) Va a proporcionar a TIN2GO con información de registro precisa,
					verídica, actual y completa.
				</p>
				<p>
					(b) Usted es responsable de su propio registro y de la información
					contenida en TIN2GO, por medio de este acuerdo acepta las condiciones
					del Aviso de Privacidad de TIN2GO y acepta todos términos y
					condiciones de descarga y uso de TIN2GO, mismas que se definen en el
					presente documento de términos y condiciones.
				</p>
				<p>
					(c) Además usted acepta no compartir contraseñas con cualquier otra
					persona o usar la cuenta de otro usuario, esto no está permitido bajo
					ninguna circunstancia.
				</p>
				<p>
					(d) Usted será enteramente responsable por el uso y la seguridad su
					cuenta en TIN2GO.
				</p>
				<p>
					(e) TIN2GO Y/O SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. no
					será responsable en ningún caso de la seguridad e integridad de su
					cuenta en caso de que haya un uso no autorizado de la misma.
				</p>
				<p>
					(f) Es un usuario mayor de 18 años cumplidos al día de su registro en
					la Plataforma.
				</p>
				<strong>IX. TITULARIDAD DE LOS DERECHOS DE TIN2GO:</strong>
				<p>
					La tecnología, servicios, funcionalidad e información de TIN2GO es
					propiedad de SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. Sin
					perjuicio de lo anterior , todos los textos, imágenes, sonido,
					compilaciones (es decir, la recopilación, clasificación e indexación
					de la información) y el resto de contenidos incluidos en los
					productos, los servicios, distintos del contenido de usuario como se
					define a continuación (en lo sucesivo el "contenido"), todo el
					software incorporado en los productos y servicios y utilizado por
					TIN2GO para prestar los servicios es propiedad de SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. y está protegido por derechos
					de autor y otros derechos de propiedad intelectual. A excepción de lo
					expresamente permitido en el presente Acuerdo, cualquier uso, copia,
					ingeniería inversa, trabajos derivados, transmisión, publicación,
					vinculación, redistribución, comercialización, modificación,
					traducción o el desmontaje del software y de los productos y servicios
					protegidos por las Leyes de Derechos de Autor ESTA PROHIBIDO y usted
					puede estar sujeto a sanciones civiles o penales por violaciones
					definidas en este párrafo.
				</p>
				<strong>X. MARCA REGISTRADA:</strong>
				<p>
					Así mismo TIN2GO es una marca registrada y no puede ser utilizada en
					conexión con ningún producto o servicio que no sea suministrado por
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. La marca TIN2GO
					no puede ser utilizada de ninguna manera en que pueda causar confusión
					entre los usuarios, o de ninguna manera que se menosprecie o se
					desacredite a la marca TIN2GO y/ SERVICIOS DE TINTORERIA Y LOGISTICA
					S.A.P.I. DE C.V., en caso de que personas físicas o morales causen
					descredito, confusión y menosprecio se harán acreedoras al pago de
					daños y perjuicios por la afectación en la imagen de las marcas
					registradas y definidas en el presente párrafo.
				</p>
				<strong>
					XI. OTRAS MARCAS COMERCIALES QUE ESTAN DESPLEGADAS EN TIN2GO:
				</strong>
				<p>
					TIN2GO puede desplegar en su página web, o en la aplicación
					tecnológica TIN2GO marcas comerciales, marcas de servicio, marcas de
					proveedores, logotipos de TIN2GO o de terceros, y cada propietario
					conserva todos los derechos sobre dichas marcas. Cualquier uso de
					dichas marcas, o cualesquiera que aparecen en la aplicación
					tecnológica TIN2GO, redundarán en beneficio exclusivo de sus
					respectivos dueños.
				</p>
				<strong>XII. LICENCIA DE USO AL USUARIO PARA UTILIZAR TIN2GO:</strong>
				<p>
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. le otorga a usted
					una licencia no exclusiva, por tiempo indefinido, limitada a un
					usuario final por licencia de TIN2GO con el fin de descargar e
					instalar una copia autorizada de la licencia de TIN2GO a nivel
					mundial, para usar, reproducir, distribuir y exhibir contenido de
					TIN2GO y las diferentes funcionalidades de TIN2GO, incluyendo sin
					limitación la promoción o redistribución en cualquier formato y a
					través de cualquier canal de comunicación a través de su uso en el
					dispositivo móvil o equipo de cómputo propiedad del usuario final,
					equipo de cómputo y dispositivo móvil que el usuario final debe además
					de poseer y controlar. El usuario final ejecuta la aplicación TIN2GO
					en cada dispositivo móvil o equipo de cómputo únicamente para su
					servicio personal. Esta licencia es otorgada al usuario final de
					TIN2GO para acceder, instalar y actualizar TIN2GO y para poder acceder
					a los productos y servicios desplegados en TIN2GO y utilizar la
					funcionalidad de TIN2GO, de acuerdo a los presentes términos y
					condiciones y de acuerdo a las especificaciones de software y hardware
					que defina SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
				</p>
				<p>
					Por medio de la aceptación total de los términos y condiciones
					definidos en el presente documento, SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V. le autoriza a usted como usuario final a
					acceder, instalar y actualizar TIN2GO para su uso personal de manera
					gratuita.
				</p>
				<strong>XIII. ESQUEMA DE NEGOCIO:</strong>
				<p>
					TIN2GO a través de su plataforma tecnológica provee a usted como
					Usuario Final los servicios de tintorería, lavado, secado, sastrería y
					planchado a domicilio por medio de aplicaciones y funcionalidad de
					herramientas tecnológicas propiedad de TIN2GO y/o SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
				</p>
				<strong>XIV. ALTA Y REGISTRO DE TARJETA DE CREDITO:</strong>
				<p>
					Para poder usar la funcionalidad y los productos y servicios de TIN2GO
					es necesario que usted se registre en la página web de TIN2GO o que el
					usted se registre en la aplicación tecnológica de TIN2GO y proporcione
					los datos exactos de registro de su tarjeta de crédito o débito de una
					manera satisfactoria en TIN2GO. Una vez registrado usted y su
					respectiva tarjeta de crédito y/o débito el usuario puede acceder a
					los servicios de tintorería, lavado, secado, sastrería y planchado a
					domicilio, sujeto a las restricciones contenidas en los presentes
					términos y condiciones.
				</p>
				<strong>XV. ENVIOS</strong>
				<p>
					El servicio de entrega será en un horario de lunes a sábado de las
					08:00 am y las 10:00 pm.
				</p>
				<strong>XVI. POLITICAS DE DEVOLUCIONES Y CANCELACIONES</strong>
				<p>
					Se podrá solicitar cancelación del servicio de recolección de prendas
					en cualquier momento en nuestra App o página web, siempre y cuando no
					se haya realizado aún la recolección de las mismas por parte de
					nuestros agentes. Para ello deberá ir a su Perfil, buscar “cancelar
					pedido” y realizar el proceso solicitado
				</p>
				<p>
					Se podrá cancelar el proceso de tratamiento de sus prendas cuando a
					discreción de Servicios de Tintoreria y Logística SAPI de CV, una o
					varias de las prendas no puedan ser procesadas por: estado de cuidado
					de las prendas, características particulares de las prendas, o fallas
					en el suministro eléctrico o cualquier otro que impidan, total o
					parcialmente, brindar un servicio adecuado al cliente, en cuyo caso,
					el cliente podrá: solicitar la devolución de las prendas a su
					domicilio sin costo alguno y la devolución, si hubiera sido cargado,
					del monto pagado a Tin2Go, o bien, autorizar a Tin2Go una fecha de
					re-proceso y entrega de sus prendas posterior a la pactada
					inicialmente.
				</p>
				<p>
					Todo situación que se presente por re-proceso de sus prendas, cuando
					el cliente solicita que una de sus prendas vuelva a ser tratadas,
					deberá ser notificado por correo electrónico a
					servicioalcliente@tin2go.com enviando descripción del problema o razón
					de la solicitud de re- proceso, junto con los datos de su servicio,
					como son; No. de servicio, nombre del cliente y domicilio registrado.
					El cliente deberá de revisar sus prendas al momento de la entrega por
					parte, para hacer valida dicha garantía de re-proceso.
				</p>
				<p>
					Los gastos de envío por devolución de sus prendas no tendrán costo
					para el cliente, pero acepta que la misma devolución se programara de
					acuerdo a la carga de trabajo de los agentes disponibles.
				</p>
				<strong>XVII. POLITICAS DE GARANTIA</strong>
				<p>
					Los servicios de Tin2Go están garantizados contra defectos en el
					proceso de limpieza y planchado. El uso, desgaste y cualquier pérdida
					en consecuencia del re-proceso no está cubierto por la garantía.Los
					servicios que ofrece Tin2Go podrán ser re-procesados sin costo para el
					cliente, siempre y cuando su solicitud se haga el mismo día de la
					recepción de los servicios. La garantía no aplica cuando las prendas
					sean usadas entre la entrega de su servicio y la solicitud de
					re-proceso.
				</p>
				<strong>XVIII. DESCUENTOS Y/O PROMOCIONES</strong>
				<p>
					Los descuentos y/o promociones definidas por TIN2GO tendrán fechas de
					caducidad y políticas especiales que podrán cambiar en cualquier
					momento. Cualquier promoción que se publique externamente en redes
					sociales o campaña de mailing o cualquier otro esquema, es bajo la
					regla de que el pedido mínimo de la siguiente forma: Lavandería, es de
					$200, Tintoreria, mínimo de 3 prendas ó $ 200; Costura, mínimo de 3
					prendas ó $ 200, aun cuando no se especifique dentro de la misma.
				</p>
				<strong>XIX. RESPONSABILIDADES TIN2GO</strong>
				<p>
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.se obliga mediante
					este documento a revisar el estado que guardan las prendas, haciendo
					ver a usted como Usuario Final cualquier deterioro o riesgo en el
					proceso de limpieza, fijando el límite de garantía o excusarse de
					hacer el servicio. SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE
					C.V.se obliga a reparar las prendas en caso de deterioro parcial
					imputable a él mismo, dentro de los treinta días siguientes a la
					presentación de la inconformidad, sin costo alguno para el cliente,
					aun cuando se haya subcontratado el servicio con terceros y devolver
					el importe del servicio no realizado cuando previamente se haya
					cobrado. SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
					indemnizará en caso de deterioro total o pérdida, salvo causas de
					fuerza mayor, a su propietario hasta con un máximo de 10 (diez) veces
					el precio del servicio pactado por dicha prenda, o un máximo del 60%
					(sesenta por ciento) del valor de la prenda. TIN2GO Y/O SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. no responderá por daños a
					prendas cuyo valor rebase los $8,000.00 pesos (OCHOMIL PESOS 00/100
					M.N.) por lo que los daños que pudieran resultar de los servicios que
					se soliciten para dichas prendas serán responsabilidad de usted como
					usuario final. TIN2GO corregirá sin cargo extra, las deficiencias en
					el servicio que el Usuario Final encuentre en el momento de entregar o
					recibir las prendas. La garantía se limita al cumplimiento del
					servicio solicitado de acuerdo a lo establecido en nuestro Contrato de
					Adhesión registrado ante PROFECO, por lo que le solicitamos consultar
					nuestro apartado de Contrato de Adhesión.
				</p>
				<strong>XX. RESPONSABILIDAD DE LOS USUARIOS FINALES.</strong>
				<p>
					Usted como Usuario Final es responsables de seguir y cumplir todos los
					lineamientos y definiciones del presente documento y del aviso de
					privacidad de TIN2GO y de separar claramente y etiquetar los artículos
					que serán lavados y secados o lavados delicadamente. TIN2GO no es
					responsable por artículos lavados incorrectamente por no estar
					marcados claramente.
				</p>
				<strong>XXI. POLITICAS DE PAGO</strong>
				<p>
					Se debe de realizar el pago con tarjeta de crédito o débito al momento
					de hacer el pedido, por el precio que fue indicado en la orden de
					servicio y/o en el precio que fue determinado en la aplicación
					tecnológica de TIN2GO o en la página web o al momento del ajuste de la
					nota (esto derivado de alguna prenda enviada diferente a lo elegido).
					Una vez que las prendas recolectadas para servicio lleguen a la planta
					donde serán tratadas, se determinara que efectivamente las prendas
					correspondan en sus características como pieza, tamaño y fibra, al
					precio pagado; en caso de no ser así, le será notificado al cliente,
					pudiendo ser de manera electrónica o telefónica, la diferencia de
					precio por pagar, misma que podrá aceptar o declinar, en cuyo caso, le
					serán devueltas la o las prendas que presenten diferencia entre lo
					solicitado vía la app de Tin2Go y la prenda recibida para su proceso.
				</p>
				<strong>XXII. POLITICAS DE FACTURACION</strong>
				<p>
					El cliente podrá al momento de registrarse, dar de alta sus datos
					fiscales para solicitar facturas en cada uno de los pedidos que
					realice o en su defecto, podrá agregar o modificar dichos datos en
					otro momento posterior al registro inicial, para lo cual deberá de ir
					a la opción “Perfil”, luego “Facturación”, luego “modificar o ingresar
					datos”.
				</p>
				<p>
					Solo serán facturables aquellos pedidos posteriores al registro de los
					datos de facturación, no pudiéndose facturar pedidos anteriores al
					registro de dichos datos en la app o web.
				</p>
				<p>
					La factura de cada servicio, será emitida de manera automática una vez
					que se realice el cargo al método de pago seleccionado. El cliente
					recibirá su factura por correo electrónico y tendrá 48 horas para
					revisar e indicarnos, si es el caso, de algún error en su factura,
					entendiendo que el cliente es directamente quien registra sus datos.
					Deberá enviar correo a servicioalcliente@tin2go.com con los siguientes
					datos: nombre y no. De factura.
				</p>
				<p>
					Por favor considere que no se realizaran cancelaciones ni
					modificaciones de facturas después del último día del mes en que se
					realizó del servicio.
				</p>
				<strong>XXIII. DISPONIBILIDAD Y PRECIOS</strong>
				<p>
					Los precios definidos por TIN2GO pueden cambiar eventualmente, es
					importante que todos los Usuarios Finales revisen los precios
					definidos en la aplicación tecnológica TIN2GO y desplegados en la
					página web www.TIN2GO.com. Los precios publicados en TIN2GO son
					exclusivos y no aplican en otros lugares fuera de la zona de
					recolección y entrega definida por TIN2GO a través de su página web y
					aplicación tecnológica.
				</p>
				<strong>XXIV. IMPUESTOS APLICABLES</strong>
				<p>
					Todos los precios incluyen un Impuesto al Valor Agregado del 16%
					(dieciséis por ciento) de acuerdo a la legislación de los Estados
					Unidos Mexicanos.
				</p>
				<strong>XXV. OBJETO DE LOS SERVICIOS</strong>
				<p>
					El objeto de este contrato es la prestación de los servicios de
					tintorería, lavado, sastrería, secado y planchado de las prendas
					definidas por usted, así como de manera presente o futura, otros
					servicios como Lavado de Alfombras, lavado de vestiduras, lavado de
					salas, empacado al alto vacío, enunciados de manera enunciativa mas no
					limitativa, mediante las aplicaciones tecnológicas de TIN2GO por parte
					de los establecimientos asociados de TIN2GO. La prestación de los
					servicios de tintorería, lavado, sastrería secado y planchado siempre
					se hará de acuerdo a las especificaciones definidas en el presente
					documento de términos y condiciones.
				</p>
				<strong>XXVI. ALCANCE DEL ACUERDO CON EL USUARIO FINAL:</strong>
				<p>
					TIN2GO se compromete, de acuerdo con el Convenio con usted, a dar
					acceso a usted a los servidores proporcionados de TIN2GO, a través del
					uso de la aplicación TIN2GO a través de un navegador web. Usted está
					consciente y acepta que el uso de los Servicios TIN2GO requiere que el
					usted tenga acceso a una conexión de Internet móvil o fijo. Usted
					deberá proveer por sí mismo, pagar y mantener dicha conexión a
					internet y todo el equipo que se requiere para acceder y utilizar los
					Servicios. El Usuario está consciente que TIN2GO puede ser cerrado
					inmediatamente si SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
					lo considera necesario por seguridad o por otras razones. TIN2GO se
					reserva el derecho de modificar, añadir o eliminar funciones en los
					servicios y cambiar de forma unilateral, realizar adiciones, y
					modificar los términos de este Convenio con el Usuario en cualquier
					momento. TIN2GO te notificará, a través del sitio web de TIN2GO por
					correo electrónico a una dirección de correo electrónico proporcionada
					por usted, de las enmiendas de los términos del Convenio con el
					Usuario y / o cambios de los Servicios TIN2GO que TIN2GO realice. En
					caso de que usted no acepte dicha modificación de los Servicios TIN2GO
					o de los términos del Convenio con usted, podrá, dentro de los treinta
					(30) días a partir de la recepción de la notificación de los cambios
					de TIN2GO, dar por terminado el Convenio con usted, con efecto
					inmediato. Un usuario que no ha dado por terminado el Convenio, a más
					tardar treinta (30) días desde la fecha en que TIN2GO notificó de los
					cambios, se considerará que ha aceptado dichos cambios. TIN2GO no será
					responsable por cualquier interrupción o distorsión del Contenido que
					ocurra durante la transferencia a través de Internet. TIN2GO no
					protege contra virus u otro código dañino y usted está consciente que
					toda apertura de archivos disponibles en TIN2GO se hace a cuenta y
					riesgo de usted. TIN2GO no da ninguna garantía en cuanto a la
					disponibilidad de Mercado TIN2GO y no garantiza que TIN2GO está libre
					de defectos.
				</p>
				<strong>
					XXVII. DECLARACIONES Y GARANTÍAS RELACIONADAS CON DERECHOS DE AUTOR:
				</strong>
				<p>
					Todos los artículos, productos y servicios que se exhiben en TIN2GO,
					nombres comerciales, certificaciones, slogans, textos, logos, audio,
					video, software pertenecen a sus respectivos titulares o dueños y
					usted no podrá reproducir, copiar, publicarlos en ningún otro lado a
					reserva de una autorización por escrito de sus respectivos dueños o
					titulares.
				</p>
				<strong>XXVIII. SOPORTE A USUARIO</strong>
				<p>
					El soporte Usuarios de la aplicación de TIN2GO se proporciona a través
					de guías, preguntas frecuentes y otros documentos de apoyo disponibles
					en el sitio web de TIN2GO, www.TIN2GO.com TIN2GO no garantiza la
					disponibilidad de algún soporte a Usuarios para alguna pregunta
					específica o cuestiones de Soporte.
				</p>
				<strong>XXIX. DURACIÓN Y TERMINACIÓN</strong>
				<p>
					Este Convenio es por tiempo indeterminado con usted y entra en vigor
					el día del registro del usted como usuario en TIN2GO o de la descarga
					y la activación de la aplicación de TIN2GO y permanecerá vigente hasta
					su terminación. La terminación puede ser hecha por cualquiera de las
					partes por causa o por conveniencia en cualquier momento. La
					terminación por usted se realiza a través de la desactivación de la
					cuenta de la cual usted es Usuario en TIN2GO y es efectiva
					inmediatamente. La Terminación por TIN2GO se realiza a través de
					notificación a usted como Usuario de TIN2GO a través de la cuenta que
					usted proporciono en TIN2GO o a través de la aplicación de TIN2GO o
					por correo electrónico a la dirección de correo electrónico
					proporcionada por usted en los Datos de Registro, con notificación de
					al menos treinta (30) días de anticipación. Al momento de terminación
					del Convenio con el usted como Usuario, usted deberá cesar el uso de
					la aplicación TIN2GO y desinstalarla de todos y cada una de los
					dispositivos donde el usted como Usuario de TIN2GO la haya instalado.
					TIN2GO se reserva el derecho de terminar inmediatamente el Convenio
					con usted y/o cerrar, total o parcialmente, el acceso de usted como
					Usuario Final.
				</p>
				<strong>XXX. CESIÓN DE DERECHOS.</strong>
				<p>
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.se reserva el
					derecho de ceder los derechos de estos términos y condiciones a
					cualquier otra entidad que compre, se anexe, ó se agrupe con SERVICIOS
					DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
				</p>
				<strong>
					XXXI. CAMBIOS Y MODIFICACIONES DE LOS TERMINOS Y CONDICIONES DE USO DE
					TIN2GO:
				</strong>
				<p>
					Este Convenio con usted comprende el acuerdo completo entre las partes
					con respecto a la utilización de usted de los Servicios TIN2GO.
					Excepto por el derecho de TIN2GO de cambiar, hacer adiciones y de
					otras maneras modificar los términos y condiciones de uso en cualquier
					momento. Usted no está facultado a transferir sus derechos u
					obligaciones conforme a este Convenio con otro Usuario sin el
					consentimiento por escrito de SERVICIOS DE TINTORERIA Y LOGISTICA
					S.A.P.I. DE C.V. SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE
					C.V.está facultado a contratar a subcontratistas para llevar a cabo
					sus obligaciones de conformidad con este Convenio con usted. SERVICIOS
					DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V., es responsable de sus
					subcontratistas.
				</p>
				<strong>XXXII. MARCAS DESPLEGADAS EN TIN2GO:</strong>
				<p>
					Usted también se obliga a no utilizar las marcas registradas de
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. o signos
					distintivos de cualquier forma diferente a la que TIN2GO haya dado su
					consentimiento explícito. Usted como usuario final no puede eliminar
					ninguna marca, patente, copyright, marca comercial u otros avisos de
					propiedad que se han colocado en TIN2GO, modificar, copiar, pegar,
					redistribuir, publicar, cargar, transmitir, esta propiedad intelectual
					está estrictamente prohibido sin el permiso previo por escrito de
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. Usted garantiza y
					acepta que el uso de los Servicios será consistente con este Acuerdo y
					que no infringirá o violara ningún derecho de ningún tercero. Usted
					acepta que cumplirá con todas las leyes, reglamentos, ordenamientos,
					lineamientos relativos a aeronáutica civil, respecto a la privacidad
					de las personas y a las condiciones que se definen en este documento.
				</p>
				<strong>XXXIII. IDIOMA. </strong>
				<p>
					La versión en castellano (Español) es la única versión que se acepta
					para efectos de los términos y condiciones de uso de TIN2GO.
				</p>
				<strong>XXXIV. PERMISOS EN MOTORES DE BUSQUEDA:</strong>
				<p>
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. podrá conceder
					permiso a operadores de motores de búsqueda pública para usar motores
					de búsqueda o arañas web de búsqueda para copiar Contenido con el
					único fin de crear índices de búsqueda públicamente disponibles del
					Contenido.
				</p>
				<strong>XXXV. PROPIEDAD EQUIPO DE COMPUTO O DISPOSITIVO MÓVIL. </strong>
				<p>
					Usted es responsable de ser el legítimo propietario del dispositivo
					móvil o equipo de cómputo y de mantenerlo acorde con las
					especificaciones definidas por la aplicación tecnológica TIN2GO. Usted
					será el único responsable del pago de tarifas y gastos en
					telecomunicaciones, tales como planes de datos para tener acceso a
					internet y pagos parciales de planes de adquisición de equipos de
					cómputo y dispositivos móviles. TIN2GO no se hace responsable de
					ninguna pérdida o daño que sufran los dispositivos móviles o equipos
					de cómputo por daño a los equipos utilizados o perdida de información
					en relación con el uso de la aplicación TIN2GO. TIN2GO no se hace
					responsable de ningún cargo económico hecho por un aumento su servicio
					o plan de datos para acceder a Internet.
				</p>
				<strong>XXXVI. MENORES DE 18 AÑOS.</strong>
				<p>
					El uso de los Servicios no está dirigido a usuarios menores de 18
					años. Si usted es menor de 18 años, no se le permite registrarse como
					usuario.
				</p>
				<strong>XXXVII. SITIOS WEB DE TERCEROS.</strong>
				<p>
					La aplicación TIN2GO puede contener enlaces a sitios web o
					aplicaciones de terceros. Las aplicaciones y sitios enlazados no están
					bajo el control de la aplicación TIN2GO, SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V. no se hace responsable de los contenidos de
					cualquier sitio web o aplicación vinculada. SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V. proporciona estos vínculos sólo como una
					conveniencia, y un enlace que no implica aprobación, patrocinio o
					afiliación con el sitio enlazado.
				</p>
				<strong>XXXVIII. IDEAS PRESENTADAS A TIN2GO:</strong>
				<p>
					TIN2GO se complace en tener retroalimentación de sus usuarios finales
					y agradece sus comentarios acerca de los servicios y de la aplicación
					TIN2GO. En caso de que usted envíe ideas o sugerencias de la
					aplicación TIN2GO, se considerarán estas sugerencias y comentarios
					pero en el caso de implementarlos la propiedad de estos será exclusiva
					de SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V., ninguna de
					las sugerencias o comentarios estarán sujetas a ninguna obligación de
					confidencialidad por parte de SERVICIOS DE TINTORERIA Y LOGISTICA
					S.A.P.I. DE C.V. y no será responsable de cualquier uso o divulgación
					de cualquier sugerencia o comentario. Sin perjuicio de lo anterior,
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. tendrá derecho al
					uso irrestricto de los comentarios para cualquier fin, comercial o de
					otro tipo, por cualquier medio, sin compensación alguna para el
					proveedor, autor, creador o inventor de las sugerencias o comentarios.
				</p>
				<strong>
					XXXIX. LÍMITES DE RESPONSABILIDAD TIN2GO POR EL USO DE LA APLICACIÓN
					TECNOLOGICA Y FUNCIONALIDAD DE LA PÁGINA WEB:
				</strong>
				<p>
					TIN2GO Y/O SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. PUEDEN
					INTERRUMPIR LOS SERVICIOS DE LA APLICACIÓN TECNOLÓGICA TIN2GO EN
					CUALQUIER MOMENTO, LOS USUARIOS FINALES DE LA APLICACIÓN DEBERÁN
					ESPERAR TIEMPOS DE INACTIVIDAD PARA QUE SE EJECUTEN LAS
					ACTUALIZACIONES PERIÓDICAS A LA APLICACIÓN. TIN2GO Y/O SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. NO OTORGAN NINGUN TIPO DE
					GARANTÍA, YA SEA EXPRESA O IMPLÍCITA. TIN2GO Y/O SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. NO OTORGAN GARANTIAS DE QUE LA
					APLICACIÓN SIEMPRE ESTARA DISPONIBLE, ACCESIBLE Y QUE EL SERVICIO SERA
					ININTERRUMPIDO, OPORTUNO, SEGURO O QUE OPERA SIN ERRORES. TIN2GO Y/O
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. TAMPOCO OTORGAN
					GARANTIAS EN CUANTO A LA PRECISIÓN O CONFIABILIDAD DE CUALQUIER
					INFORMACIÓN OBTENIDA DE LA APLICACIÓN TECNOLOGICA TIN2GO. NINGÚN
					CONSEJO O INFORMACIÓN, YA SEA ORAL O ESCRITA, OBTENIDO POR USTED A
					TRAVÉS DE LA APLICACIÓN TIN2GO CREARÁ NINGUNA GARANTÍA QUE NO ESTÉN
					EXPRESAMENTE ESTABLECIDAS EN ESTE DOCUMENTO. EL USUARIO FINAL NO PODRA
					BAJO NINGUNA CIRCUNSTANCIA RECLAMAR CUALQUIER DAÑO INCIDENTAL,
					CONSECUENTE , INDIRECTO, MORAL O ESPECIAL (INCLUYENDO DAÑOS POR
					PÉRDIDAS ECONOMICAS, RESPONSABILIDAD CIVIL OBJETIVA PÉRDIDA DE
					BENEFICIOS O PÉRDIDA DE INFORMACION) , YA SEA POR CONTRATO , AGRAVIO
					(INCLUYENDO NEGLIGENCIA), O CUALQUIER DAÑO RELACIONADO CON LA
					APLICACIÓN TIN2GO. ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE
					GARANTÍAS O LIMITACIONES DE RESPONSABILIDAD POR CIERTOS TIPOS DE
					DAÑOS, POR LO QUE ALGUNAS DE LAS LIMITACIONES ANTERIORES PUEDEN NO
					SEAN APLICABLES A USTED.
				</p>
				<strong>XL. INDEMNIZACIÓN Y DAÑOS Y PERJUICIOS:</strong>
				<p>
					Usted como usuario final deberá defender, indemnizar y mantener a
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. y a la aplicación
					TIN2GO indemne frente a cualquier reclamación, acciones,
					procedimientos, demandas, responsabilidades, pérdidas, daños, costos,
					gastos y honorarios de abogados que surjan en relación con el uso de
					los servicios de la aplicación TIN2GO o por el incumplimiento de
					cualquier disposición de los presentes términos y condiciones . TIN2GO
					Y/O SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. se reservan
					el derecho de asumir el control exclusivo y resolución de cualquier
					reclamo, acción, demanda o procedimiento para el que esté obligado a
					otorgar una indemnización, usted como el usuario final cooperará con
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.para tal defensa y
					su resolución de acuerdo al aviso de privacidad.
				</p>
				<p>
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. opera los
					servicios de uso de la aplicación TIN2GO de conformidad con el aviso
					de privacidad. El aviso de privacidad se actualiza periódicamente.
				</p>
				<strong>XLI. LEY DEL DERECHO DE AUTOR:</strong>
				<p>
					TIN2GO cumple y está protegido por las disposiciones de la Ley Federal
					del Derecho de Autor. Si tiene alguna queja o protesta acerca de
					materiales publicados en la aplicación TIN2GO envíe un correo
					electrónico a la siguiente dirección electrónica gerencia@tin2go.com o
					envíe una carta o comentario a la siguiente dirección:
				</p>
				<p> A la atención de: Luis Antonio Velasco</p>
				<p>
					Cualquier notificación alegando que la información almacenada o
					distribuida a través de la aplicación TIN2GO infringe derechos de
					propiedad intelectual deberá incluir lo siguiente:
				</p>
				<p>
					1. Poder original o copia certificada de poder para actuar en nombre
					del propietario de los derechos de autor (derechos patrimoniales) u
					otro derecho que presumiblemente se violó.
				</p>
				<p>
					2. Título del Instituto Nacional de los Derechos de Autor o su
					equivalente que contenga una descripción de la obra, derechos de autor
					(derechos patrimoniales) u otra propiedad intelectual que usted afirma
					han sido violadas.
				</p>
				<p>
					3. Una descripción del material que considera que infringe y dónde se
					encuentra en la aplicación TIN2GO;
				</p>
				<p>
					4. Su dirección, número de teléfono (con código de país) y dirección
					de correo electrónico;
				</p>
				<p>
					5. Una declaración en donde usted afirma que tiene una creencia de
					buena fe que el uso de los materiales en los servicios de los que se
					queja no está autorizado por el propietario de los derechos de autor
					(copyright) o la ley.
				</p>
				<p>
					6. Una declaración suya de que la información mencionada en su
					notificación es exacta y, bajo pena de perjurio , que son los derechos
					de autor o propiedad intelectual o está autorizado a actuar en nombre
					del autor o dueño de la propiedad intelectual.
				</p>
				<strong>XLII. LEY Y JURISDICCIÓN APLICABLE (Cumplimiento).</strong>
				<p>
					Usted como usuario final y SERVICIOS DE TINTORERIA Y LOGISTICA
					S.A.P.I. DE C.V. aceptan que todos los asuntos que surjan de o
					relacionados con el uso, instalación, actualizaciones y funcionamiento
					de la aplicación TIN2GO se regirán por las leyes y los tribunales de
					la Ciudad de México. Usted como usuario final acepta que cualquier
					reclamación que surja en relación con la operación, uso o cualquier
					otra explotación de la aplicación serán escuchadas y resueltas en los
					tribunales de la Ciudad de México, México. Si usted como usuario final
					usa, instala o actualiza la aplicación TIN2GO desde un lugar distinto
					a la Ciudad de México Distrito Federal, México, usted como usuario
					final será responsable del cumplimiento de todas las leyes locales de
					cualquier otro lugar, y en ningún caso va a utilizar la aplicación
					TIN2GO, información o contenidos de la misma, violando leyes mexicanas
					u otras leyes o regulaciones de otros países fuera de México.
				</p>
				<strong>
					XLIII. SUSPENSIÓN DE SERVICIOS, TERMINACIÓN ANTICIPADA Y FUERZA MAYOR:
				</strong>
				<p>
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. se reserva el
					derecho de suspender su contraseña y/o acceso a la aplicación TIN2GO
					en cualquier momento si considera que un usuario final está violando
					el presente acuerdo. TIN2GO se reserva el derecho de suspender o
					modificar el presente acuerdo, cancelar los servicios de uso,
					instalación o actualizaciones o modificar las características o
					funcionalidad de la aplicación TIN2GO, o modificar sus políticas en
					cualquier momento, con o sin previo aviso a usted como usuario final.
					Si usted utiliza la aplicación TIN2GO como usuario final está obligado
					por el presente acuerdo que entra vigor en el momento del uso,
					instalación o actualización de la aplicación TIN2GO. Usted como
					usuario final está obligado a revisar la versión actual del presente
					acuerdo, del aviso de privacidad y de otras políticas y manuales
					operativos de TIN2GO, antes de utilizar la página web de TIN2GO y sus
					actualizaciones. En ningún caso SERVICIOS DE TINTORERIA Y LOGISTICA
					S.A.P.I. DE C.V. o la aplicación TIN2GO serán responsables de
					cualquier incumplimiento del presente acuerdo, en la medida en que el
					incumplimiento se deba a factores fuera del control razonable de
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V.
				</p>
				<strong>XLIV. DISPOSICIONES VARIAS:</strong>
				<p>
					Ninguna demora u omisión de TIN2GO en el ejercicio de cualquiera de
					sus derechos que deriven en cualquier incumplimiento con respecto a
					cualquiera de los términos y condiciones de este contrato se
					interpretará como una renuncia de ningún derecho o de cualquier otro
					convenio, condición o acuerdo de este documento. Si alguna disposición
					de este Acuerdo es considerada por un tribunal de jurisdicción
					competente para ser inválida o inaplicable, el presente acuerdo
					permanecerá en pleno vigor y efecto salvo la parte que se reformó.
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. y reemplaza a
					todas las anteriores promesas, acuerdos o representaciones, escritas u
					orales, con respecto al objeto del mismo. Con el registro de usted
					como usuario final en la aplicación TIN2GO, otorga a SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. su consentimiento expreso PARA
					CUMPLIR con todos los términos y condiciones del presente acuerdo y
					sus derechos y obligaciones en virtud del presente, no son asignables
					o transferibles los derechos y obligaciones por usted a terceros sin
					el consentimiento previo por escrito de SERVICIOS DE TINTORERIA Y
					LOGISTICA S.A.P.I. DE C.V.
				</p>
				<p>
					SERVICIOS DE TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. puede ceder este
					acuerdo con todos sus efectos comerciales y jurídicos a cualquier
					comprador de la aplicación TIN2GO o del negocio de SERVICIOS DE
					TINTORERIA Y LOGISTICA S.A.P.I. DE C.V. El presente Acuerdo podrá ser
					ejecutado por vía electrónica, y aceptación estará completamente
					ligada a su registro, uso, instalación o actualización de la
					aplicación de TIN2GO y constituirá la ejecución de este acuerdo. Usted
					como usuario final acepta todo el texto electrónico escrito de este
					acuerdo y el uso, la instalación y actualización de la aplicación
					TIN2GO constituye la aceptación total de todos los términos y
					condiciones del presente documento al mismo tiempo que constituye
					consentimiento para todos los efectos legales.
				</p>
				<p>Reglas de Operación de las Tarjetas de Prepago y Promocionales:</p>
				<p>
					1. EMISIÓN.- Las Tarjetas de Prepago y Promocionales de TIN2GO (en
					adelante la(s) Tarjeta(s)) podrán encontrarse a la venta, si así lo
					determina TIN2GO dentro del sitio Web. Adicionalmente TIN2GO podrá
					expedir a su absoluta discreción tarjetas promocionales para sus
					clientes, las cuales no podrán ser adquiridas con dinero. Para comprar
					una Tarjeta será necesario tener una cuenta de usuario conforme se
					establecen dentro de estos Términos y Condiciones de Uso del Portal de
					TIN2GO. En el supuesto de que TIN2GO active la funcionalidad de la
					Tarjeta de Regalo, será necesario ingresar el código secreto que
					contenga dicha tarjeta dentro de la cuenta de usuario de destino,
					donde tras la validación del mismo, la cuenta del Usuario reflejará el
					saldo abonado. En caso de adquisición de tarjetas en línea, el valor
					se verá reflejado en la cuenta del beneficiario, en concordancia con
					las reglas que se establezcan dentro de este documento para la
					identificación de las cuentas de usuario. La compra de una Tarjeta no
					es reembolsable ni cancelable. TIN2GO no será responsable por errores
					en la captura de códigos ni por fallas relacionadas al acceso de
					Internet o de suministro eléctrico que puedan ocasionar perdidas
					dentro del sitio Web de TIN2GO.
				</p>
				<p>
					2. Uso.- Es obligatorio para recibir los beneficios de una Tarjeta de
					Prepago, ser usuario registrado del sitio Web de TIN2GO. En el caso de
					las Tarjetas Promocionales, se podrán recibir los beneficios de la
					misma con solo solicitar los servicios de TIN2GO a través de su sitio
					web.
				</p>
				<p>
					3. Prioridad del Uso.- En su calidad de Usuario, y en caso de contar
					con saldo dentro de su cuenta que tenga como origen alguna de las
					Tarjetas, la regla de uso dará prioridad en todo momento a cualquier
					saldo que tenga fecha de expiración, para después utilizar el saldo
					sin fecha límite de uso. Siempre serán utilizados los saldos de
					acuerdo a su antigüedad (los más antiguos serán los primeros en
					aplicarse).
				</p>
				<p>
					4. Operaciones sin derecho a Comisión.- El Usuario entiende que si
					compra una tarjeta de regalo para una persona que no sea usuario
					registrado de TIN2GO, y para realizar su uso el beneficiario de la
					tarjeta de regalo se inscribe al servicio, deberá entenderse que bajo
					ninguna circunstancia tendrá el comprador de la Tarjeta de Regalo
					derecho a recibir comisión alguna por el registro del nuevo usuario.
				</p>
				<p>
					5. Expiración y cancelación. Las Tarjetas de Regalo tendrán una
					validez de un año a partir de su activación por TIN2GO o algún tercero
					autorizado para ello por TIN2GO. Pasado este periodo, cualquier saldo
					acumulado en la cuenta del usuario caducará a favor de TIN2GO. Una vez
					realizada la compra de La Tarjeta, el Usuario acepta que dicha
					operación no podrá ser cancelada y en consecuencia no será posible
					realizar un reembolso por el valor de la misma. Las Tarjetas
					Promocionales siempre contendrán en la propia tarjeta su fecha de
					vigencia.
				</p>
				<p>
					6. Limitaciones. Las Tarjetas sólo pueden ser usadas en la página Web
					de TIN2GO para solicitar los servicios de lavandería y tintorería que
					en ella se ofrecen. No aplica para otras ofertas o descuentos ni para
					promociones especiales. Si el monto del saldo es insuficiente para
					pagar los servicios contratados, el Usuario acepta que deberá pagar el
					saldo restante con una tarjeta de crédito u otro método de pago
					aceptado dentro del sitio Web.
				</p>
				<p>Las Tarjetas no pueden ser usadas para comprar otras Tarjetas.</p>
				<p>
					Las Tarjetas no pueden ser recargadas, revendidas, transferidas por su
					valor, canjeadas por efectivo o aplicadas a otra cuenta, excepto que
					exista autorización expresa de TIN2GO o porque exista un requerimiento
					legal para ello.
				</p>
				<p>
					Restricciones especiales para las Tarjetas Promocionales. Las Tarjetas
					Promocionales no podrán ser transferidas, ni podrá solicitarse un
					reembolso equivalente a su valor, ni podrán ser canjeadas, ni
					aplicadas a pago de tarjetas de crédito. Las Tarjetas Promocionales no
					serán reemplazadas por robo o extravío, y dado que contienen su valor
					intrínsecamente, se entenderá perdido el derecho al beneficio
					establecido en la misma. Las Tarjetas Promocionales no pueden ser
					revendidas.
				</p>
				<p>
					7. Políticas de Uso.- Las Tarjetas y su uso se encuentran sujetos a
					estos Términos y Condiciones, así como al Aviso de Privacidad de
					TIN2GO consultable en su sitio Web TIN2GO se reserva el derecho a
					modificar este apartado de los Términos y Condiciones o las Reglas de
					Privacidad a su entera discreción, y con la sola publicación de los
					cambios en el sitio Web, mediante el uso continuado de los servicios
					de TIN2GO, se entenderán aceptados los cambios a las condiciones de
					uso.
				</p>
				<p>
					8. Pérdida de Beneficios.- Las Tarjetas de Regalo podrán ser
					canceladas si el Usuario las reporta en servicioalcliente@tin2go.com
					antes de su abono a la cuenta del beneficiario. A pesar de lo
					anterior, se le recomienda al Usuario tener en resguardo cualquier
					Tarjeta de Regalo que adquiera. La falta de aviso oportuno de robo o
					extravío de una Tarjeta de Regalo, libera a TIN2GO de cualquier
					responsabilidad. El riesgo de pérdida de la titularidad de una Tarjeta
					pasa al comprador una vez que se ha activado la Tarjeta, por lo que si
					no se reporte oportunamente, y un tercero acredita el valor de la
					misma a su cuenta, se entenderá que el titular de la Tarjeta es quien
					la ingresó en el sistema. Las Tarjetas Promocionales contienen un
					Código Único, pero no serán reemplazadas por pérdida, robo,
					destrucción o uso por terceros y se entenderá que sin estas, TIN2GO
					estará libre de cualquier obligación o responsabilidad al respecto.
				</p>
				<p>
					9. Límite de Garantías.- TIN2GO, sus filiales, socios y dependientes,
					no otorgan ninguna garantía expresa o implícita de la factibilidad,
					funcionalidad, comerciabilidad o idoneidad con respecto a Las Tarjetas
					para todo efecto legal. En el supuesto de que alguna Tarjeta no
					funcionará correctamente, la única obligación para TIN2GO será
					reemplazarla por otra. De ninguna manera esto generará daños o
					perjuicios a favor del Usuario y en perjuicio de TIN2GO.
				</p>
				<p>
					10. Acuerdos Generales.- Si cualquiera de estos términos y condiciones
					fueran declarados inválidos, nulos o inaplicables por alguna
					autoridad, EL USUARIO acepta y reconoce que el resto del acuerdo
					seguirá vigente. En caso de disputas relacionadas con la operación del
					sitio, respecto de los servicios de TIN2GO o cualquier otra situación
					conexa, el USUARIO acepta someterse a las Leyes y Tribunales de la
					Ciudad de México, renunciando a cualquier otra Jurisdicción que
					pudiera corresponderle por razón de su domicilio actual o futuro, o
					derivado de cualquier otra situación.
				</p>
				<p>
					11. Servicio al Cliente.- Si necesita asistencia o soporte para el uso
					de las Tarjetas, le pedimos se comunique a los Teléfonos de Atención a
					Clientes contenidos en el sitio Web, o a través del correo
					electrónico,
				</p>
				<p>
					Fecha de la última actualización de términos y condiciones de uso,
					instalación y actualizaciones:
				</p>
				<p>1 de Enero del 2020</p>
			</article>
		</section>
	);
};

export default Terms;
