/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/styles/components/auth/Login.scss';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import Error from '../../layouts/Error';
import Loader from '../../layouts/Loader';
import { LoginAction } from '../../actions/LoginActions';
import store from '../../store';
import authService from '../../services/AuthService';
import { ReactComponent as SVGSUCCESS } from '../../assets/static/icons/success.svg';
import '../../assets/styles/components/auth/ForgotPassword.scss';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.isSubmitting = props.isSubmitting;
    this.isValid = props.isValid;
    this.state = { loginData: {}, loading: false, error: null };
    this.message = null;
  }

  validate = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Por favor, introduzca un correo electrónico.';
    }

    if (!this.validateEmail(values.email)) {
      errors.email = 'Por favor, introduzca un correo electrónico valido.';
    }

    return errors;
  };

  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error />;
    }

    if (this.message) {
      return (
        <>
          <div className='card-forget-password'>
            <SVGSUCCESS />
            <p>Se enviara un correo para cambiar la contraseña.</p>
          </div>
        </>
      );
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{
          email: ''
        }}
        validate={this.validate}
        onSubmit={async (values, actions) => {
          try {
            const res = await authService.forgotPassword(values.email);
            const data = res.data;
            this.message = data.message;
          } catch (error) {
            console.log('error', error);
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          values,
          setValues,
          isValid
        }) => {
          return (
            <div className='auth-container'>
              <h1>¿Olvidaste tu contraseña?</h1>
              <p>Ingresa tu correo para restablecer la contraseña.</p>
              <Form>
                <div className='form-group'>
                  <Field
                    className='width100'
                    type='email'
                    name='email'
                    placeholder='Correo electrónico'
                    value={values.email}
                  />
                  <ErrorMessage name='email'>
                    {message => <div className='inputError'>{message}</div>}
                  </ErrorMessage>
                </div>
                <br />
                <button
                  type='submit'
                  disabled={!isValid}
                  className={`forgotButton width100 submit`}
                >
                  Cambiar contraseña
                </button>
              </Form>
              <br />
              <Link to='/login' className='forgotBack'>
                Regresar
              </Link>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default ForgotPassword;
