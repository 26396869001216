import React, { Component } from 'react';
import '../assets/styles/components/Sections.scss';
import SectionList from './SectionList';

class Sections extends Component {
  render() {
    return (
      <section className='sections'>
        <div className='sections__head'>
          <p>En que podemos ayudarte</p>
          <h1>Nuestros servicios</h1>
        </div>
        <SectionList />
      </section>
    );
  }
}

export default Sections;
