import { combineReducers } from 'redux';
import basketReducer from './basketReducer';
import categoryReducer from './categoryReducer';
import loginReducer from './loginReducer';
import sectionReducer from './sectionReducer';
import orderReducer from './orderReducer';
import orderObjReducer from './orderObjReducer';
import paymentReducer from './paymentReducer';
import addressReducer from './addressReducer';
import headerReducer from './headerReducer';
import authReducer from './authReducers';
import successReducer from './successReducer';
import modalReducer from './modalReducer';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  routing: routerReducer,
  basketReducer,
  headerReducer,
  categoryReducer,
  loginReducer,
  sectionReducer,
  authReducer,
  orderReducer,
  orderObjReducer,
  paymentReducer,
  addressReducer,
  successReducer,
  modalReducer
});
