const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ORDER_SUBTOTAL':
      return {
        ...state,
        subtotal: action.payload
      };
    case 'SET_ORDER_DISCOUNT':
      return {
        ...state,
        discount: action.payload
      };
    case 'SET_ORDER_TOTAL':
      return {
        ...state,
        total: action.payload
      };
    case 'SET_ORDER_DATE_RECOVERY':
      return {
        ...state,
        dateOfRecovery: action.payload
      };
    case 'SET_ORDER_DATE_DELIVERY':
      return {
        ...state,
        dateOfDelivery: action.payload
      };
    case 'SET_ORDER_PAYMENT_STATUS':
      return {
        ...state,
        paymentStatus: action.payload
      };
    case 'SET_ORDER_CHANNEL':
      return {
        ...state,
        orderChannel: action.payload
      };
    case 'SET_ORDER_NOTE':
      return {
        ...state,
        numberNote: action.payload
      };
    case 'SET_ORDER_PAY_FORM':
      return {
        ...state,
        payForm: action.payload
      };
    case 'SET_ORDER_TRACKING':
      return {
        ...state,
        orderTracking: action.payload
      };
    case 'SET_ORDER_CUSTOMER':
      return {
        ...state,
        idCustomer: action.payload
      };
    case 'SET_ORDER_DATE':
      return {
        ...state,
        orderDate: action.payload
      };
    case 'SET_ORDER_ADDRESS_RECOVERY':
      return {
        ...state,
        idAddressRecovery: action.payload
      };
    case 'SET_ORDER_ADDRESS_DELIVERY':
      return {
        ...state,
        idAddressDelivery: action.payload
      };
    case 'SET_ORDER_PAY_REFERENCE':
      return {
        ...state,
        payReference: action.payload
      };
    case 'SET_ORDER_DATE_PAYMENT':
      return {
        ...state,
        dateOfPayment: action.payload
      };
    case 'SET_ORDER_PROMOTIONS':
      return {
        ...state,
        promotions: action.payload
      };
    case 'RESET_ORDER':
      return {};
    default:
      return state;
  }
};
