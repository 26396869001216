import React, { Component } from 'react';
import axios from 'axios';
import Error from '../layouts/Error';
import Section from './Section';
import Loader from '../layouts/Loader';
import { ReactComponent as SVGSad } from '../assets/static/icons/sad.svg';
import '../assets/styles/components/SectionList.scss';

class SectionList extends Component {
  state = {
    loading: false,
    error: null,
    sectionsData: []
  };

  componentDidMount() {
    this.getSections();
  }

  getSections = async () => {
    this.setState({ loading: true, error: null });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/section`
      );
      this.setState({ loading: false, sectionsData: response.data });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error />;
    }

    if (!this.state.sectionsData.length) {
      return (
        <div className='sectionList__empty'>
          <SVGSad className='sectionList__empty-icon' />
          <h4 className='sectionList__empty-text'>
            No se encontraron secciones.
          </h4>
        </div>
      );
    }

    return (
      <div className='sectionList'>
        {this.state.sectionsData.map(item => {
          return <Section key={item.idSection} data={item} />;
        })}
      </div>
    );
  }
}

export default SectionList;
