import React from 'react';
import SocialLogin from 'react-social-login';
import '../../assets/styles/components/auth/SocialButton.scss';

class SocialButton extends React.Component {
  render() {
    return (
      <button
        className='socialButton'
        onClick={this.props.triggerLogin}
        {...this.props}
      >
        {this.props.children}
      </button>
    );
  }
}

export default SocialLogin(SocialButton);
