import React, { Component } from 'react';
import Loader from '../../layouts/Loader';
import Error from '../../layouts/Error';
import { getUserState } from '../../localStorage';
import paymentService from '../../services/Payments';
import '../../assets/styles/components/profile/payments.scss';
import { ReactComponent as SVGNOPAYMENTS } from '../../assets/static/icons/nopayments.svg';
import { ReactComponent as SVGDELETE } from '../../assets/static/icons/delete.svg';
import Modal from '../../layouts/Modal';
import PaymentNew from '../PaymentNew';
import PaymentDelete from '../PaymentDelete';

class Payments extends Component {
  state = {
    loginData: {},
    loading: true,
    error: null,
    payments: [],
    modalIsOpen: false,
    modalContent: null
  };

  constructor(props) {
    super(props);
    this.isSubmitting = props.isSubmitting;
    this.isValid = props.isValid;
    this._isMounted = false;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getPayments();
  }

  getPayments = async () => {
    this.setState({ loading: true, error: null });

    try {
      const user = getUserState();
      const response = await paymentService.getPayment(user.idCustomer);
      if (this._isMounted) {
        this.setState({
          loading: false,
          error: null,
          payments: response.data
        });
      }
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  deletePayment = paymentData => {
    this.openModal(
      <PaymentDelete
        data={paymentData}
        handleCloseModal={this.handleCloseModal}
      />
    );
  };

  addPayments = () => {
    this.openModal(<PaymentNew handleCloseModal={this.handleCloseModal} />);
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
    this.getPayments();
  };

  openModal = component => {
    this.setState({ modalIsOpen: true, modalContent: component });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error />;
    }

    if (this.state.payments.length === 0) {
      return (
        <>
          <section className='nopayments'>
            <div className='message'>
              <SVGNOPAYMENTS className='image' />
              <p className='description'>
                No cuentas con ningún método de pago registrado
              </p>
            </div>
            <button onClick={this.addPayments}>Agregar</button>
          </section>
          <Modal
            isOpen={this.state.modalIsOpen}
            onClose={this.handleCloseModal}
          >
            <div className='backPayment'>{this.state.modalContent}</div>
          </Modal>
        </>
      );
    }

    return (
      <>
        <section className='payments'>
          <div className='payments-head'>
            <h2>Métodos de pago</h2>
            <button onClick={this.addPayments}>Agregar</button>
          </div>
          <div className='payments-information'>
            {this.state.payments.map(item => {
              return (
                <div key={item.idPaycards} className='payment-item'>
                  <div>
                    <p className='title'>{`${getUserState().name} ${
                      getUserState().lastname
                    }`}</p>
                    <p className='description'>
                      {`•••• •••• •••• ${item.lastfour}`}
                    </p>
                  </div>
                  <SVGDELETE
                    className='delete'
                    onClick={() => {
                      this.deletePayment(item);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </section>
        <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal}>
          <div className='backPayment'>{this.state.modalContent}</div>
        </Modal>
      </>
    );
  }
}

export default Payments;
