import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as orderActions from '../actions/orderActions';
import * as localStorage from '../localStorage';
import { withFormik, Field, ErrorMessage, Form } from 'formik';
import { ReactComponent as SVGPayCard } from '../assets/static/icons/payCard.svg';
import '../assets/styles/components/PaymentNew.scss';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';

class PaymentNew extends Component {
  state = {
    loading: false,
    error: false,
    years: [],
    tokenObj: {},
    newPayCardResponse: null
  };

  createPayCard = async () => {
    const user = localStorage.getUserState();
    window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_KEY);
    const successResponseHandler = async token => {
      this.setState({
        tokenObj: {
          token: token.id,
          lastfour: tokenParams.card.number.substring(12, 16),
          idCustomer: user.idCustomer
        }
      });

      this.setState({ loading: true, error: null });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/paycards`,
          this.state.tokenObj
        );
        this.setState({ loading: false, newPayCardResponse: response.data });
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    };
    var errorResponseHandler = function(error) {
      console.log(error);
    };
    var tokenParams = {
      card: {
        number: this.props.values.card,
        name: this.props.values.name,
        exp_year: this.props.values.year,
        exp_month: this.props.values.month,
        cvc: this.props.values.cvv
      }
    };
    window.Conekta.Token.create(
      tokenParams,
      successResponseHandler,
      errorResponseHandler
    );
  };

  closeModal = () => {
    if (window.location.pathname === '/perfil' && this.props.data) {
      this.props.change();
    } else {
      this.props.handleCloseModal();
    }
  };

  getYears = () => {
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.state.years.push(currentYear + i);
    }
  };

  toPayment = () => {
    window.scrollTo(0, 0);
    this.props.setStep('cardPay');
  };

  paySubmit = () => {
    this.createPayCard();
  };

  constructor() {
    super();
    this.getYears();
  }

  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div className='paymentNew'>
        {this.state.loading ? (
          <Loader />
        ) : this.state.error ? (
          <Error vertical={true} />
        ) : this.state.newPayCardResponse ? (
          <div className='paymentNewSuccess'>
            <SVGPayCard className='payCard-icon' />
            <h2>Tarjeta creada con éxito.</h2>
            {window.location.pathname === '/orden' ? (
              <button type='button' onClick={this.toPayment}>
                Aceptar
              </button>
            ) : (
              <button type='button' onClick={this.closeModal}>
                Aceptar
              </button>
            )}
          </div>
        ) : (
          <>
            <h3 className='paymentNew-title'>Tarjeta de crédito o débito</h3>
            <Form>
              <span className='label'>Títular</span>
              <Field className='field width100' type='text' name='name' />
              <ErrorMessage name='name'>
                {message => <div className='inputError'>{message}</div>}
              </ErrorMessage>
              <span className='label'>Número de tarjeta</span>
              <Field className='field width100' type='text' name='card' />
              <ErrorMessage name='card'>
                {message => <div className='inputError'>{message}</div>}
              </ErrorMessage>
              <div className='paymentNew__cardDetails'>
                <div className='paymentNew__cardDetails-item date'>
                  <span className='label'>Mes de vencimiento</span>
                  <Field as='select' className='field' type='text' name='month'>
                    <option defaultValue='' disabled hidden />
                    <option value='01' label='enero' />
                    <option value='02' label='febrero' />
                    <option value='03' label='marzo' />
                    <option value='04' label='abril' />
                    <option value='05' label='mayo' />
                    <option value='06' label='junio' />
                    <option value='07' label='julio' />
                    <option value='08' label='agosto' />
                    <option value='09' label='septiembre' />
                    <option value='10' label='octubre' />
                    <option value='11' label='noviembre' />
                    <option value='12' label='diciembre' />
                  </Field>
                  <ErrorMessage name='month'>
                    {message => <div className='inputError'>{message}</div>}
                  </ErrorMessage>
                </div>
                <div className='paymentNew__cardDetails-item date'>
                  <span className='label'>Año de vencimiento</span>
                  <Field as='select' className='field' type='text' name='year'>
                    <option defaultValue='' disabled hidden />
                    {this.state.years.map(element => {
                      return (
                        <option key={element} value={element} label={element} />
                      );
                    })}
                  </Field>
                  <ErrorMessage name='year'>
                    {message => <div className='inputError'>{message}</div>}
                  </ErrorMessage>
                </div>
                <div className='paymentNew__cardDetails-item cvv'>
                  <span className='label'>CVV</span>
                  <Field className='field width100' type='text' name='cvv' />
                  <ErrorMessage name='cvv'>
                    {message => <div className='inputError'>{message}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <button
                type='button'
                onClick={this.paySubmit}
                className={`submit ${
                  this.props.isSubmitting || !this.props.isValid
                    ? 'disabled'
                    : ''
                }`}
                disabled={this.props.isSubmitting || !this.props.isValid}
              >
                Agregar tarjeta
              </button>
            </Form>
            {window.location.pathname === '/orden' ? (
              <span className='newLink' onClick={this.toPayment}>
                Regresar...
              </span>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.orderReducer;
};

const _PaymentNew = connect(mapStateToProps, orderActions)(PaymentNew);

export default withFormik({
  mapPropsToValues(props) {
    return {
      name: '',
      card: '',
      month: '',
      year: '',
      cvv: ''
    };
  },

  validate(values) {
    const errors = {};

    if (!values.name) {
      errors.name = 'Por favor introduzca el nombre del títular de la tarjeta.';
    }

    if (!values.card) {
      errors.card = 'Por favor introduzca el número de la tarjeta.';
    } else if (!window.Conekta.card.validateNumber(values.card)) {
      errors.card = 'Número de tarjeta inválido';
    }

    if (!values.month) {
      errors.month =
        'Por favor introduzca el mes de vencimiento de la tarjeta.';
    }

    if (!values.year) {
      errors.año = 'Por favor introduzca el año de vencimiento de la tarjeta.';
    }

    if (!values.cvv) {
      errors.cvv = '';
    } else if (isNaN(parseInt(values.cvv)) || values.cvv.length !== 3) {
      errors.cvv = 'CVV inválido';
    }

    return errors;
  }
})(_PaymentNew);
