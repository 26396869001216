import axios from 'axios';

class AddressService {
  getAddresses(idCustomer) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/addressByCustomer/${idCustomer}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  }

  saveAddress(address, idCustomer) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/address/${idCustomer}`,
          address
        );
        resolve(response);
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  }

  updateAddress(address, idAddress) {
    return new Promise(async (resolve, reject) => {
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/address/${idAddress}`,
          address
        );
        resolve((response = { data: {} }));
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  }

  verifyPostalCode(postalCode) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/address/verifyPostalCode/${postalCode}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  }
}

const addressService = new AddressService();

export default addressService;
