import React, { Component } from 'react';
import '../../assets/styles/components/address/Address.scss';

class Address extends Component {
  constructor(props) {
    super(props);
    this.address = props.address;
  }

  render() {
    return this.address ? (
      <div className='address'>
        <h4 className='title'>{this.address.alias}</h4>
        <span className='location'>{`${this.address.street} ${
          this.address.numExt ? this.address.numExt : ''
        }, ${this.address.colony}, ${this.address.locality}, CP. ${
          this.address.CP
        }.`}</span>
      </div>
    ) : (
      ''
    );
  }
}

export default Address;
