import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as localStorage from '../localStorage';
import * as basketActions from '../actions/basketActions';
import '../assets/styles/components/Product.scss';
import { ReactComponent as SVGAdd } from '../assets/static/icons/add.svg';
import { ReactComponent as SVGRemove } from '../assets/static/icons/remove.svg';
import Modal from '../layouts/Modal';
import ProductAdd from './ProductAdd';
import Basket from './Basket';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';

class Product extends Component {
  state = {
    loading: false,
    error: null,
    contDry: 0,
    contWet: 0,
    modalIsOpen: false,
    modalContent: null
  };
  myStorage = window.localStorage;

  addDry = () => {
    this.setState({ contDry: this.state.contDry + 1 });
  };

  addWet = () => {
    this.setState({ contWet: this.state.contWet + 1 });
  };

  addProduct = () => {
    if (!this.state.contDry && !this.state.contWet) {
      return;
    }

    let productDry = null;
    let productWet = null;
    let product = null;

    if (this.props.data.priceWet) {
      if (this.state.contDry) {
        productDry = {
          displayType: 'Dry',
          idProduct: this.props.data.idProduct,
          keyProduct: `${this.props.data.idProduct}Dry`,
          name: this.props.data.nameProduct,
          numProd: this.state.contDry,
          price: this.props.data.priceDry,
          section: this.props.sectionReducer.activeSection.name,
          service: 'Dry',
          total: this.state.contDry * this.props.data.priceDry
        };
      }

      if (this.state.contWet) {
        productWet = {
          displayType: 'Wet',
          idProduct: this.props.data.idProduct,
          keyProduct: `${this.props.data.idProduct}Wet`,
          name: this.props.data.nameProduct,
          numProd: this.state.contWet,
          price: this.props.data.priceWet,
          section: this.props.sectionReducer.activeSection.name,
          service: 'Wet',
          total: this.state.contWet * this.props.data.priceWet
        };
      }
    } else {
      product = {
        displayType: '',
        idProduct: this.props.data.idProduct,
        keyProduct: `${this.props.data.idProduct}Dry`,
        name: this.props.data.nameProduct,
        numProd: this.state.contDry,
        price: this.props.data.priceDry,
        section: this.props.sectionReducer.activeSection.name,
        service: 'Dry',
        total: this.state.contDry * this.props.data.priceDry
      };
    }

    if (productDry) {
      this.addBasket(productDry);
      this.setState({ contDry: 0 });
    }

    if (productWet) {
      this.addBasket(productWet);
      this.setState({ contWet: 0 });
    }

    if (product) {
      this.addBasket(product);
      this.setState({ contDry: 0 });
    }

    const user = localStorage.getUserState();
    if (user && user.token) {
      this.updateOrderTemp(user.idCustomer);
      this.getOrderTemp(user.idCustomer);
      this.getOrderTemp(user.idCustomer);
    }

    this.openModal(
      <ProductAdd
        onClose={this.handleCloseModal}
        viewBasket={() => this.openModal(<Basket />)}
      />
    );
  };

  addBasket = product => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);
    if (basket.content.length) {
      const isInBasket = this.searchProduct(basket, product);
      if (!isInBasket) {
        basket.content.push(product);
      }
    } else {
      basket = {
        content: [product],
        promotion: basket.promotion,
        discount: 0,
        subtotal: 0,
        total: 0
      };
    }
    basket.subtotal = 0;
    basket.content.forEach(item => {
      basket.subtotal += item.total;
    });

    if (basket.promotion.type === 'ORDER') {
      switch (basket.promotion.discountType) {
        case 'PERCENT':
          const percent = parseInt(basket.promotion.discount) / 100;
          const discount = parseFloat((basket.subtotal * percent).toFixed(2));
          basket.discount = discount;
          break;
        default:
          break;
      }
    }

    if (basket.subtotal - basket.discount < 0) {
      basket.total = 0;
    } else {
      basket.total = basket.subtotal - basket.discount;
    }
    this.myStorage.setItem('t2gBasket', JSON.stringify(basket));
    this.props.setBasket(JSON.parse(this.myStorage.t2gBasket).content);
    this.props.setSubtotal(JSON.parse(this.myStorage.t2gBasket).subtotal);
    this.props.setDiscount(JSON.parse(this.myStorage.t2gBasket).discount);
    this.props.setTotal(JSON.parse(this.myStorage.t2gBasket).total);
  };

  updateOrderTemp = userId => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);
    basket.content.forEach(async element => {
      const tempObj = {
        idCustomer: userId,
        idProduct: element.idProduct,
        service: element.service,
        numProd: element.numProd,
        commentsProd: '',
        total: element.total.toString()
      };

      this.setState({ loading: true, error: null });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/orderTemp`,
          tempObj
        );
        this.setState({ loading: false, tempResponse: response.data });
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  getOrderTemp = async userId => {
    let tempData = [];
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orderTemp/customer/${userId}`
      );
      tempData = response.data;
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }

    if (tempData.length) {
      this.props.setBasket([]);
      tempData.forEach(element => {
        let productDry = null;
        let productWet = null;
        let product = null;

        if (element.product.priceWet) {
          if (element.service === 'Dry') {
            productDry = {
              idOrderTemp: element.idOrderTemp,
              displayType: 'Dry',
              idProduct: element.idProduct,
              keyProduct: `${element.idProduct}Dry`,
              name: element.product.nameProduct,
              numProd: parseInt(element.numProd),
              price: parseFloat(element.product.priceDry),
              section: element.section.nameSection,
              service: 'Dry',
              total: parseFloat(element.total)
            };
          }
          if (element.service === 'Wet') {
            productWet = {
              idOrderTemp: element.idOrderTemp,
              displayType: 'Wet',
              idProduct: element.idProduct,
              keyProduct: `${element.idProduct}Wet`,
              name: element.product.nameProduct,
              numProd: parseInt(element.numProd),
              price: parseFloat(element.product.priceWet),
              section: element.section.nameSection,
              service: 'Wet',
              total: parseFloat(element.total)
            };
          }
        } else {
          product = {
            idOrderTemp: element.idOrderTemp,
            displayType: '',
            idProduct: element.idProduct,
            keyProduct: `${element.idProduct}Dry`,
            name: element.product.nameProduct,
            numProd: parseInt(element.numProd),
            price: parseFloat(element.product.priceDry),
            section: element.section.nameSection,
            service: 'Dry',
            total: parseFloat(element.total)
          };
        }

        if (productDry) {
          this.addBasketReducer(productDry);
        }

        if (productWet) {
          this.addBasketReducer(productWet);
        }

        if (product) {
          this.addBasketReducer(product);
        }
      });

      this.setTotal();

      this.setLocalWithReducer();
    }
  };

  addBasketReducer = product => {
    const content = this.props.basketReducer.content;
    content.push(product);
    this.props.setBasket(content);
  };

  setTotal = () => {
    const content = this.props.basketReducer.content;
    let subtotal = 0;

    content.forEach(element => {
      subtotal += element.total;
    });
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);

    if (basket.promotion.type === 'ORDER') {
      switch (basket.promotion.discountType) {
        case 'PERCENT':
          const percent = parseInt(basket.promotion.discount) / 100;
          const discount = parseFloat((subtotal * percent).toFixed(2));
          basket.discount = discount;
          break;
        default:
          break;
      }
    }

    const total = subtotal - basket.discount;
    this.props.setSubtotal(subtotal);
    this.props.setDiscount(basket.discount);
    this.props.setTotal(total);
  };

  setLocalWithReducer = () => {
    this.myStorage.setItem(
      't2gBasket',
      JSON.stringify(this.props.basketReducer)
    );
  };

  searchProduct = (basket, product) => {
    let isInBasket = false;
    basket.content.forEach(item => {
      if (item.keyProduct === product.keyProduct) {
        item.numProd = product.numProd;
        item.total = product.total;
        isInBasket = true;
        return;
      }
    });
    return isInBasket;
  };

  removeDry = () => {
    if (this.state.contDry) {
      this.setState({ contDry: this.state.contDry - 1 });
    }
  };

  removeWet = () => {
    if (this.state.contWet) {
      this.setState({ contWet: this.state.contWet - 1 });
    }
  };

  openModal = component => {
    this.setState({ modalIsOpen: true, modalContent: component });
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    return (
      <>
        <figure className="product">
          <span className="product__name">{this.props.data.nameProduct}</span>
          <div className="product__dry">
            <span>
              {this.props.data.priceWet ? 'DRY:' : ''} $
              {parseFloat(this.props.data.priceDry).toFixed(2)}
            </span>
            <div className="product__quantity">
              <SVGRemove className="product__icon" onClick={this.removeDry} />
              <span className="product__quantity-number">
                {this.state.contDry}
              </span>
              <SVGAdd className="product__icon" onClick={this.addDry} />
            </div>
          </div>
          {this.props.data.priceWet ? (
            <div className="product__wet">
              <span>
                WET: ${parseFloat(this.props.data.priceWet).toFixed(2)}
              </span>
              <div className="product__quantity">
                <SVGRemove className="product__icon" onClick={this.removeWet} />
                <span className="product__quantity-number">
                  {this.state.contWet}
                </span>
                <SVGAdd className="product__icon" onClick={this.addWet} />
              </div>
            </div>
          ) : (
            ''
          )}
          <button onClick={this.addProduct} className="product__button">
            Añadir
          </button>
        </figure>
        {this.state.loading ? (
          <Modal
            isOpen={this.state.modalIsOpen}
            onClose={this.handleCloseModal}
          >
            <Loader />
          </Modal>
        ) : this.state.error ? (
          <Modal
            isOpen={this.state.modalIsOpen}
            onClose={this.handleCloseModal}
          >
            <Error />
          </Modal>
        ) : (
          <Modal
            isOpen={this.state.modalIsOpen}
            onClose={this.handleCloseModal}
          >
            {this.state.modalContent}
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer,
    sectionReducer: reducers.sectionReducer,
    orderObjReducer: reducers.orderObjReducer,
    paymentReducer: reducers.paymentReducer
  };
};

export default connect(mapStateToProps, basketActions)(Product);
