import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as basketActions from '../actions/basketActions';
import Hero from '../components/Hero';
import PromotionsList from '../components/PromotionsList';

class Home extends Component {
  _isMounted = false;
  componentDidMount() {
    this.props.setStep('address');
    this.props.profileMenu(false);
    this.props.setOpenModal(false);
    this._isMounted = true;
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <>
        <Hero />
        <section className="sections">
          <div className="sections__head">
            <p>¡Descubre increíbles descuentos!</p>
            <h1>Promociones</h1>
          </div>
          <PromotionsList />
        </section>
      </>
    );
  }
}

export default connect(null, basketActions)(Home);
