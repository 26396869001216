import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as categoryActions from '../actions/categoryActions';
import Category from './Category';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';
import { ReactComponent as SVGSad } from '../assets/static/icons/sad.svg';
import '../assets/styles/components/CategoryList.scss';

class CategoryList extends Component {
  state = {
    loading: false,
    error: null,
    categoriesData: []
  };

  componentDidMount() {
    this.getCategories(this.props.activeSection.id);
  }

  componentWillReceiveProps(nextProps) {
    this.getCategories(nextProps.activeSection.id);
  }

  getCategories = async idSection => {
    this.setState({ loading: true, error: null });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/section/${idSection}/categories`
      );

      this.setState({
        loading: false,
        categoriesData: response.data.categories
      });
      this.activateCategory();
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  activateCategory = () => {
    this.props.activateCategory(this.state.categoriesData[0].idCategory);
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.error) {
      return <Error vertical={true} />;
    }

    if (!this.state.categoriesData.length) {
      return (
        <div className='categoryList__empty'>
          <SVGSad className='categoryList__empty-icon' />
          <h4 className='categoryList__empty-text'>
            La sección seleccionada está vacía.
          </h4>
        </div>
      );
    }

    return (
      <div className='categoryList'>
        {this.state.categoriesData.map(item => {
          return <Category key={item.idCategory} data={item} />;
        })}
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.sectionReducer;
};

export default connect(mapStateToProps, categoryActions)(CategoryList);
