const INITIAL_STATE = {
  activeSection: {
    id: 1,
    name: 'Tintorería'
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ACTIVATE_SECTION':
      return {
        ...state,
        activeSection: action.payload
      };
    default:
      return state;
  }
};
