import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as orderActions from '../actions/orderActions';
import '../assets/styles/containers/Order.scss';
import Basket from '../components/Basket';
import PaymentSelect from '../components/PaymentSelect';
import PaymentNew from '../components/PaymentNew';
import AddressSelect from '../components/AddressSelect';
import NewAddress from '../components/address/NewAddress';
import Confirm from '../components/Confirm';
import OrderSuccess from '../components/OrderSuccess';

class Order extends Component {
  _isMounted = false;
  componentDidMount() {
    this.props.setOpenModal(false);
    this.props.setStep('address');
    this._isMounted = true;
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="order">
        {this.props.orderStep === 'confirm' ? (
          <Confirm />
        ) : this.props.orderStep === 'success' ? (
          <OrderSuccess />
        ) : (
          <>
            <section className="order__section">
              <div className="order__section__stepper">
                <div
                  className={`order__section__stepper-step ${
                    this.props.orderStep === 'address' ||
                    this.props.orderStep === 'newAddress'
                      ? 'active'
                      : ''
                  }`}
                >
                  Datos de entrega
                </div>
                <div
                  className={`order__section__stepper-step ${
                    this.props.orderStep === 'cardPay' ||
                    this.props.orderStep === 'newCardPay'
                      ? 'active'
                      : ''
                  }`}
                >
                  Forma de pago
                </div>
              </div>
              {this.props.orderStep === 'address' ? (
                <AddressSelect />
              ) : this.props.orderStep === 'newAddress' ? (
                <NewAddress />
              ) : this.props.orderStep === 'cardPay' ? (
                <PaymentSelect />
              ) : this.props.orderStep === 'newCardPay' ? (
                <PaymentNew />
              ) : (
                ''
              )}
            </section>
            <section className="order__section">
              <Basket runway={true} />
            </section>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return reducers.orderReducer;
};

export default connect(mapStateToProps, orderActions)(Order);
