import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as basketActions from '../actions/basketActions';
import '../assets/styles/components/PromotionDetail.scss';
import { ReactComponent as SVGWarning } from '../assets/static/icons/warning.svg';

class PromotionDetail2 extends Component {
  
  render() {
    const br = `\n`;
    const {
      data
    } = this.props;

    if(data.id ==81){
      var names = <>* Limpieza general profunda <br />* Humectación de la piel  <br /> * Desinfección de Calzado  <br />  * Limpieza y Pulido de Suelas </>;
    }else if(data.id ==82){
      var names = <>* Tapa de tacón o sandalia en material correspondiente con el calzado <br />  * Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de suelas  </>;
    }else if(data.id ==83){
      var names = <>* Se cambia el forro del tacón, tapa y pintura en general<br /> * Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de suelas    </>;
    }else if(data.id ==84){

      var names = <>* Protector de suela de goma antiderrapante de alta resistencia y minimo espesor de protege la suela del calzado y evita el paso de humedad al mismo<br />* Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de complemento de suelas     </>;

       

    }else if(data.id ==85){
      var names = <>*Protector de suela doble vida y tapas según la necesidad y tipo de calzado<br />  * Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de suelas           </>
    }else if(data.id ==86){
      var names = <>* Cambio de suela o doble vida, cambio de tapas, plantillas (en caso de requerirse), costura, pintura y hormado<br /> 
      * Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de suelas    <br />           
      *Se Entrega con un par de hormas para su cuidado  </>
    }else if(data.id ==87){
      var names = <>* Se cose o pega la suela, o el corte del calzado según sea la necesidad del mismo <br />   
      * Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de suelas </>
    }else if(data.id ==88){
      var names = <>*Ensanchamiento del calzado segun la necesidad del mismo  <br />  
      * Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de suelas  </>
    }else if(data.id ==89){
      
      var names = <> * Pintura o teñido del calzado según sea el caso <br />  
      * Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de suelas <br />    </>


    }else if(data.id ==90){
      var names = <>  * Limpieza general profunda, humectación de la piel, desinfección, limpieza y pulido de suelas      </>
    }

      return (
        <div className="PromotionDetail">
          <p className="TextoDesc">

            {names}

          </p>
          <button
            onClick={() => {
              this.props.close();
            }}
            className="PromotionDetail__button"
          >
            Aceptar
          </button>
        </div>
      );

  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer
  };
};

export default connect(mapStateToProps, basketActions)(PromotionDetail2);
