import React, { Component } from 'react';
import axios from 'axios';
import { withFormik, Field, ErrorMessage, Form } from 'formik';
import { ReactComponent as SVGResume } from '../assets/static/icons/resume.svg';
import '../assets/styles/components/ProfileEdit.scss';
import Loader from '../layouts/Loader';
import Error from '../layouts/Error';

class ProfileEdit extends Component {
  state = {
    error: null,
    loading: false,
    response: false
  };

  closeModal = () => {
    this.props.handleCloseModal();
  };

  updateUser = async () => {
    const userObj = {
      name: this.props.values.name,
      lastname: this.props.values.lastName,
      email: this.props.values.email,
      telephone: this.props.values.telephone.toString()
    };

    this.setState({ loading: true, error: null });
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/customer/client/${this.props.data.idCustomer}`,
        userObj
      );
      this.setState({ loading: false, response: response });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <div className='profileEdit'>
          <Loader />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className='profileEdit'>
          <Error />
        </div>
      );
    }

    if (!this.state.response) {
      return (
        <div className='profileEdit'>
          <h1 className='profileEdit__title'>Editar perfil</h1>
          <Form>
            <span className='label'>Nombre</span>
            <Field className='field width100' type='text' name='name' />
            <ErrorMessage name='name'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <span className='label'>Apellído</span>
            <Field className='field width100' type='text' name='lastName' />
            <ErrorMessage name='lastName'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <span className='label'>Correo electrónico</span>
            <Field className='field width100' type='email' name='email' />
            <ErrorMessage name='email'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <span className='label'>Teléfono</span>
            <Field
              className='field width100 phone'
              type='number'
              name='telephone'
            />
            <ErrorMessage name='telephone'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <button
              type='button'
              onClick={this.updateUser}
              className={`width100 submit ${
                this.props.isSubmitting || !this.props.isValid ? 'disabled' : ''
              }`}
              disabled={this.props.isSubmitting || !this.props.isValid}
            >
              Guardar cambios
            </button>
          </Form>
        </div>
      );
    }

    if (this.state.response) {
      return (
        <div className='profileEdit'>
          <div className='profileEdit__success'>
            <SVGResume className='profileSuccess-icon' />
            <p>Los datos de tu cuenta han sido actualizados correctamente.</p>
            <button onClick={this.closeModal}>Aceptar</button>
          </div>
        </div>
      );
    }
  }
}

export default withFormik({
  mapPropsToValues(props) {
    return {
      name: props.data.name,
      lastName: props.data.lastname,
      email: props.data.email,
      telephone: props.data.telephone
    };
  },

  validate(values) {
    const errors = {};

    if (!values.name) {
      errors.name = 'Por favor introduzca un nombre';
    }

    if (!values.lastName) {
      errors.lastName = 'Por favor introduzca un apellído.';
    }

    if (!values.email) {
      errors.email = 'Por favor introduzca un correo electrónico.';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Correo electrónico inválido.';
    }

    if (!values.telephone) {
      errors.telephone = 'Por favor introduzca un teléfono.';
    } else if (
      !/(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/.test(values.telephone)
    ) {
      errors.telephone = 'Número inválido.';
    }

    return errors;
  }
})(ProfileEdit);
