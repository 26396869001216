import React, { Component } from 'react';
import axios from 'axios';
import * as localStorage from '../localStorage';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';
import { ReactComponent as SVGEmpty } from '../assets/static/icons/orderEmpty.svg';
import '../assets/styles/components/OrderList.scss';
import OrderItem from './OrderItem';

class OrderList extends Component {
  state = {
    loading: false,
    error: null,
    ordersData: []
  };

  getOrders = async () => {
    const user = localStorage.getUserState();

    this.setState({ loading: true, error: null });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/customer/${user.idCustomer}`
      );
      this.setState({ loading: false, ordersData: response.data });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  componentDidMount() {
    this.getOrders();
  }

  render() {
    return (
      <section className='orderList'>
        {this.state.loading ? (
          <Loader />
        ) : this.state.error ? (
          <Error />
        ) : !this.state.ordersData.length ? (
          <>
            <SVGEmpty />
            <br />
            <h4>No cuentas con ningún pedido.</h4>
          </>
        ) : (
          <>
            <h1 className='orderList__title'>Mis pedidos</h1>
            <div className='orderList__container'>
              {this.state.ordersData.map(item => {
                return (
                  <OrderItem
                    key={item.idOrder}
                    data={item}
                    reload={this.getOrders}
                  />
                );
              })}
            </div>
          </>
        )}
      </section>
    );
  }
}

export default OrderList;
