import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as basketActions from '../actions/basketActions';
import '../assets/styles/containers/Profile.scss';
import Hero from '../components/Hero';
import Navbar from '../components/profile/navbar';
import Addresses from '../components/address/Addresses';
import MyProfile from '../components/profile/myprofile';
import Payments from '../components/profile/payments';
import OrderList from '../components/OrderList';

class Profile extends Component {
  state = {
    viewName: 'addresses'
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.setStep('address');
    this.props.profileMenu(false);
    window.scrollTo(0, 0);
  }

  changeView = viewName => {
    this.setState({
      viewName
    });
  };

  getView = () => {
    switch (this.state.viewName) {
      case 'address':
        return <Addresses />;
      case 'profile':
        return <MyProfile />;
      case 'payment':
        return <Payments />;
      default:
        return <OrderList />;
    }
  };

  render() {
    return (
      <>
        <Hero />
        <section className='sections'>
          <div className='sections__head'>
            <h1>Mi perfil</h1>
          </div>
        </section>
        <section className='flexStaSta'>
          <Navbar changeView={viewName => this.changeView(viewName)} />

          {this.getView()}
        </section>
      </>
    );
  }
}

export default connect(null, basketActions)(Profile);
