import React from 'react';
import privacyImg from '../assets/static/images/privacy.png';
import '../assets/styles/components/Terms.scss';

const Privacy = () => {
	window.scrollTo(0, 0);
	return (
		<section className="conditions">
			<h2 className="conditions__title">Aviso de Privacidad</h2>
			<article>
				<strong>¿QUIÉNES SOMOS?</strong>
				<p>
					Servicios de Tintorería y Logística S.A.P.I. de C.V., (en lo sucesivo
					“TIN2GO») con domicilio en Lago zurich 168 Colonia Ampliación Granada
					C.P. 11520 Interior PB Local C, Ciudad de México, México, le hace
					saber a los usuarios del sitio Web www.tin2go.mx, y aplicación móvil
					TIN2GO, que TIN2GO obtiene sus datos personales, en virtud del llenado
					de los formularios del sitio, que usted realiza, su contacto directo
					vía correo electrónico, redes sociales, o aplicación móvil, la
					contratación de algún servicio vía web o app. Por lo que, en términos
					de lo señalado por la Ley Federal de Protección de Datos Personales en
					Posesión de los Particulares (la “Ley”), manifiesta que los datos
					personales que sean proporcionados a TIN2GO tendrán un tratamiento
					legítimo, controlado e informado, a efecto de garantizar la privacidad
					y el derecho a la autodeterminación de los Usuarios.
				</p>
				<strong>QUE DATOS RECABAMOS y PARA QUE LOS UTILIZAMOS</strong>
				<p>
					Recuerde que usted es responsable de enviar o completar los datos
					personales a través de las solicitudes, registros, formularios,
					formatos, correos electrónicos que al efecto se soliciten o pongan a
					disposición a través de este sitio Web, redes sociales o app móvil,
					así como de forma telefónica. TIN2GO podrá solicitar y tratar los
					siguientes datos dependiendo la finalidad:
				</p>
				<img className="img100" src={privacyImg} alt="Table" />
				<br />
				<br />
				<strong>Aviso de Privacidad</strong>
				<p>
					Usted puede oponerse a estas finalidades en cualquier momento sin que
					por ello afecte el cumplimiento a las otras finalidades o relación
					jurídica con usted. TIN2GO en ningún momento recabará información que
					sea considerada como Datos Personales Sensibles. Así mismo hacemos de
					su conocimiento que no toda la Información que se solicita es
					obligatoria, sino únicamente aquella que así se le haga saber a través
					de las solicitudes, registros, formularios, formatos, contratos o
					correos electrónicos a través de la cual se recabe la información.
				</p>
				<strong>COOKIES y WEB BEACONS</strong>
				<p>
					Las cookies son pequeños archivos de texto que un servidor de web
					envía a la computadora del Usuario al entrar al sitio Web de TIN2GO,
					mismas que sirven para poder brindarle una mejor atención respecto de
					nuestros productos y/o servicios. Las cookies de una sesión recuerdan
					la actividad que usted llevó a cabo anteriormente en el sitio web de
					TIN2GO. Las cookies persistentes incluso hacen posible guardar sus
					preferencias en las diversas sesiones en que visite el sitio web, lo
					cual nos permite personalizar, por ejemplo, la manera en que dicho
					sitio ponga a disposición del Usuario la información de su interés.
					Así mismo, las cookies pueden utilizarse para evitar que mostremos
					avisos, noticias y/o recomendaciones que pudieran interesarle, de
					conformidad con sus actividades anteriores en nuestro sitio web. Las
					cookies también se usan para implementar estrategias que nos permitan
					elevar la calidad de nuestros productos y/o servicios. Los contadores
					de visita o web beacons, son por lo general imágenes gráficas que se
					ponen en un sitio web y se utilizan para contar el número de
					visitantes a un sitio web o incluso pueden permitir el acceso a
					algunas cookies. El uso de beacons en nuestro sitio web tiene la
					finalidad de reflejar estadísticas respecto de los productos y/o
					servicios que son de su interés. Estos contadores de visita
					normalmente no recopilan información diferente de la que el navegador
					proporciona como parte estándar de cualquier navegación en internet.
					Si Usted eligiera la opción de desactivación de las cookies en su
					explorador de internet, el contador de visitas no podrá seguir
					rastreando específicamente su actividad. Algunos sitios web o
					aplicaciones de nuestra página web podrán utilizar objetos almacenados
					localmente, como son las cookies de Flash o almacenamiento local con
					HTML5. Este tipo de objetos almacenados localmente se utilizan para
					fines similares a los de las cookies pero generalmente pueden contener
					mayor cantidad de datos diferentes a los de las cookies del navegador.
					Usted puede modificar la configuración de su reproductor Flash
					mediante el Administrador de configuración de conformidad con su
					preferencia sobre el almacenamiento de objetos locales compartidos,
					inclusive desactivar objetos locales compartidos solamente para
					determinados sitios web o desactivar totalmente el almacenamiento de
					objetos locales compartidos para todos los sitios web. La mayoría de
					los navegadores o exploradores de internet le permiten desactivar o
					permitir el uso de cookies. Usted también puede eliminar las cookies
					de su computadora si su navegador se lo permite. Si desactiva las
					cookies, es posible que no pueda usar determinadas partes de nuestro
					sitio web y que sea necesario reinstalar una cookie de rechazo. En
					todo caso, Usted contará con diversas opciones para limitar el acceso
					de las cookies y web beacons de nuestro sitio web en su computadora.
				</p>
				<strong>COMO LOS PROTEGEMOS</strong>
				<p>
					TIN2GO utiliza la tecnología más avanzada a su alcance para proteger
					los Datos Personales proporcionados. Esta tecnología, en el caso del
					sitio Web, y app móvil almacena de forma segura y previene la
					intercepción de los Datos Personales suministrados. TIN2GO, a través
					de la tecnología más avanzada a su alcance, establecerá medidas de
					seguridad administrativas, técnicas y físicas que permitan proteger
					los datos personales contra daño, pérdida, alteración, destrucción, o
					el uso, acceso o tratamiento no autorizado.
				</p>
				<strong>CON QUIENES LOS COMPARTIMOS</strong>
				<p>
					Para cumplir las finalidades descritas sus datos podrán ser remitidos
					a proveedores, franquicitarios, partes relacionadas o socios
					comerciales de TIN2GO, ya sean nacionales o extranjeros, los cuales,
					en su caso, destinarán los datos personales a las mismas finalidades
					descritas en el presente Aviso de Privacidad. El consentimiento
					expreso y/o por escrito para el tratamiento de los datos o
					transferencias que así lo requieran será recabado a través de las
					solicitudes, registros, formatos, contratos o cualquier otro medio que
					para el efecto establezca TIN2GO. Las remisiones para las finalidades
					aquí señaladas no requieren de su consentimiento por situarse dentro
					de las excepciones del artículo 37 de la Ley, sin embargo, es muy
					importante para TIN2GO, que los usted las conozca. Asimismo, le
					informamos que si usted decide adherirse al “Programa de Lealtad
					PAYBACK”, TIN2GO podrá transferir, sin requerir de su consentimiento,
					sus datos personales de identificación y contacto a PAYBACK MÉXICO S.
					de R.L. de C.V. (“PAYBACK”) para que éste le pueda contactar,
					inscribirle al Programa de Lealtad PAYBACK y para la operación y
					funcionamiento de dicho Programa.
				</p>
				<strong>SUS DERECHOS</strong>
				<p>
					Usted tendrá derecho a ejercer en todo momento su derecho de acceso,
					rectificación, cancelación u oposición, así como revocar su
					consentimiento según lo establecido en la Ley y en el presente Aviso
					de Privacidad. Para ejercitar dichos derechos deberán hacer
					solicitarlo a: Lago zurich 168 Torre B Num. Int. PB Local C Colonia
					Ampliación Granda, Delegación Miguel Hidalgo, C.P. 11520, Ciudad de
					México, México, E-mail: datospersonales@tin2go.com TIN2GO dará
					respuesta a las solicitudes para el ejercicio de los derechos a que se
					refiere el presente Aviso de Privacidad, en un máximo de 20 días
					hábiles o en su caso notificará a usted la ampliación de este término
					cuando existan causas que lo justifiquen. Para la atención de
					cualquiera de los derechos o la revocación del consentimiento será
					necesario: Se acredite la identidad del titular o su representante
					legal con copia de identificación oficial, y documento donde consten
					las facultades del representante. Se adjunte a la solicitud los
					requisitos del artículo 29 de la Ley. El medio por el cual requiere se
					le notifique, obtenga información y de respuesta a su solicitud de
					entre los cuales podrá elegir: a) vía correo electrónico (mensajes de
					datos) o; b) vía física (copias simples); en este último caso el
					titular o su representante deberán recoger personalmente las
					notificaciones en las instalaciones de TIN2GO. Para el caso del
					derecho de acceso TIN2GO podrá poner a su disposición copias simples,
					certificadas, archivos de audio, video, imágenes o mensajes de datos
					según corresponda. Usted en cualquier momento puede limitar el uso o
					transferencia de sus datos informando TIN2GO dicha circunstancia, sin
					embargo, estos datos son necesarios para las finalidades descritas en
					el presente Aviso de Privacidad por lo que al no contar con ellos o no
					permitir su obtención, impediría estar en condiciones para cumplir las
					finalidades. Para el caso de las finalidades de publicidad y
					mercadotecnia el Usuario puede, adicional a lo contenido en el párrafo
					anterior, inscribirse en el Registro Público de Consumidores previsto
					en la Ley Federal de Protección al Consumidor.
				</p>
				<p>
					TIN2GO mantiene revisiones y actualizaciones constantes al presente
					Aviso de Privacidad, por lo que es necesario reservarse sus
					modificaciones. Su última actualización fue realizada: el 1 de
					Septiembre del 2017. Cualquier modificación al presente Aviso de
					Privacidad será publicada y estará disponible publicado en la sitio
					Web y en la App Móvil de TIN2GO y podrá ser solicitada en cualquier
					momento por usted ante TIN2GO SI TIENE ALGUNA DUDA O PREGUNTA RESPECTO
					AL PRESENTE AVISO DE PRIVACIDAD, POR FAVOR CONTÁCTENOS EN LA SIGUIENTE
					DIRECCIÓN: servicioalcliente@tin2go.com
				</p>
			</article>
		</section>
	);
};

export default Privacy;
