import React, { Component } from 'react';
import '../assets/styles/components/Categories.scss';
import CategoryList from './CategoryList';

class Categories extends Component {
	render() {
		return (
			<section className="categories">
				<CategoryList />
			</section>
		);
	}
}

export default Categories;
