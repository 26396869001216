const INITIAL_STATE = {
  content: [],
  promotion: {},
  subtotal: 0,
  discount: 0,
  total: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_BASKET':
      return {
        ...state,
        content: action.payload
      };
    case 'SET_PROMOTION':
      return {
        ...state,
        promotion: action.payload
      };
    case 'SET_DISCOUNT':
      return {
        ...state,
        discount: action.payload
      };
    case 'SET_SUBTOTAL':
      return {
        ...state,
        subtotal: action.payload
      };
    case 'SET_TOTAL':
      return {
        ...state,
        total: action.payload
      };
    default:
      return state;
  }
};
