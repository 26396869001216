import axios from 'axios';



class AuthService {

    login(user) {
        return new Promise(async(resolve, reject) => {

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/customer/login`, user
                );
                resolve(response);
            } catch (error) {
                reject(error);
                console.log(error);
            }

        });

    }

    forgotPassword(email) {
        return new Promise(async(resolve, reject) => {

            try {
                const obj = {
                    redirectTo: `${window.location.host}/cambiar_contasena`,
                    email
                };

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/customer/forgotPassword`, obj
                );
                resolve(response);
            } catch (error) {
                reject(error);
                console.log(error);
            }

        });
    }

    resetPassword(token, password) {
        return new Promise(async(resolve, reject) => {

            try {
                const obj = {
                    password,
                    token
                };

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/customer/resetPassword`, obj
                );
                resolve(response);
            } catch (error) {
                reject(error);
                console.log(error);
            }

        });
    }
}

const authService = new AuthService();

export default authService;