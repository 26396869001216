export const setStep = step => dispatch => {
  dispatch({
    type: 'SET_STEP',
    payload: step
  });
};
export const setOrderDateRecovery = dateRecovery => dispatch => {
  dispatch({
    type: 'SET_ORDER_DATE_RECOVERY',
    payload: dateRecovery
  });
};
export const setOrderDateDelivery = dateDelivery => dispatch => {
  dispatch({
    type: 'SET_ORDER_DATE_DELIVERY',
    payload: dateDelivery
  });
};
export const setOrderAddressRecovery = addressRecovery => dispatch => {
  dispatch({
    type: 'SET_ORDER_ADDRESS_RECOVERY',
    payload: addressRecovery
  });
};
export const setOrderAddressDelivery = addressDelivery => dispatch => {
  dispatch({
    type: 'SET_ORDER_ADDRESS_DELIVERY',
    payload: addressDelivery
  });
};
export const setPaymentPayCard = idPayCard => dispatch => {
  dispatch({
    type: 'SET_PAYMENT_PAY_CARD',
    payload: idPayCard
  });
};
export const setPaymentLastFour = lastFour => dispatch => {
  dispatch({
    type: 'SET_PAYMENT_LAST_FOUR',
    payload: lastFour
  });
};
export const setAddressRecovery = addressRecovery => dispatch => {
  dispatch({
    type: 'SET_ADDRESS_RECOVERY',
    payload: addressRecovery
  });
};
export const setAddressDelivery = addressDelivery => dispatch => {
  dispatch({
    type: 'SET_ADDRESS_DELIVERY',
    payload: addressDelivery
  });
};
export const setBasket = content => dispatch => {
  dispatch({
    type: 'SET_BASKET',
    payload: content
  });
};
export const setSubtotal = subtotal => dispatch => {
  dispatch({
    type: 'SET_SUBTOTAL',
    payload: subtotal
  });
};
export const setDiscount = discount => dispatch => {
  dispatch({
    type: 'SET_DISCOUNT',
    payload: discount
  });
};
export const setPromotion = promotion => dispatch => {
  dispatch({
    type: 'SET_PROMOTION',
    payload: promotion
  });
};
export const setTotal = total => dispatch => {
  dispatch({
    type: 'SET_TOTAL',
    payload: total
  });
};
export const setExecute = boolean => dispatch => {
  dispatch({
    type: 'SET_EXECUTE',
    payload: boolean
  });
};
export const resetOrder = () => dispatch => {
  dispatch({
    type: 'RESET_ORDER',
    payload: null
  });
};
export const setOpenModal = state => dispatch => {
  dispatch({
    type: 'SET_OPEN_MODAL',
    payload: state
  });
};
