export const setBasket = content => dispatch => {
  dispatch({
    type: 'SET_BASKET',
    payload: content
  });
};
export const setPromotion = promotion => dispatch => {
  dispatch({
    type: 'SET_PROMOTION',
    payload: promotion
  });
};
export const setDiscount = discount => dispatch => {
  dispatch({
    type: 'SET_DISCOUNT',
    payload: discount
  });
};
export const setSubtotal = subtotal => dispatch => {
  dispatch({
    type: 'SET_SUBTOTAL',
    payload: subtotal
  });
};
export const setTotal = total => dispatch => {
  dispatch({
    type: 'SET_TOTAL',
    payload: total
  });
};
export const setStep = step => dispatch => {
  dispatch({
    type: 'SET_STEP',
    payload: step
  });
};
export const profileMenu = state => dispatch => {
  dispatch({
    type: 'PROFILE_MENU',
    payload: state
  });
};
export const setOpenModal = state => dispatch => {
  dispatch({
    type: 'SET_OPEN_MODAL',
    payload: state
  });
};
