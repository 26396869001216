import React from 'react';
import Content from './Content';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Layout = ({ children }) => (
  <>
    <Header />
    <Content>{children}</Content>
    <Footer />
  </>
);

export default Layout;
