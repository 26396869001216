import React, { Component } from 'react';
import axios from 'axios';
import { withFormik, Field, ErrorMessage, Form } from 'formik';
import '../assets/styles/components/BillForm.scss';
import { ReactComponent as SVGSuccess } from '../assets/static/icons/success.svg';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';

class BillForm extends Component {
  state = {
    loading: false,
    error: null,
    response: false,
    cfdiUses: []
  };

  billOrder = async () => {
    const billData = {
      address: {
        street: this.props.values.street,
        extNumber: this.props.values.numExt,
        colony: this.props.values.colony,
        municipality: this.props.values.municipality,
        CP: this.props.values.postalCode,
        state: this.props.values.state
      },
      RFC: this.props.values.rfc,
      nameFiscal: this.props.values.name,
      mailFiscal: this.props.values.email,
      description: 'Factura de Tin2Go',
      paymentForm: '28',
      paymentMethod: 'PUE',
      serie: 'A',
      numberToTicket: '',
      cfdiUse: 'P01',
      orders: [this.props.data.idOrder]
    };

    this.setState({ loading: true, error: null });
    try {
      console.log(billData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/billing`,
        billData
      );
      this.setState({ loading: false, response: response });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  close = () => {
    this.props.close();
  };

  render() {
    if (this.state.loading) {
      return (
        <div className='billForm'>
          <Loader />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className='billForm'>
          <Error />
        </div>
      );
    }

    if (this.state.response) {
      return (
        <div className='billForm'>
          <SVGSuccess />
          <br />
          <h2 className='success__title'>Pedido facturado con éxito</h2>
          <button onClick={this.close}>Aceptar</button>
        </div>
      );
    }

    if (!this.state.response) {
      return (
        <div className='billForm'>
          <h1>Facturar pedido: {this.props.data.idOrder}</h1>
          <Form>
            <span className='label'>Nombre o razón social</span>
            <Field className='field width100' type='text' name='name' />
            <ErrorMessage name='name'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <br />
            <span className='label'>RFC</span>
            <Field className='field width100' type='text' name='rfc' />
            <ErrorMessage name='rfc'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <br />
            <span className='label'>Correo electrónico</span>
            <Field className='field width100' type='email' name='email' />
            <ErrorMessage name='email'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <br />
            <span className='label'>Calle</span>
            <Field className='field width100' type='text' name='street' />
            <ErrorMessage name='street'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <br />
            <span className='label'>Número exterior</span>
            <Field className='field width100' type='text' name='numExt' />
            <ErrorMessage name='numExt'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <br />
            <span className='label'>Colonia</span>
            <Field className='field width100' type='text' name='colony' />
            <ErrorMessage name='colony'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <br />
            <span className='label'>Alcaldía o municipio</span>
            <Field className='field width100' type='text' name='municipality' />
            <ErrorMessage name='municipality'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <br />
            <span className='label'>Código postal</span>
            <Field className='field width100' type='text' name='postalCode' />
            <ErrorMessage name='postalCode'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <br />
            <span className='label'>Estado</span>
            <Field className='field width100' type='text' name='state' />
            <ErrorMessage name='state'>
              {message => <div className='inputError'>{message}</div>}
            </ErrorMessage>
            <button
              type='button'
              onClick={this.billOrder}
              className={`width100 submit ${
                this.props.isSubmitting || !this.props.isValid ? 'disabled' : ''
              }`}
              disabled={this.props.isSubmitting || !this.props.isValid}
            >
              Facturar pedido
            </button>
          </Form>
        </div>
      );
    }
  }
}

export default withFormik({
  mapPropsToValues(props) {
    return {
      name: '',
      rfc: '',
      email: '',
      street: '',
      numExt: '',
      colony: '',
      municipality: '',
      postalCode: '',
      state: ''
    };
  },

  validate(values) {
    const errors = {};

    if (!values.name) {
      errors.name = 'Por favor introduzca un nombre o razón social.';
    }

    if (!values.rfc) {
      errors.rfc = 'Por favor introduzca un RFC.';
    } else if (
      !/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/.test(
        values.rfc
      )
    ) {
      errors.rfc = 'RFC inválido.';
    }

    if (!values.email) {
      errors.email = 'Por favor introduzca un correo electrónico.';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Correo electrónico inválido.';
    }

    if (!values.street) {
      errors.street = 'Por favor introduzca una calle.';
    }

    if (!values.numExt) {
      errors.numExt = 'Por favor introduzca un número exterior.';
    }

    if (!values.colony) {
      errors.colony = 'Por favor introduzca una colonia.';
    }

    if (!values.municipality) {
      errors.municipality = 'Por favor introduzca una alcaldía o municipio.';
    }

    if (!values.postalCode) {
      errors.postalCode = 'Por favor introduzca un código postal.';
    }

    if (!values.state) {
      errors.state = 'Por favor introduzca un estado.';
    }

    return errors;
  }
})(BillForm);
