import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import { createBrowserHistory } from 'history';
import { getStorageState, setStorageState } from './localStorage';
import { routerMiddleware } from 'react-router-redux';

const storageState = getStorageState();
const browserHistory = createBrowserHistory();
const routingMiddleware = routerMiddleware(browserHistory);

const store = createStore(
    reducers,
    storageState,
    applyMiddleware(reduxThunk, routingMiddleware)
);

store.subscribe(() => {
    setStorageState(store.getState());
});

export default store;