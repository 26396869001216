import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as basketActions from '../actions/basketActions';
import * as localStorage from '../localStorage';
import '../assets/styles/components/Header.scss';
import basketIcon from '../assets/static/icons/basket.svg';
import logo from '../assets/static/images/logo.png';
import userIcon from '../assets/static/icons/user.svg';
import ModalHeader from '../layouts/ModalHeader';
import Basket from './Basket';
import { createBrowserHistory } from 'history';

class Header extends Component {
  state = {
    modalContent: null,
    loading: true,
    error: null,
    productsData: []
  };
  myStorage = window.localStorage;
  width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );

  constructor() {
    super();
    this.createBasket();
  }

  componentDidMount() {
    this.getBasket();
  }

  addBasket = product => {
    const content = this.props.basketReducer.content;
    content.push(product);
    this.props.setBasket(content);
  };

  changeProfileMenu = () => {
    const user = localStorage.getUserState();
    if (user && user.token) {
      if (this.props.headerReducer.profileMenu) {
        this.props.profileMenu(false);
      } else {
        this.props.profileMenu(true);
      }
    } else {
      const browserHistory = createBrowserHistory({
        forceRefresh: true
      });
      browserHistory.push('/login');
    }

    if (this.props.headerReducer.profileMenu) {
      this.props.profileMenu(false);
    } else {
      this.props.profileMenu(true);
    }
  };

  closeUser = () => {
    this.myStorage.removeItem('state');
    this.resetLocalBasket();
    const browserHistory = createBrowserHistory({
      forceRefresh: true
    });
    browserHistory.push('/');
  };

  createBasket = () => {
    const basket = this.myStorage.getItem('t2gBasket');
    if (!basket) {
      this.resetLocalBasket();
    }
  };

  getBasket = () => {
    const user = localStorage.getUserState();
    if (user && user.token) {
      this.updateOrderTemp(user.idCustomer);
      this.getOrderTemp(user.idCustomer);
      this.getOrderTemp(user.idCustomer);
    } else {
      this.setReducerWithLocal();
    }
  };

  getOrderTemp = async userId => {
    this.setState({ loading: true, error: null });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orderTemp/customer/${userId}`
      );
      this.setState({ loading: false, tempData: response.data });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }

    if (this.state.tempData.length) {
      this.props.setBasket([]);
      this.state.tempData.forEach(element => {
        let productDry = null;
        let productWet = null;
        let product = null;

        if (element.product.priceWet) {
          if (element.service === 'Dry') {
            productDry = {
              idOrderTemp: element.idOrderTemp,
              displayType: 'Dry',
              idProduct: element.idProduct,
              keyProduct: `${element.idProduct}Dry`,
              name: element.product.nameProduct,
              numProd: parseInt(element.numProd),
              price: parseFloat(element.product.priceDry),
              section: element.section.nameSection,
              service: 'Dry',
              total: parseFloat(element.total)
            };
          }
          if (element.service === 'Wet') {
            productWet = {
              idOrderTemp: element.idOrderTemp,
              displayType: 'Wet',
              idProduct: element.idProduct,
              keyProduct: `${element.idProduct}Wet`,
              name: element.product.nameProduct,
              numProd: parseInt(element.numProd),
              price: parseFloat(element.product.priceWet),
              section: element.section.nameSection,
              service: 'Wet',
              total: parseFloat(element.total)
            };
          }
        } else {
          product = {
            idOrderTemp: element.idOrderTemp,
            displayType: '',
            idProduct: element.idProduct,
            keyProduct: `${element.idProduct}Dry`,
            name: element.product.nameProduct,
            numProd: parseInt(element.numProd),
            price: parseFloat(element.product.priceDry),
            section: element.section.nameSection,
            service: 'Dry',
            total: parseFloat(element.total)
          };
        }

        if (productDry) {
          this.addBasket(productDry);
        }

        if (productWet) {
          this.addBasket(productWet);
        }

        if (product) {
          this.addBasket(product);
        }
      });

      this.setTotal();

      this.setLocalWithReducer();
    }
  };

  handleCloseModal = () => {
    this.props.setOpenModal(false);
    this.setState({ modalContent: null });
  };

  openBasket = () => {
    this.openModal();
  };

  openModal = component => {
    if (window.location.pathname === '/orden') {
      return;
    }
    this.props.setOpenModal(true);
    this.setState({ modalContent: component });
  };

  resetLocalBasket = () => {
    this.myStorage.setItem(
      't2gBasket',
      JSON.stringify({
        content: [],
        promotion: {},
        subtotal: 0,
        discount: 0,
        total: 0
      })
    );
  };

  setLocalWithReducer = () => {
    this.myStorage.setItem(
      't2gBasket',
      JSON.stringify(this.props.basketReducer)
    );
  };

  setReducerWithLocal = () => {
    const localBasket = this.myStorage.getItem('t2gBasket');
    const JSONBasket = JSON.parse(localBasket);
    this.props.setBasket(JSONBasket.content);
    this.props.setSubtotal(JSONBasket.subtotal);
    this.props.setDiscount(JSONBasket.discount);
    this.props.setTotal(JSONBasket.total);
  };

  setTotal = () => {
    const content = this.props.basketReducer.content;
    let total = 0;

    content.forEach(element => {
      total += element.total;
    });

    this.props.setSubtotal(total);
    total -= this.props.basketReducer.discount;
    this.props.setTotal(total);
  };

  updateOrderTemp = userId => {
    let basket = this.myStorage.getItem('t2gBasket');
    basket = JSON.parse(basket);
    basket.content.forEach(async element => {
      const tempObj = {
        idCustomer: userId,
        idProduct: element.idProduct,
        service: element.service,
        numProd: element.numProd,
        commentsProd: '',
        total: element.total.toString()
      };

      this.setState({ loading: true, error: null });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/orderTemp`,
          tempObj
        );
        this.setState({ loading: false, tempResponse: response.data });
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  render() {
    if (this.width <= 425) {
      return '';
    }

    return (
      <>
        <header>
          <div className="header">
            <div className="header__menu">
              {/* <img
                className='header__menu-icon headerIcon'
                src={menuIcon}
                alt='Menu Icon'
              /> */}
              <Link to="/">
                <img
                  className="header__menu-img"
                  src={logo}
                  alt="Tin2Go logo"
                />
              </Link>
            </div>
            <div className="header__options">
              <Link to="/promociones" className="header__options-item">
                <strong>Promociones</strong>
              </Link>
              <div
                className="header__options-user header__options-item"
                onClick={this.changeProfileMenu}
              >
                <img className="headerIcon" src={userIcon} alt="User icon" />
              </div>
              <div
                className={`header__profileMenu ${
                  this.props.headerReducer.profileMenu ? 'active' : ''
                }`}
              >
                <ul>
                  <li>
                    <Link to="/perfil">Mi perfil</Link>
                  </li>
                  <li onClick={this.closeUser}>Cerrar sesión</li>
                </ul>
              </div>
              <div
                onClick={() => this.openModal(<Basket runway={false} />)}
                className="header__options-basket header__options-item"
              >
                <img
                  className={`headerIcon header__options-basket-img ${
                    this.props.basketReducer.content.length ? 'active' : ''
                  }`}
                  src={basketIcon}
                  alt="Basket icon"
                />
                {this.props.basketReducer.content.length ? (
                  <figure className="header__options-basket-cont">
                    {this.props.basketReducer.content.length}
                  </figure>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </header>
        <ModalHeader
          isOpen={this.props.modalReducer.isOpen}
          onClose={this.handleCloseModal}
        >
          {this.state.modalContent}
        </ModalHeader>
      </>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer,
    headerReducer: reducers.headerReducer,
    modalReducer: reducers.modalReducer
  };
};

export default connect(mapStateToProps, basketActions)(Header);
