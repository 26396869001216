import React, { Component } from 'react';
import axios from 'axios';
import '../assets/styles/components/PromotionsList.scss';
import Error from '../layouts/Error';
import Loader from '../layouts/Loader';
import Promotion from './Promotion';

class PromotionsList extends Component {
  state = {
    loading: false,
    error: null,
    data: []
  };

  getPromotions = async () => {
    this.setState({ loading: true, error: false });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/promotions`
      );
      this.setState({ loading: false, data: response.data });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  componentDidMount() {
    this.getPromotions();
  }

  render() {
    const { loading, error, data } = this.state;

    if (loading) {
      return (
        <section className="PromotionsList">
          <Loader />
        </section>
      );
    }

    if (error) {
      return (
        <section className="PromotionList">
          <Error />
        </section>
      );
    }

    if (!data.length) {
      return (
        <section className="PromotionList">
          <h2>Por el momento no existen promociones.</h2>
        </section>
      );
    }

    return (
      
      <section className="PromotionsList">
      {data.map(promotion => (
        <Promotion key={promotion.idPromotion} data={promotion} />
      ))}
    </section>

      
     
    );
  }
}

export default PromotionsList;
