import React from 'react';
import '../assets/styles/layouts/Loader.scss';

const Loader = () => (
  <div className='loader'>
    <div className='lds-dual-ring'></div>
  </div>
);

export default Loader;
