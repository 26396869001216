import React from 'react';
import '../assets/styles/components/Terms.scss';

const Adherence = () => {
	window.scrollTo(0, 0);
	return (
		<section className="conditions">
			<h2 className="conditions__title">
				PRESTACIÓN DE SERVICIOS DE TINTORERIA Y LAVANDERÍA
			</h2>
			<article className="conditions__text">
				<strong>
					CONTRATO DE SERVICIOS QUE CELEBRAN EL PRESTADOR DEL SERVICIO Y EL
					CONSUMIDOR CUYOS NOMBRES Y DATOS CONSTAN EN EL ANVERSO DE ESTE
					DOCUMENTO COMO PARTE INTEGRAL DEL MISMO SUJETANDOSE AL TENOR DE LAS
					SIGUIENTES CLAUSULAS.
				</strong>
				<p>
					1ª.- El objeto de éste contrato es el lavado y planchado y/o lavado y
					secado de alfombras, muebles, y/o arreglo de prendas de acuerdo a lo
					especificado en el anverso.
				</p>
				<p>
					2ª.- Deberán quedar especificados en la orden de servicio a) la fecha
					de entrega b) cualquier deterioro que tenga la prenda incluyendo
					adornos y aplicaciones c) cualquier riesgo que corra la prenda por el
					proceso d) el proceso a que será sometido la prenda cuando ésta no
					tenga etiqueta de instrucciones de limpieza e) las manchas o
					decoloraciones visibles y f) por lo que se refiere a las prendas que
					tengan el carácter de especiales, importadas o de alta costura su
					valor estimado, características que guarda, servicio solicitado,
					precio del servicio, limite de garantía, fecha de entrega y riesgo que
					corren, serán convenidos previamente y por escrito entre el consumidor
					y el prestador de servicio en el anverso de este contrato. Todo tipo
					de cortinas y prendas que por su naturaleza sean expuestas al sol o a
					la intemperie no serán objeto de garantía, dado que las prendas u
					objetos expuestos al sol por largos periodos de tiempo hace que
					pierdan su propiedades originales y el color de las mismas, lo cual
					quedará por escrito en la orden de servicio.
				</p>
				<p>
					3ª.- Las prendas chicas que se reciben a granel no se revisarán
					excepto cobertores, edredones, sabanas, colchas etc., en cuyo caso se
					hará la observación de las condiciones en que se recibe, fijando el
					límite de garantía o excusarse del servicio. Todo tipo de cortinas y
					prendas que por su naturaleza sean expuestas al sol o intemperie no
					serán objeto de garantía, lo cual quedará por escrito en el anverso
					del contrato. El mismo procedimiento operará en caso de prendas que
					por su valor o características tengan el carácter de especiales o de
					alta costura a) las prendas que se consideran como especiales son
					aquellas que contengan pedrería o aplicaciones que puedan correr el
					riesgo de desprenderse al momento de llevar a cabo el servicio, así
					mismo las chamarras de piel y de gamuza pues por sus características
					son susceptibles a sufrir daños y aquellas que contenga aplicaciones
					de vinil sintéticas b) las prendas de alta costura no serán objeto de
					garantía si por tratarse de una prenda de diseñador no contenga las
					instrucciones de lavado.
				</p>
				<p>
					4ª.-El prestador de servicio no está obligado a eliminar las manchas
					que por su naturaleza sean insolubles. Así mismo no será responsable
					de las manchas no perceptibles a la vista al momento de la recepción
					de la (s) prenda (s) y que surgieran durante su proceso de lavado y/o
					planchado, tales como residuos de perfume, ácidos, desodorantes o
					exudación. En el caso de servicio exclusivamente de planchado el
					prestador del servicio no se obliga a restablecer la apariencia
					original de la (s) prenda (s) en lo que se refiere a tersura y/o
					tamaño.
				</p>
				<p>
					5ª.-El prestador de servicio se obliga a) revisar el estado que guarda
					la prenda, haciendo ver al consumidor cualquier deterioro o riesgo en
					el proceso de limpieza, fijando el límite de garantía o excusarse de
					hacer el servicio b) seguir las instrucciones de lavado y/o planchado
					contenidas en las etiquetas en idioma español c) reparar las prendas
					en caso de deterioro parcial imputable a el mismo dentro de los
					treinta días siguientes a la presentación de la inconformidad de el
					consumidor, sin costo alguno para éste, aún cuando se haya
					subcontratado el servicio con terceros d) devolver el importe del
					servicio no realizado cuando previamente se haya cobrado e) indemnizar
					en caso de deterioro total o perdida salvo causas de fuerza mayor, a
					su propietario hasta con un máximo de 12 veces el precio del servicio
					pactado por dicha prenda o un máximo del 60% del valor de la prenda
					que las partes de común acuerdo hayan declarado o en su defecto que el
					consumidor pueda demostrar fehacientemente. La garantía de los
					servicios prestados será de 90 días de acuerdo a lo dispuesto por el
					artículo 77 de la Ley Federal de Protección al f) corregir sin cargo
					extra las deficiencias en el servicio que el consumidor encuentre en
					el momento de recoger las prendas. La garantía se limita al
					cumplimiento del servicio solicitado y se hará valida en el mismo
					establecimiento. Salidas las prendas del establecimiento no se admite
					reclamación por éste concepto, por lo que el consumidor deberá revisar
					sus prendas al momento de la entrega de las mismas g) entregar las
					prendas en el día pactado en la orden de servicio, salvo causas de
					fuerza mayor h) identificarse plenamente ante el consumidor en el caso
					de que se preste el servicio a domicilio. De haber un cargo adicional
					por dicho servicio se especificara en el anverso del presente
					contrato.
				</p>
				<p>
					6ª.- Las garantías de los servicios prestados se podrán hacer
					exigibles en el local ubicado en Lago Zúrich # 168, torre B, local C,
					Planta Baja, col. Ampliación Granada, Del. Miguel Hidalgo, D.F. CP.
					11520
				</p>
				<p>
					7ª.-El prestador del servicio no es responsable de a) objetos y/o
					valores olvidados en las prendas b) los daños que pudieran sufrir la
					prenda en caso de que esta no cuente con instrucciones de lavado o
					sean erróneas c) el deterioro de rellenos, entretelas y partes ocultas
					de la prenda d) el estado de limpieza o planchado que guarden las
					prendas después de treinta días a partir del día pactado para su
					entrega.
				</p>
				<p>
					8ª.- El consumidor deberá a) Pagar a través de una tarjeta de crédito
					o debito al momento de ordenar el servicio, en moneda nacional, o
					extranjera que acepte “El prestador de servicio”. Las prendas para
					efecto de control no serán entregadas en forma parcial b) recibir las
					prendas en la fecha convenida c) verificar que son las mismas que
					entregó para su servicio d) pagar por concepto de almacenaje, después
					de 30 días el 1% sobre el valor del servicio por cada día que
					transcurra, en ningún caso el pago por almacenaje excederá el monto
					total. En caso de que transcurran 90 días sin que el consumidor recoja
					su (s) prenda (s) el prestador de servicio se constituirá como
					acreedor prendario respecto de la(s) misma (s) pudiendo ejecutar la
					prenda de forma inmediata en los términos de las disposiciones legales
					aplicables e) En caso de no presentar la orden de servicio, exhibir
					una identificación oficial, comprobante de domicilio, firmar de
					recibido en la copia de la orden de servicio que obre en poder del
					prestador del servicio y en el libro que para tal fin lleve, en el
					horario y días fijados por el establecimiento.
				</p>
				<p>
					9ª.- Son causas de terminación del presente contrato a) que el
					consumidor antes de que se haya iniciado el proceso avise al prestador
					de servicio que no quiere utilizar el servicio b) que el prestador del
					servicio detecte que la prenda o sus accesorios pueda sufrir daño. En
					estos casos no habrá indemnización para ninguna de las partes.
				</p>
				<p>
					10ª.- Las partes serán acreedoras de una pena convencional por el
					monto del servicio solicitado por el incumplimiento de las
					obligaciones contractuales pactadas en el presente contrato de
					adhesión.
				</p>
				<p>
					11ª.- La recepción de éste documento y la entrega de las prendas
					implica la aceptación del presente contrato.
				</p>
				<p>
					12ª.- Para dirimir cualquier controversia las partes se someten a la
					ley federal de protección al consumidor, este contrato fue aprobado y
					registrado por la procuraduría del consumidor bajo el número 9457-2018
					de fecha 19 de septiembre de 2018 Cualquier variación del presente
					contrato en perjuicio del consumidor, frente al contrato de adhesión
					registrado, se tendrá por no puesta.
				</p>
			</article>
		</section>
	);
};

export default Adherence;
