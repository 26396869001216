import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getUserState } from '../localStorage';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = getUserState();

  return (
    <Route
      {...rest}
      render={props => {
        return user && user.token ? (
          <Component {...props} />
        ) : (
          <Redirect
            from={props.location.pathname}
            to={{
              pathname: '/login',
              state: { redirectTo: props.location.pathname }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
