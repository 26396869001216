import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import * as localStorage from '../localStorage';
import * as basketActions from '../actions/basketActions';
import '../assets/styles/components/PromotionDetail.scss';
import { ReactComponent as SVGMoney } from '../assets/static/icons/money.svg';
import { ReactComponent as SVGPercent } from '../assets/static/icons/percent.svg';
import { ReactComponent as SVGSuccess } from '../assets/static/icons/success.svg';
import { ReactComponent as SVGWarning } from '../assets/static/icons/warning.svg';

class PromotionDetail extends Component {
  state = {
    dueDate: null,
    view: null
  };
  myStorage = window.localStorage;
  user = localStorage.getUserState();

  componentDidMount() {
    this.formatDate();
  }

  formatDate = () => {
    const date = new Date(this.props.data.dueDate);
    const formatDate = format(date, 'd MMMM yyyy', { locale: es });
    this.setState({ dueDate: formatDate });
  };

  showWarning = () => {
    this.setState({ view: 'warning' });
  };

  applyDiscount = () => {
    const localBasket = JSON.parse(this.myStorage.getItem('t2gBasket'));

    const {
      data,
      basketReducer,
      setDiscount,
      setPromotion,
      setTotal
    } = this.props;

    const promotion = {
      id: data.idPromotion,
      name: data.name,
      type: data.type,
      discount: data.discount,
      discountType: data.discountType
    };

    setPromotion(promotion);
    localBasket.promotion = promotion;

    if (promotion.type === 'ORDER') {
      switch (promotion.discountType) {
        case 'PERCENT':
          const percent = parseInt(data.discount) / 100;
          const discount = parseFloat(
            (basketReducer.subtotal * percent).toFixed(2)
          );
          setDiscount(discount);
          localBasket.discount = discount;
          setTotal(basketReducer.subtotal - discount);
          localBasket.total = basketReducer.subtotal - discount;
          this.myStorage.setItem('t2gBasket', JSON.stringify(localBasket));
          break;
        default:
          break;
      }
    }

    this.setState({ view: 'success' });
  };

  render() {
    const { data } = this.props;
    const { dueDate, view } = this.state;

    const PromotionDetailIcon = () => {
      switch (data.discountType) {
        case 'MONEY':
          return <SVGMoney className="PromotionDetail__icon" />;
        case 'PERCENT':
          return <SVGPercent className="PromotionDetail__icon" />;
        default:
          return '';
      }
    };

    const PromotionDetailDescription = () => {
      switch (data.discountType) {
        case 'PERCENT':
          return (
            <p className="PromotionDetail__description">
              {`${data.discount}% de descuento sobre el total de tu orden.`}
            </p>
          );
        default:
          return '';
      }
    };

    if (view === 'warning') {
      return (
        <div className="PromotionDetail">
          <SVGWarning className="PromotionDetail__icon" />
          <p className="PromotionDetail__warningText">
            Si tienes alguna promoción aplicada anteriormente será sustituida
            por ésta.
          </p>
          <button
            onClick={this.applyDiscount}
            className="PromotionDetail__button"
          >
            Aceptar
          </button>
        </div>
      );
    }

    if (view === 'success') {
      return (
        <div className="PromotionDetail">
          <SVGSuccess className="PromotionDetail__icon" />
          <p className="PromotionDetail__warningText">
            La promoción seleccionada ha sido aplicada a tu pedido.
          </p>
          <button
            onClick={() => {
              this.props.close();
            }}
            className="PromotionDetail__button"
          >
            Aceptar
          </button>
        </div>
      );
    }

    return (
      <div className="PromotionDetail">
        <PromotionDetailIcon />
        <h2 className="PromotionDetail__name">{data.name}</h2>
        <PromotionDetailDescription />
        <p className="PromotionDetail__detail">
          <strong>Detalles: </strong>
          <br />
          {data.detail}
        </p>
        <p className="PromotionDetail__expiration">
          <strong>Fecha de vencimiento: </strong>
          <br />
          {dueDate}
        </p>
        {this.user && this.user.token ? (
          <button
            onClick={this.showWarning}
            className="PromotionDetail__button"
          >
            Aplicar
          </button>
        ) : (
          <p>Para aplicar una promoción se necesita iniciar sesión.</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = reducers => {
  return {
    basketReducer: reducers.basketReducer
  };
};

export default connect(mapStateToProps, basketActions)(PromotionDetail);
